export const environment = {
    production: false,
    test: true,
    serviceUrl: 'https://test.cognix.ai/llmToolsJavaApi',
    botServiceUrl: 'https://test.cognix.ai/llmChatApi',
    stripe_key: 'pk_test_51OEHSaLUkCUEhkswuFyoaNfZI5Dcm0wwrmIfF9GdNbbMQjHcvoQGKRKfgX3Udpa5tfQ2G7TVQu0JOFsDBlyH7Ecm00PMPbzGIH',
    chat_bot_id:175,
    chat_bot_webkey:'1kOlfKBUFISKd2VIAvIA9m1G4VYtslGF',
    qa_bot_id:175,
    qa_bot_webkey:'1kOlfKBUFISKd2VIAvIA9m1G4VYtslGF'
};
