<div class="container">
  <p class="heading">Bot Details</p>
  <div>
    <div class="details-container">
      <div class="input-container">
        <div>


          <label>Bot Identifier: {{toolModel.id}}</label>
        </div>
        <div>
          <pi
                  [text]="'Key Identifier'"
                  [toolTip]="getBotNameTooltip('key_identifier')"
          />
          <input
                  type="text"
                  disabled
                  value="123456789012"
                  class="text-input disabled-input"
                  [(ngModel)]="toolModel.webInterfaceKey"
          />
        </div>
        <div>
          <pi
            [text]="'Bot Name'"
            [toolTip]="getBotNameTooltip('bot_name')"
          ></pi>
          <input type="text" class="text-input" [(ngModel)]="toolModel.name" />
        </div>
        <div>
          <pi
                  [text]="'Default Answer'"
                  [toolTip]="getBotNameTooltip('default_answer')"
          ></pi>
          <input type="text" class="text-input" [(ngModel)]="toolModel.defaultChatBotAnswer" />
          <p style="font-size: small;font-style: italic">For example : I am unable to answer this question. Feel free to reach us via email ( support@mycompany.com) or phone ( 1-800-xxx-xxxx). </p>
        </div>
        <div>
          <pi
            [text]="'Website URL'"
            [toolTip]="getBotNameTooltip('website_url')"
          />
          <input
            type="text"
            placeholder="Link to the website relevant to the bot"
            class="text-input"
            [(ngModel)]="toolModel.allowedOrigins"
          />
        </div>

        <div>
          <pi
            [text]="'Description'"
            [toolTip]="getBotNameTooltip('bot_description')"
          />
          <textarea
            cols="30"
            rows="4"
            placeholder="What makes your bot special?"
            class="textaBot Namerea-input"
            [(ngModel)]="toolModel.description"
          ></textarea>
        </div>
<!--        <div>-->
<!--          <pi-->
<!--            [text]="'Primary Language'"-->
<!--            [toolTip]="getBotNameTooltip('bot_primary_language')"-->
<!--          />-->
<!--          <select class="select-input" [(ngModel)]="toolModel.primaryLanguage">-->
<!--            <option value="left" selected>English</option>-->
<!--            <option value="right">French</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div>-->
<!--          <pi-->
<!--            [text]="'Primary Timezone'"-->
<!--            [toolTip]="getBotNameTooltip('bot_primary_timezone')"-->
<!--          />-->
<!--          <select class="select-input" [(ngModel)]="toolModel.primaryTimezone">-->
<!--            <option value="left" selected>(GMT +5:30) India</option>-->
<!--            <option value="right">(GMT +1) Africa</option>-->
<!--          </select>-->
<!--        </div>-->
        <!-- <div class="checkbox-container">
        <input type="checkbox" />
        <p>Override Timezone</p>
      </div> -->
      </div>
      <div class="help-container">
        <!-- <p class="help-heading">Help & Support</p>
      <p class="help-text">Setup basic details for the bot</p>
      <a class="help-link">What is a path?</a>
      <a class="help-link">What is a faq?</a>
      <a class="help-link">What is a node?</a>
      <button class="view-all-btn">View All Resources</button> -->
      </div>
    </div>
    <div class="button-container">
      <button (click)="submitForm()" class="save-btn">Save Changes</button>
    </div>
  </div>
</div>
