import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "color-input",
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: "./color-input.component.html",
  styleUrls: ["./color-input.component.css"],
})
export class ColorInputComponent {
  @Input() value: string = "#000000";
  @Output() valueChange = new EventEmitter<string>();
  openColorPicker() {
    const colorInput = document.getElementById("colorInput");
    if (colorInput) {
      colorInput.click();
    }
  }

  onColorSelected(event: any) {
    this.value = event.target.value;
    this.valueChange.emit(this.value);
  }
}
