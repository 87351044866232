<div class="d-flex row col-12">
    <div class="container col-lg-6 col-12 mt-3">
        <div class="first">
            <form name="toolsForm" #myForm="ngForm" (ngSubmit)="updateTool()">

                <h4>Bot:{{toolName}} </h4>
                <ul>
                    <li>
                        <label>Tool ID: {{toolId}}</label>
                    </li>
                </ul>

                <div class="row tm-small">
                    <div class="form-group tm-small">

                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label for="name">Name:</mat-label>
                            <input matInput type="text" id="name" name="name" ngModel required
                                [(ngModel)]="toolModel.name" #name="ngModel" />
                            <mat-error *ngIf="!name.valid && name.touched">Name is required.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group tm-small" *ngIf="toolModel.toolType == '1'">
                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label for="defaultAnswer">Default Answer (When chatbot is not able answer):</mat-label>
                            <input matInput type="text" id="defaultAnswer" name="defaultChatBotAnswer" ngModel
                                [(ngModel)]="toolModel.defaultChatBotAnswer"
                                placeholder="I am unable to answer this question. Please contact admin@yourbusinsee or support phone number" />
                        </mat-form-field>

                        <!-- <label for="defaultAnswer">Default Answer(When chatbot is not able answer):</label>
                        <input type="text" class="form-control" id="defaultAnswer" name="defaultChatBotAnswer"
                            [(ngModel)]="toolModel.defaultChatBotAnswer"
                            placeholder="I am unable to answer this question. Please contact admin@yourbusinsee or support phone number" /> -->
                    </div>

                    <div class="form-group tm-small">
                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label for="allowedOrigins">Allowed Origins:</mat-label>
                            <input matInput type="text" id="allowedOrigins" name="allowedOrigins" ngModel required
                                [(ngModel)]="toolModel.allowedOrigins" #allowedOrigins="ngModel" [minLength]="5"
                                [maxLength]="200" />
                            <mat-error *ngIf="!allowedOrigins.valid && allowedOrigins.touched">Allowed list of origins
                                should be a valid list of comma seperated domains.</mat-error>
                        </mat-form-field>

                        <!-- <label for="allowedOrigins">Allowed Origins:</label>
                        <input type="text" class="form-control" id="allowedOrigins" name="allowedOrigins"
                            [(ngModel)]="toolModel.allowedOrigins" #allowedOrigins="ngModel" [minLength]="5"
                            [maxLength]="200" />
                        <span class="help-block" *ngIf="!allowedOrigins.valid && allowedOrigins.touched">Allowed list of origins
                            should be a valid list of comma seperated domains.</span> -->
                    </div>

                    <div class="form-group tm-small">

                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label for="description">Description:</mat-label>
                            <textarea matInput id="description" name="description" ngModel required
                                [(ngModel)]="toolModel.description" required #descriptipn="ngModel" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
                                cdkAutosizeMaxRows="5"></textarea>
                            <mat-error *ngIf="!descriptipn.valid && descriptipn.touched">Description is
                                required.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group tm-small tex">
                        <label>Web Interface Key: {{toolModel.webInterfaceKey}}</label>
                        <!--                    <input type="text" class="form-control" id="webInterfaceKey" name="webInterfaceKey" value="{{toolModel.webInterfaceKey}}"   readonly/>-->

                    </div>

                    <div class="button-container text-left d-flex flex-row-reverse" style="margin-top: 1em">
                        <button type="submit" class="btn upd" [disabled]="!myForm.valid">Update
                            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn can" style="margin-left: 1em">Cancel</button>
                    </div>
                </div>


            </form>
            <!-- <div class="row " style="margin-top:0.5em">
                <div *ngIf="isError" class="alert alert-danger">{{ updateToolMessage }}</div>
                <div *ngIf="isSuccess" class="alert-success">{{ updateToolMessage }}</div>

            </div> -->

        </div>
    </div>


    <div class="container col-lg-6 col-12 mt-3">
        <div class="second">
            <div id="quickCreate">
                <h4>Source Documents :  {{toolName}}</h4>
                <h6>Add new document to {{toolName}} </h6>

                <form (ngSubmit)="addDocument(f)" #f="ngForm">

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label for="dataSourceDropDown">Document Type</mat-label>
                                <mat-select name="srcType" id="dataSourceDropDown" [(ngModel)]="srcType"
                                    (selectionChange)="onDataSourceOptionChange($event)">
                                    <mat-option value="File">Upload Document (.txt/.pdf/.doc)</mat-option>
                                    <mat-option value="WebPages">Web Page</mat-option>
                                    <mat-option value="GoogleDocs" disabled>Google Docs (Coming Soon)</mat-option>
                                    <mat-option value="S3" disabled>S3 (Coming soon)</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="fileUploadDataSourceSelected">

                        <div class="col-md-12 mt-3 mb-3">

                            <!-- <mat-form-field>
                                <mat-label for="dataSourceDropDown">Upload Docs</mat-label>
                                <ngx-mat-file-input formControlName="localFile" placeholder="Basic Input" #fileInput
                                    (change)="file = fileInput.files[0]"></ngx-mat-file-input>
                            </mat-form-field> -->

                            <label for="localFile">Upload Docs</label>:

                            <input type="file" class="form-control" id="localFile" name="localFile" #fileInput
                                (change)="file = fileInput.files[0]" />
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="googleDocsDataSourceSelected">
                        <div class="col-md-12">

                            <mat-form-field appearance="outline" style="width: 100%">
                                <mat-label for="textfield">List of google docs</mat-label>
                                <input matInput type="text" id="textarea" name="srcDataGoogleDocs" ngModel required
                                    rows="3" [(ngModel)]="srcDataGoogleDocs" #textarea="ngModel" />
                                <mat-error *ngIf="!textarea.valid && textarea.touched">Web pages is
                                    required.</mat-error>
                            </mat-form-field>

                            <!-- <label for="textfield">List of google docs</label>
                            <textarea id="textarea" name="srcDataGoogleDocs" class="form-control" rows="3"
                                [(ngModel)]="srcDataGoogleDocs"></textarea> -->
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="webPageDataSourceSelected">
                        <div class="col-md-12">

                            <mat-form-field appearance="outline" style="width: 100%">
                                <mat-label for="textfield">List of web pages</mat-label>
                                <input matInput type="text" id="textfield" name="srcDataWebPages" ngModel required
                                    rows="3" [(ngModel)]="srcDataWebPages" #textfield="ngModel" />
                                <mat-error *ngIf="!textfield.valid && textfield.touched">Web pages is
                                    required.</mat-error>
                            </mat-form-field>

                            <!-- <label for="textfield">List of web pages</label>
                            <input type="text" class="form-control" id="textfield" name="srcDataWebPages"
                                placeholder="single webpage ex: https://en.wikipedia.org/wiki/Metabolite etc."
                                [(ngModel)]="srcDataWebPages"> -->
                        </div>

                    </div>
                    <div class="row mt-3" *ngIf="webSiteDataSourceSelected">
                        <div class="col-md-12">

                            <mat-form-field appearance="outline" style="width: 100%">
                                <mat-label for="websiteName">Website Name</mat-label>
                                <input matInput type="text" id="websiteName" name="srcDataWebsite" ngModel required
                                    rows="3" #textfield="ngModel" />

                            </mat-form-field>


                            <!-- <label for="websiteName">Website Name</label>
                            <input type="text" class="form-control" id="websiteName"
                                placeholder="example : www.anywebsite.com" formControlName="srcDataWebsite"> -->

                            <div style="font-size: x-small"> We will crawl over the entire website and try to add
                                webpages
                                into
                                KB. Currently only 20 pages are supported</div>
                        </div>

                    </div>

                    <div class=" d-flex flex-row-reverse">
                        <button type="submit" class="btn upd">Add Document
                            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn can" style="margin-left: 1em">Cancel</button>
                    </div>

                    <div style="margin-top: 2em">
                        <div>
                            <h6>List of documents for {{toolName}}</h6>
                            <table width="100%">
                                <thead>
                                <th> File </th>
                                <th> Keywords</th>
                                <th></th>
                                </thead>
                                <tbody>
                                <tr *ngFor="let toolDocModel of toolDocModelList; let i = index" style="margin-bottom: 10px;">
                                    <td>{{toolDocModel.name}}</td>
                                    <td><input type="text" id="keywords" name="keywords_{{i}}" [(ngModel)]="toolDocModel.keywords" size="30"/></td>
                                    <td style="text-align: right ">
                                        <button type="button" (click)="updateKeywords(i)" name="updateKeyWords" id="updateKeywords" class="btn upd-small" >Update
                                            <span *ngIf="isLoadingUpdateKeywords" class="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span>
                                        </button></td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- <div class="row" style="margin-top:0.5em">
                        <div *ngIf="isError" class="alert alert-danger">{{ addDocumentMessage }}</div>
                        <div *ngIf="isSuccess" class="alert-success">{{ addDocumentMessage }}</div>
                    </div> -->

                </form>

            </div>
        </div>
    </div>


    <div class="container col-lg-6 col-12 mt-3">

        <div class="third ">
            <h4> Current Configuration of {{toolName}}: </h4>
            <section>
                <p style="font-style: italic;"> These are advanced settings. Changing these can affect the output of
                    this
                    tool
                    significantly.</p>
            </section>


            <!--    <label for="customRange1" class="form-label">Temperature:{{toolModel.toolConfigDto.temperature}}</label>-->
            <!--    <input type="range" class="form-range" id="customRange1" min="0" max="1" step="0.1" [(ngModel)]="toolModel.toolConfigDto.temperature"/>-->


            <form class="d-flex row col-12" name="toolsForm" #myForm="ngForm" (ngSubmit)="updateTool()">

                <div class="form-group col-12 mt-3">

                        <input type="checkbox" name="applyMetaDataFilter" id="applyMetaDataFilter" class="form-check-inline" [(ngModel)]="toolModel.toolConfigDto.applyMetaDataFilter"/><label for="applyMetaDataFilter">Apply Keywords Filter while querying</label>
                </div>

                <div class="form-group col-12 mt-3">

                    <input type="checkbox" name="applyRecencyFilter" id="applyRecencyFilter" class="form-check-inline" [(ngModel)]="toolModel.toolConfigDto.applyRecencyFilter"/><label for="applyRecencyFilter">Use the latest version of the document.</label>
                </div>



                <div class="form-group col-12 mt-3">


                    <label for="temperature">Temperature(Between 0 and 1):
                        {{toolModel.toolConfigDto.temperature}}</label>
                    <!--                <input type="text" class="form-control" id="temperature" name="temperature"   [(ngModel)]="toolModel.toolConfigDto.temperature" required #temperature="ngModel"/>-->
                    <input type="range" class="form-range" name="temperature" id="temperature" min="0" max="1"
                        step="0.1" [(ngModel)]="toolModel.toolConfigDto.temperature" />

                </div>
                <div class="form-group col-12 mt-3">
                    <label for="maxChunkOverlap">Max Chunk Overlap(Between 0 and 1)
                        :{{toolModel.toolConfigDto.maxChunkOverlap}}</label>
                    <input type="range" class="form-range" name="maxChunkOverlap" id="maxChunkOverlap" min="0" max="1"
                        step="0.1" [(ngModel)]="toolModel.toolConfigDto.maxChunkOverlap" />

                    <!--                <input type="text" class="form-control" id="maxChunkOverlap" name="maxChunkOverlap" [(ngModel)]="toolModel.toolConfigDto.maxChunkOverlap" required #maxChunkOverlap="ngModel"/>-->
                    <!--                <span class="help-block" *ngIf="!maxChunkOverlap.valid && maxChunkOverlap.touched"> Max Chunk Overlap is required.</span>-->
                </div>

                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="maxOutputSize">Max output size:</mat-label>
                        <input matInput type="text" id="maxOutputSize" name="maxOutputSize" ngModel required
                            [(ngModel)]="toolModel.toolConfigDto.maxOutputSize" #maxOutputSize="ngModel" />
                        <mat-error *ngIf="!maxOutputSize.valid && maxOutputSize.touched">Max output size is
                            required.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="maxInputSize">Max Input size:</mat-label>
                        <input matInput type="text" id="maxInputSize" name="maxInputSize" ngModel required
                            [(ngModel)]="toolModel.toolConfigDto.maxInputSize" #maxInputSize="ngModel" />
                        <mat-error *ngIf="!maxInputSize.valid && maxInputSize.touched">Max input size is
                            required.</mat-error>
                    </mat-form-field>

                </div>

                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="maxTokens">Max Tokens:</mat-label>
                        <input matInput type="text" id="maxTokens" name="maxTokens" ngModel required
                            [(ngModel)]="toolModel.toolConfigDto.maxTokens" #maxTokens="ngModel" />
                        <mat-error *ngIf="!maxTokens.valid && maxTokens.touched">Max Tokens is
                            required.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="topKSimilarity">Top K Similarity:</mat-label>
                        <input matInput type="text" id="topKSimilarity" name="topKSimilarity" ngModel required
                            [(ngModel)]="toolModel.toolConfigDto.similarityTopK" #similarityTopK="ngModel" />
                        <mat-error *ngIf="!similarityTopK.valid && similarityTopK.touched">Max Tokens is
                            required.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="llmProvider">LLM Provider:</mat-label>
                        <input matInput type="text" id="llmProvider" ngModel
                            [(ngModel)]="toolModel.toolConfigDto.llmProvider" #llmProvider="ngModel" name="llmProvider"
                            [readonly]="true" />

                    </mat-form-field>
                </div>
                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="llmModel">LLM Model:</mat-label>
                        <input matInput type="text" id="llmModel" ngModel
                            [(ngModel)]="toolModel.toolConfigDto.modelName" #modelName="ngModel" name="llmModel"
                            [readonly]="true" />

                    </mat-form-field>
                </div>
                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="embeddingProvider">Embedding Provider:</mat-label>
                        <input matInput type="text" id="embeddingProvider" ngModel
                            [(ngModel)]="toolModel.toolConfigDto.embeddingProvider" #embeddingProvider="ngModel"
                            name="embeddingProvider" [readonly]="true" />

                    </mat-form-field>
                </div>
                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="embeddingModel">Embedding Model:</mat-label>
                        <input matInput type="text" id="embeddingModel" ngModel
                            [(ngModel)]="toolModel.toolConfigDto.embeddingModel" #embeddingModel="ngModel"
                            name="embeddingModel" [readonly]="true" />

                    </mat-form-field>

                </div>




                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="contextPromptId">Context Prompt</mat-label>
                        <mat-select id="contextPromptId" name="selectedContextPromot"    [(ngModel)]="toolModel.toolConfigDto.contextPromptDto" [compareWith]="comparePrompts">
                            <mat-option *ngFor="let contextPrompt of contextPromptList" [value]="contextPrompt" >{{ contextPrompt.name
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="systemPromptId">System Prompt</mat-label>
                        <mat-select id="systemPromptId" name="selectedSystemPromot" [(ngModel)]="toolModel.toolConfigDto.systemPromptDto" [compareWith]="comparePrompts">
                            <mat-option *ngFor="let prompt of systemPromptList" [value]="prompt" >{{ prompt.name
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="condensePromptId">Condense Prompt</mat-label>
                        <mat-select id="condensePromptId" name="selectedCondensePrompt" [(ngModel)]="toolModel.toolConfigDto.condensePromptDto" [compareWith]="comparePrompts">
                            <mat-option *ngFor="let prompt of condensePromptList" [value]="prompt" >{{ prompt.name
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-6 mt-3">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="condenseQuestionPromptId">Condense Question Prompt</mat-label>
                        <mat-select id="condenseQuestionPromptId" name="selectedCondenseQuestionPrompt" [(ngModel)]="toolModel.toolConfigDto.condenseQuestionPromptDto" [compareWith]="comparePrompts">
                            <mat-option *ngFor="let prompt of condenseQuestionPromptList" [value]="prompt" >{{ prompt.name
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>




                <div class="button-container text-left d-flex flex-row-reverse" style="margin-top: 1em">
                    <button type="submit" class="btn upd" [disabled]="!myForm.valid">Update
                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn can" style="margin-left: 1em">Cancel</button>
                </div>
            </form>
            <!-- <div class="row" style="margin-top:0.5em">
                <div *ngIf="isError" class="alert alert-danger">{{ updateToolMessage }}</div>
                <div *ngIf="isSuccess" class="alert-success">{{ updateToolMessage }}</div>
            </div> -->



            <!-- <div class="row" style="margin-top:0.5em">
                <div *ngIf="isError" class="alert alert-danger">{{ updateToolMessage }}</div>
                <div *ngIf="isSuccess" class="alert-success">{{ updateToolMessage }}</div>
            </div> -->


        </div>




    </div>




    <div class="col-lg-6 col-12">
    </div>

</div>


<!-- <div class="container" style="margin-bottom: 2rem;" xmlns="http://www.w3.org/1999/html">


<!-- <div class="container" style="margin-bottom: 2rem;" xmlns="http://www.w3.org/1999/html">

    <form name="toolsForm" #myForm="ngForm" (ngSubmit)="updateTool()">

        <h3>Bot:{{toolName}} </h3>

        <div class="row tm-small">
            <div class="form-group tm-small">


                <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label for="name">Name:</mat-label>
                    <input matInput type="text" id="name" name="name" ngModel required [(ngModel)]="toolModel.name"
                        required #name="ngModel" />
                    <mat-error *ngIf="!name.valid && name.touched">Name is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="form-group tm-small" *ngIf="toolModel.toolType == '1'">
                <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label for="defaultAnswer">Default Answer (When chatbot is not able answer):</mat-label>
                    <input matInput type="text" id="defaultAnswer" name="defaultChatBotAnswer" ngModel
                        [(ngModel)]="toolModel.defaultChatBotAnswer"
                        placeholder="I am unable to answer this question. Please contact admin@yourbusinsee or support phone number" />
                </mat-form-field>

                <label for="defaultAnswer">Default Answer(When chatbot is not able answer):</label>
                <input type="text" class="form-control" id="defaultAnswer" name="defaultChatBotAnswer"
                    [(ngModel)]="toolModel.defaultChatBotAnswer"
                    placeholder="I am unable to answer this question. Please contact admin@yourbusinsee or support phone number" />
            </div>

            <div class="form-group tm-small">
                <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label for="allowedOrigins">Allowed Origins:</mat-label>
                    <input matInput type="text" id="allowedOrigins" name="allowedOrigins" ngModel required
                        [(ngModel)]="toolModel.allowedOrigins" #allowedOrigins="ngModel" [minLength]="5"
                        [maxLength]="200" />
                    <mat-error *ngIf="!allowedOrigins.valid && allowedOrigins.touched">Allowed list of origins
                        should be a valid list of comma seperated domains.</mat-error>
                </mat-form-field>

                <label for="allowedOrigins">Allowed Origins:</label>
                <input type="text" class="form-control" id="allowedOrigins" name="allowedOrigins"
                    [(ngModel)]="toolModel.allowedOrigins" #allowedOrigins="ngModel" [minLength]="5"
                    [maxLength]="200" />
                <span class="help-block" *ngIf="!allowedOrigins.valid && allowedOrigins.touched">Allowed list of origins
                    should be a valid list of comma seperated domains.</span>
            </div>

            <div class="form-group tm-small">

                <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label for="description">Description:</mat-label>
                    <input matInput type="text" id="description" name="description" ngModel required
                        [(ngModel)]="toolModel.description" required #descriptipn="ngModel" />
                    <mat-error *ngIf="!descriptipn.valid && descriptipn.touched">Description is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="form-group tm-small">
                <label>Web Interface Key: {{toolModel.webInterfaceKey}}</label>
                                   <input type="text" class="form-control" id="webInterfaceKey" name="webInterfaceKey" value="{{toolModel.webInterfaceKey}}"   readonly/>

            </div>

            <div class="button-container text-left" style="margin-top: 1em">
                <button type="button" class="btn btn-secondary" style="margin-left: 1em">Cancel</button>
                <button type="submit" class="btn btn-primary" [disabled]="!myForm.valid">Update</button>
            </div>
        </div>


    </form>
    <div class="row" style="margin-top:0.5em">
        <div *ngIf="isError" class="alert alert-danger">{{ updateToolMessage }}</div>
        <div *ngIf="isSuccess" class="alert-success">{{ updateToolMessage }}</div>
    </div>
    <hr>
</div> -->

<!-- <div class="container">
    <div id="quickCreate">
        <h3>Add additional document to {{toolName}}</h3>

        <form (ngSubmit)="addDocument(f)" #f="ngForm">

            <div class="row">
                <div class="col-md-12">
                    <label for="dataSourceDropDown">Document Type</label>
                    <select class="form-select" name="srcType" id="dataSourceDropDown"
                        (change)="onDataSourceOptionChange($event)" style="width: 40%;" [(ngModel)]="srcType">
                        <option selected>Select an option...</option>
                        <option value="File">Upload Document(.txt/.pdf/.doc)</option>
                        <option value="WebPages">Web Page</option>
                        <option value="GoogleDocs" disabled>Google Docs(Coming Soon)</option>
                        <option value="S3" disabled>S3 (Coming soon)</option>
                        <option value="GoogleDrive">GoogleDrive</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3" *ngIf="fileUploadDataSourceSelected">

                <div class="col-md-12 mt-3">
                    <label for="localFile">Upload Docs</label>:

                    <input type="file" id="localFile" name="localFile" #fileInput (change)="file = fileInput.files[0]">
                </div>
            </div>
            <div class="row mt-3" *ngIf="googleDocsDataSourceSelected">
                <div class="col-md-12">
                    <label for="textfield">List of google docs</label>
                    <textarea id="textarea" name="srcDataGoogleDocs" class="form-control" rows="3"
                        [(ngModel)]="srcDataGoogleDocs"></textarea>
                </div>
            </div>
            <div class="row mt-3" *ngIf="webPageDataSourceSelected">
                <div class="col-md-12">
                    <label for="textfield">List of web pages</label>
                    <input type="text" class="form-control" id="textfield" name="srcDataWebPages"
                        placeholder="single webpage ex: https://en.wikipedia.org/wiki/Metabolite etc."
                        [(ngModel)]="srcDataWebPages">
                </div>

            </div>
            <div class="row mt-3" *ngIf="webSiteDataSourceSelected">
                <div class="col-md-12">
                    <label for="websiteName">Website Name</label>
                    <input type="text" class="form-control" id="websiteName" placeholder="example : www.anywebsite.com"
                        formControlName="srcDataWebsite">
                    <div style="font-size: x-small"> We will crawl over the entire website and try to add webpages into
                        KB. Currently only 20 pages are supported</div>
                </div>

            </div>

            <div class="row mt-3">
                <div class="col-md-6" style="margin-top: 1em;">


                    <button type="submit" class="btn btn-primary btn-sm">Add Document</button>
                    <button type="button" class="btn btn-secondary btn-sm" style="margin-left: 1em">Cancel</button>

                </div>



            </div>

            <div class="row" style="margin-top:0.5em">
                <div *ngIf="isError" class="alert alert-danger">{{ addDocumentMessage }}</div>
                <div *ngIf="isSuccess" class="alert-success">{{ addDocumentMessage }}</div>
            </div>

            <hr>

        </form>

    </div>

</div> -->



<!-- <div class="container" style="margin-top: 2em">
    <h3>List of files in {{toolName}}:</h3>
    <div *ngFor="let value of toolModel?.sourceFileNames">
        {{ value }}
    </div>
</div> -->



<!-- <div class="container" style="margin-top: 2em">
    <h3> Current Configuration of{{toolName}}: </h3>
    <section>
        <p style="font-style: italic;"> These are advanced settings. Changing these can affect the output of this tool
            significantly.</p>
    </section>


       <label for="customRange1" class="form-label">Temperature:{{toolModel.toolConfigDto.temperature}}</label>
       <input type="range" class="form-range" id="customRange1" min="0" max="1" step="0.1" [(ngModel)]="toolModel.toolConfigDto.temperature"/>


    <form name="toolsForm" #myForm="ngForm" (ngSubmit)="updateTool()">


        <div class="form-group tm-small">
            <label for="temperature">Temperature(Between 0 and 1): {{toolModel.toolConfigDto.temperature}}</label>
                           <input type="text" class="form-control" id="temperature" name="temperature"   [(ngModel)]="toolModel.toolConfigDto.temperature" required #temperature="ngModel"/>
            <input type="range" class="form-range" name="temperature" id="temperature" min="0" max="1" step="0.1"
                [(ngModel)]="toolModel.toolConfigDto.temperature" />

        </div>
        <div class="form-group tm-small">
            <label for="maxChunkOverlap">Max Chunk Overlap(Between 0 and 1)
                :{{toolModel.toolConfigDto.maxChunkOverlap}}</label>
            <input type="range" class="form-range" name="maxChunkOverlap" id="maxChunkOverlap" min="0" max="1"
                step="0.1" [(ngModel)]="toolModel.toolConfigDto.maxChunkOverlap" />

                           <input type="text" class="form-control" id="maxChunkOverlap" name="maxChunkOverlap" [(ngModel)]="toolModel.toolConfigDto.maxChunkOverlap" required #maxChunkOverlap="ngModel"/>
                           <span class="help-block" *ngIf="!maxChunkOverlap.valid && maxChunkOverlap.touched"> Max Chunk Overlap is required.</span>
        </div>




        <div class="form-group col-md-2">
            <label for="maxOutputSize">Max output size:</label>
            <input type="text" class="form-control w-50" id="maxOutputSize" name="maxOutputSize"
                [(ngModel)]="toolModel.toolConfigDto.maxOutputSize" #maxOutputSize="ngModel" />
            <span class="help-block" *ngIf="!maxOutputSize.valid && maxOutputSize.touched"> Max output size is
                required.</span>

        </div>

        <div class="form-group col-md-2">
            <label for="maxInputSize">Max Input size:</label>
            <input type="text" class="form-control w-50" id="maxInputSize" name="maxInputSize"
                [(ngModel)]="toolModel.toolConfigDto.maxInputSize" #maxInputSize="ngModel" />
            <span class="help-block" *ngIf="!maxInputSize.valid && maxInputSize.touched"> Max output size is
                required.</span>

        </div>

        <div class="form-group col-md-2">
            <label for="maxTokens">Max Tokens:</label>
            <input type="text" class="form-control w-50" id="maxTokens" name="maxTokens"
                [(ngModel)]="toolModel.toolConfigDto.maxTokens" #maxTokens="ngModel" />
            <span class="help-block" *ngIf="!maxTokens.valid && maxTokens.touched"> Max Tokens is required.</span>

        </div>

        <div class="form-group col-md-2">
            <label for="topKSimilarity">Top K Similarity:</label>
            <input type="text" class="form-control w-50" id="topKSimilarity" name="topKSimilarity"
                [(ngModel)]="toolModel.toolConfigDto.similarityTopK" #similarityTopK="ngModel" />
            <span class="help-block" *ngIf="!similarityTopK.valid && similarityTopK.touched"> Max Tokens is
                required.</span>

        </div>


        <div class="form-group col-md-4">
            <label for="llmProvider">LLM Provider:</label>
            <input type="text" class="form-control" id="llmProvider" name="llmProvider"
                placeholder={{toolModel.toolConfigDto.llmProvider}} readonly />

        </div>

        <div class="form-group col-md-4">
            <label for="llmModel">LLM Model:</label>
            <input type="text" class="form-control" id="llmModel" name="llmModel"
                placeholder={{toolModel.toolConfigDto.modelName}} readonly />

        </div>

        <div class="form-group col-md-4">
            <label for="embeddingProvider">Embedding Provider:</label>
            <input type="text" class="form-control" id="embeddingProvider" name="embeddingProvider"
                placeholder={{toolModel.toolConfigDto.embeddingProvider}} readonly />

        </div>

        <div class="form-group col-md-4">
            <label for="embeddingModel">Embedding Model:</label>
            <input type="text" class="form-control" id="embeddingModel" name="embeddingModel"
                placeholder={{toolModel.toolConfigDto.embeddingModel}} readonly />

        </div>

        <div class="button-container" style="margin-top: 1em">
            <button type="submit" class="btn btn-primary" [disabled]="!myForm.valid">Update</button>
            <button type="button" class="btn btn-secondary" style="margin-left: 1em">Cancel</button>
        </div>
    </form>
    <div class="row" style="margin-top:0.5em">
        <div *ngIf="isError" class="alert alert-danger">{{ updateToolMessage }}</div>
        <div *ngIf="isSuccess" class="alert-success">{{ updateToolMessage }}</div>
    </div>

</div> -->
