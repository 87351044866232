import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { SubscriptionService } from "../subscription/subscription.service";

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.css"],
})
export class PricingComponent {
  isAuthenticated: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private location: Location
  ) {}

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  back() {
    this.location.back();
  }

  isMyRoute(): boolean {
    const currentRoute = this.router.url;
    console.log(currentRoute);
    console.log(currentRoute.includes("dashboard"));
    return currentRoute.includes("dashboard");
  }

  createSubscription() {
    this.subscriptionService.createSubscription(4);
  }
}
