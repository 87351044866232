import { Component, OnInit } from "@angular/core";
import { PromptModel } from "../prompt.model";
import { ActivatedRoute } from "@angular/router";
import { PromptService } from "../promptService";
import { MessageService } from "src/app/shared/services/message.service";

@Component({
  selector: "app-prompt-create",
  templateUrl: "./prompt-create.component.html",
  styleUrls: ["./prompt-create.component.css"],
})
export class PromptCreateComponent implements OnInit {
  promptDto = new PromptModel();

  isError: boolean = false;
  isSuccess: boolean = false;
  serverMessage: string = "";
  promptTypeList: any = null;
  selectedPromptType: any | undefined;

  constructor(
    private promptService: PromptService,
    public messageService: MessageService
  ) {}

  ngOnInit() {
    this.promptService.getPromptTypeList().subscribe(
      (response) => {
        this.promptTypeList = response;
        console.log(this.promptTypeList);
        this.selectedPromptType = this.promptTypeList[0];
        this.promptDto.text = this.selectedPromptType.defaultText;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  submitForm() {
    console.log("inside Submit form");
    this.isError = false;
    this.isSuccess = false;

    this.promptDto.promptType = this.selectedPromptType.id;

    this.promptService.addPrompt(this.promptDto).subscribe(
      (data) => {
        this.isSuccess = true;
        // this.serverMessage = "Success! Now you can use this prompt in your queries.Go to the query section to query using this prompt"
        this.messageService.showSuccess(
          "Success! Now you can use this prompt in your queries.Go to the query section to query using this prompt"
        );
        this.promptDto = new PromptModel();
      },
      (error) => {
        this.isError = true;
        // this.serverMessage= "oops!! We hit the snag. Please try after sometime."
        this.messageService.showError(
          "oops!! We hit the snag. Please try after sometime."
        );
      }
    );
  }

  onPromptTypeChange(newPromptType: any) {
    console.log(this.selectedPromptType);
    this.promptDto.text = this.selectedPromptType.defaultText;
  }
}
