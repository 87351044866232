<div class="container">
  <div class="item" [ngClass]="{ active: activeItem === 0 }">
<!--    <div class="item-header" (click)="setActiveItem(0)">-->
<!--      <div class="item-name">-->
<!--        &lt;!&ndash; <pi [text]="'Chat Theme'" [toolTip]="getTooltip('chat_theme')"></pi> &ndash;&gt;-->
<!--        <pi [text]="'Global Style'" [toolTip]="'Global Style'"></pi>-->
<!--      </div>-->
<!--      <img-->
<!--        [src]="-->
<!--          activeItem === 0-->
<!--            ? '../../../assets/icons/upArrow.svg'-->
<!--            : '../../../assets/icons/downArrow.svg'-->
<!--        "-->
<!--        alt="Arrow Icon"-->
<!--        class="arrow-icon"-->
<!--      />-->
<!--    </div>-->
<!--    <div [style.display]="activeItem === 0 ? 'block' : 'none'">-->
<!--      &lt;!&ndash; <app-choose-theme-->
<!--        [toolSettingsModel]="toolSettingsModel"-->
<!--        [toolTips]="toolTips"-->
<!--        [toolId]="toolId"-->
<!--      /> &ndash;&gt;-->
<!--      <app-global-style  -->
<!--      [toolSettingsModel]="toolSettingsModel"-->
<!--      [toolTips]="toolTips"-->
<!--      [toolId]="toolId"></app-global-style>-->
<!--    </div>-->
<!--  </div>-->
  <div class="item" [ngClass]="{ active: activeItem === 1 }">
    <div class="item-header" (click)="setActiveItem(1)">
      <div class="item-name">
        <pi [text]="'Header'" [toolTip]="getTooltip('chat_header')"></pi>
      </div>
      <img
        [src]="
          activeItem === 1
            ? '../../../assets/icons/upArrow.svg'
            : '../../../assets/icons/downArrow.svg'
        "
        alt="Arrow Icon"
        class="arrow-icon"
      />
    </div>
    <div [style.display]="activeItem === 1 ? 'block' : 'none'">
      <app-chat-header
        *ngIf="activeItem === 1"
        [toolSettingsModel]="toolSettingsModel"
        [toolTips]="toolTips"
        [toolId]="toolId"
      />
    </div>
  </div>
  <div class="item" [ngClass]="{ active: activeItem === 2 }">
    <div class="item-header" (click)="setActiveItem(2)">
      <div class="item-name">
        <pi
          [text]="'Background'"
          [toolTip]="getTooltip('chat_background')"
        ></pi>
      </div>
      <img
        [src]="
          activeItem === 2
            ? '../../../assets/icons/upArrow.svg'
            : '../../../assets/icons/downArrow.svg'
        "
        alt="Arrow Icon"
        class="arrow-icon"
      />
    </div>
    <div [style.display]="activeItem === 2 ? 'block' : 'none'">
      <app-chat-background
        *ngIf="activeItem === 2"
        [toolSettingsModel]="toolSettingsModel"
        [toolTips]="toolTips"
        [toolId]="toolId"
      />
    </div>
  </div>
  <div class="item" [ngClass]="{ active: activeItem === 3 }">
    <div class="item-header" (click)="setActiveItem(3)">
      <div class="item-name">
        <pi
          [text]="'Messages'"
          [toolTip]="getTooltip('response_messages')"
        ></pi>
      </div>
      <img
        [src]="
          activeItem === 3
            ? '../../../assets/icons/upArrow.svg'
            : '../../../assets/icons/downArrow.svg'
        "
        alt="Arrow Icon"
        class="arrow-icon"
      />
    </div>
    <div [style.display]="activeItem === 3 ? 'block' : 'none'">
      <app-response-messages
        *ngIf="activeItem === 3"
        [toolSettingsModel]="toolSettingsModel"
        [toolTips]="toolTips"
        [toolId]="toolId"
      />
    </div>
  </div>
  <div class="item" [ngClass]="{ active: activeItem === 4 }">
    <div class="item-header" (click)="setActiveItem(4)">
      <div class="item-name">
        <p class="mb-0 mt-2">Button </p>
      </div>
      <img
        [src]="
          activeItem === 4
            ? '../../../assets/icons/upArrow.svg'
            : '../../../assets/icons/downArrow.svg'
        "
        alt="Arrow Icon"
        class="arrow-icon"
      />
    </div>
    <div [style.display]="activeItem === 4 ? 'block' : 'none'">
      <app-button-settings
        *ngIf="activeItem === 4"
        [toolSettingsModel]="toolSettingsModel"
        [toolTips]="toolTips"
        [toolId]="toolId"
      />
    </div>
  </div>
  <div class="item" [ngClass]="{ active: activeItem === 5 }">
    <div class="item-header" (click)="setActiveItem(5)">
      <div class="item-name">
        <pi
          [text]="'User Messages'"
          [toolTip]="getTooltip('user_message')"
        ></pi>
      </div>
      <img
        [src]="
          activeItem === 5
            ? '../../../assets/icons/upArrow.svg'
            : '../../../assets/icons/downArrow.svg'
        "
        alt="Arrow Icon"
        class="arrow-icon"
      />
    </div>
    <div [style.display]="activeItem === 5 ? 'block' : 'none'">
      <app-user-messages
        *ngIf="activeItem === 5"
        [toolSettingsModel]="toolSettingsModel"
        [toolTips]="toolTips"
        [toolId]="toolId"
      />
    </div>
  </div>
</div>
