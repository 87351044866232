import { Injectable } from "@angular/core";
import { AppConstants } from "./app-constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Subject, map } from "rxjs";
import { AuthService } from "./auth/auth.service";

@Injectable()
export class AppService {
  globalMessageSubject = new Subject<string[]>();
  userMessagesSubject = new Subject<string[]>();
  tokenUsageAllowedStatusSubject = new Subject<boolean>();
  feature_enabled_subject = new Subject<boolean>();

  constructor(
    private appConstants: AppConstants,
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getGlobalMessages() {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getGlobalMessageListUrl();

    console.log("getGlobalMessage url --->" + url);

    this.http
      .get<any[]>(url, { headers: headers })
      .pipe(
        map((response) => {
          var messageList: string[] = [];

          for (const item of response) {
            messageList.push(item.text);
          }
          return messageList;
        })
      )
      .subscribe(
        (response) => {
          this.globalMessageSubject.next(response);
        },
        (error) => {
          this.globalMessageSubject.error(error);
        }
      );
  }

  getUserMessage() {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getUserMessageListUrl();

    console.log("getUserMessage url --->" + url);

    this.http
      .get<any[]>(url, { headers: headers })
      .pipe(
        map((response) => {
          var messageList: string[] = [];

          for (const item of response) {
            messageList.push(item.text);
          }
          return messageList;
        })
      )
      .subscribe(
        (response) => {
          this.userMessagesSubject.next(response);
        },
        (error) => {
          this.userMessagesSubject.error(error);
        }
      );
  }

  getTokenUsageAllowedStatus() {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getTokenUsageAllowedUrl();

    console.log("getUserMessage url --->" + url);

    this.http.get<boolean>(url, { headers: headers }).subscribe(
      (response) => {
        this.tokenUsageAllowedStatusSubject.next(response);
      },
      (error) => {
        this.tokenUsageAllowedStatusSubject.error(error);
      }
    );
  }

  isFeatureEnabled(name: string) {
    const url = this.appConstants.getDisbledFeatureListUrl();
    var isEnabled = true;

    this.http.get<string[]>(url).subscribe(
      (response) => {
        console.log("response  --> " + response);
        console.log("response.length  --> " + response.length);
        console.log("response.includes(name)  --> " + response.includes(name));
        if (response.length != 0 && response.includes(name)) {
          isEnabled = false;
        }
        this.feature_enabled_subject.next(isEnabled);
      },
      (error) => {
        console.error(
          "Error occured while getting disabled feature list" + error
        );
        this.feature_enabled_subject.error(error);
      }
    );
  }
}
