import { Component, Input } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ColorInputComponent } from '../../../core/color-input/color-input.component'
import { FileInputComponent } from '../../../core/file-input/file-input.component'
import { CommonModule } from '@angular/common'
import { DisplaySettingsModel } from '../../../display-tab/display-settings.model'

@Component({
  selector: 'app-chat-background',
  standalone: true,
  imports: [FormsModule, CommonModule, ColorInputComponent, FileInputComponent],
  templateUrl: './chat-background.component.html',
  styleUrls: ['./chat-background.component.css'],
})
export class ChatBackgroundComponent {
  @Input()
  toolSettingsModel!: DisplaySettingsModel
  @Input()
  toolTips: { fieldHelpKey: string; fieldHelpValue: string }[] = []
  @Input() toolId: string = "";
  selectedBackground: string = 'solid'
}
