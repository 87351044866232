import { Component, Input } from '@angular/core'
import { ColorInputComponent } from '../../../core/color-input/color-input.component'
import { CommonModule } from '@angular/common'
import { DisplaySettingsModel } from '../../../display-tab/display-settings.model'
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'app-user-messages',
  standalone: true,
  imports: [ColorInputComponent, CommonModule, FormsModule],
  templateUrl: './user-messages.component.html',
  styleUrls: ['./user-messages.component.css'],
})
export class UserMessagesComponent {
  @Input()
  toolSettingsModel!: DisplaySettingsModel
  @Input()
  toolTips: { fieldHelpKey: string; fieldHelpValue: string }[] = []
  @Input() toolId: string = "";
  showReplyClicked: boolean = false

  toggleShowReply() {
    this.showReplyClicked = !this.showReplyClicked
  }
}
