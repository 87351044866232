import { Injectable } from "@angular/core";
import { AppConstants } from "../app-constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { SupportMessageModel } from "./support.model";

@Injectable()
export class SupportService {
  addIssueSubject = new Subject<any>();

  constructor(
    private appConstants: AppConstants,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  addIssue(model: SupportMessageModel) {
    console.log("inside add issue");
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getAddSupportMessageUrl();

    console.log("url -->" + url);
    this.http.post(url, model, { headers: headers }).subscribe(
      (response) => {
        this.addIssueSubject.next(response);
      },
      (error) => {
        this.addIssueSubject.next(error);
      }
    );
  }
}
