<div class="d-flex container row col-12 mt-3">
  <div class="first">
    <div class="container chat-head">
      <div class="row">
        <div class="col-6 d-flex justify-content-start align-items-center">
          <h1>List of Knowledgebases</h1>
        </div>
        <div class="col-6 d-flex justify-content-end align-items-center">
          <button type="submit" class="btn knownew" (click)="addKnowledgeBase()">Create New</button>
        </div>
      </div>
    </div>


    <div *ngIf="kbList == null ||  kbList.length === 0">
      You have not created any knowledgebase and indexes yet.
    </div>
    <div class="table-responsive-xxl" *ngIf="kbList != null && kbList.length > 0">
      <table class="table table-borderless" *ngIf="kbList.length !== 0">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created By</th>
            <th>Created On</th>
            <th>Additional Info</th>
            <th></th>
            <th></th>

          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let kb of kbList.slice(0, itemsToDisplay);; let i = index">
            <tr class="main-row">
              <td>{{ kb.customName }}</td>
              <td>{{ kb.createdBy }}</td>
              <td>{{ kb.createdOn | date:'M/d/yyyy h:mm a' }}</td>
              <td>{{ kb.additionalInfo }}</td>
              <td>
  
                <a href="javascript:void(0);" (click)="viewKnowledge(i)">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="24" height="24"
                    viewBox="0 0 24 24" stroke-width="2" stroke="#000" fill="none" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                    <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                  </svg>
                </a>
              </td>
              <td>
                <!-- <button type="button" class="btn btn-secondary btn-sm"
                    (click)="showIndexTypeDropDown = false;selectedKbIndex = i">View Knowledgebase</button> -->
  
                <button mat-flat-button class="adddoc" style="padding: 0 15px; white-space: nowrap;" (click)="addIndex(i)">Add
                  Document</button>
  
                <!-- <button type="button" class="btn btn-secondary btn-sm" (click)="addIndex(i)">Add Document</button> -->
  
              </td>
  
            </tr>
        
            <tr *ngIf="viewKnowledgeIsVisible(i)" >
              <td colspan="6">
                <table class="table table-borderless" *ngIf="kbList.length !== 0">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Source Files</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
          
                    <tr *ngFor="let index of kbList[selectedKbIndex].indexDtoLiteList">
          
                      <td>{{index.indexType}}</td>
                      <td>{{index.srcList}}</td>
                      <td>
          
          
                        <a href="javascript:void(0);" (click)="updateIndex(selectedKbIndex,index.id)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="20" height="20"
                            viewBox="0 0 24 24" stroke-width="2" stroke="#000" fill="none" stroke-linecap="round"
                            stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                            <path d="M16 5l3 3"></path>
                          </svg>
                        </a>
          
                        <!-- <div class="btn-group btn-group-sm" role="group" aria-label="Button group">
                          <button type="button" class="btn btn-secondary btn-sm"
                            (click)="updateIndex(selectedKbIndex,index.id)">update Document</button>
                        </div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

          </ng-container>
        </tbody>
        





        <tbody>

          
        </tbody>
      </table>

      <button mat-flat-button class="knownew" style="padding: 0 15px; white-space: nowrap;" (click)="loadMoreItems()">Load More</button>

    </div>
  </div>
  <!-- <div class="second mt-5">
    <h1>List of Indexes for: {{kbList[selectedKbIndex].customName}}</h1>

    <div class="table-responsive" *ngIf="kbList != null && kbList.length > 0">
      <table class="table table-borderless" *ngIf="kbList.length !== 0">
        <thead>
          <tr>
            <th>Type</th>
            <th>Source Files</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let index of kbList[selectedKbIndex].indexDtoLiteList">

            <td>{{index.indexType}}</td>
            <td>{{index.srcList}}</td>
            <td>


              <a href="javascript:void(0);" (click)="updateIndex(selectedKbIndex,index.id)">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="20" height="20"
                  viewBox="0 0 24 24" stroke-width="2" stroke="#000" fill="none" stroke-linecap="round"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                  <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                  <path d="M16 5l3 3"></path>
                </svg>
              </a>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="container" *ngIf="kbList[selectedKbIndex].indexDtoLiteList.length > 1 ">

      <div class="row mt-3" *ngIf="showIndexTypeDropDown">
        <div class="col-md-12">

          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label for="indexTypeDropDown">Select Composite Index Type</mat-label>
            <mat-select name="compIndexType" id="indexTypeDropDown" [(ngModel)]="compIndexType">
              <mat-option selected>Select an option...</mat-option>
              <mat-option value="List">List Index</mat-option>
              <mat-option value="Tree">Tree Index</mat-option>

            </mat-select>
          </mat-form-field>

        </div>
      </div>
    </div>

  </div> -->
</div>