import { Component, OnInit, OnDestroy } from "@angular/core";
import { SupportMessageModel } from "./support.model";
import { SupportService } from "./support.service";
import { Subscription } from "rxjs";
import { NgForm } from "@angular/forms";
import { MessageService } from "../shared/services/message.service";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.css"],
})
export class SupportComponent implements OnInit, OnDestroy {
  supportMessageModel: SupportMessageModel = new SupportMessageModel();

  add_issue_subscription: Subscription | undefined;

  constructor(
    private supportService: SupportService,
    public messageService: MessageService
  ) {}

  ngOnInit() {
    this.add_issue_subscription = this.supportService.addIssueSubject.subscribe(
      (data) => {
        console.log("support issue added successfully");
        this.messageService.showSuccess(
          "Support issue added successfully"
        );
      },
      (error) => {
        console.log("Error : while  adding support issue");
        this.messageService.showError(
          "Error : while  adding support issue"
        );
      }
    );
  }

  addIssue(form: NgForm) {
    this.supportService.addIssue(this.supportMessageModel);
    form.resetForm();
  }

  ngOnDestroy() {
    this.add_issue_subscription?.unsubscribe();
  }
}
