<div class="row">
  <section class="section pricing" id="pricing">
    <div class="container">
      <div class=" price-head" *ngIf="!isMyRoute()">
        <div>
          <h1 class="price">
            <a
              class="sidebar-link"
              href="javascript:void(0);"
              (click)="back()"
              aria-expanded="false"
              style="color: #000"
              >Back</a
            >
          </h1>
        </div>

      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="title-box text-center">

            <h2 class="text-heading f-17 mt-1">

            </h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 d-flex">
          <div class="pricing-box mt-4">
            <div>
              <h4 class="f-20 text-center">Free Plan<sup><small>**</small></sup></h4>
              <div class="mt-4 pt-2">
                <!-- <b class="mb-2 f-18">Features</b> -->

                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >Upto 5 bots (chat + question)
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >Access to admin interface
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="red"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M10 10l4 4m0 -4l-4 4" /></svg
                  >API integration
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="red"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M10 10l4 4m0 -4l-4 4" /></svg
                  >Customer service support<sup><small>**</small></sup>
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="red"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M10 10l4 4m0 -4l-4 4" /></svg
                  >Customization support<sup><small>***</small></sup>
                </p>
              </div>
            </div>

            <div>
              <!-- <div class="pricing-plan mt-4 pt-2">
                <h4 class="text-muted">
                  <span class="plan pl-3 text-dark">$8.99 </span>
                </h4>
                <p class="text-muted mb-0">Per Month</p>
              </div> -->

              <div class="pt-3 text-left">
                <p style="margin-bottom: 0px">
                  ** Free Tier - The service will be free for one week or until the word count for chat exceed 20000 words per month. (which ever is reached first)
                </p>
                <p style="margin-bottom: 0px">
                  *** 24 hr turnaround time.
                </p>
                <p>
                  **** Customization support is our service where we will build custom features in the bot as per your requirements. (Only available in enterprise tiear)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 d-flex">
          <div class="pricing-box mt-4">
            <!-- <div class="pricing-badge">
              <span class="badge">Featured</span>
            </div> -->

            <div>
              <h4 class="f-20 text-center">Basic Plan<sup><small>**</small></sup></h4>
              <div class="ribbon">Best Value</div>
              <div class="mt-4 pt-2">
                <!-- <b class="mb-2 f-18">Features</b> -->


                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >Unlimited number of bots
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >Access to admin interface
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >API integration
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >Premier Customer service support<sup><small>***</small></sup>
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="red"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M10 10l4 4m0 -4l-4 4" /></svg
                  >Customization support<sup><small>****</small></sup>
                </p>
              </div>
            </div>

            <div>
              <div class="pricing-plan mt-4 pt-2">
                <h4 class="text-muted">
                  <span class="plans pl-3 text-dark">
                    <font style="text-decoration: line-through;">$19.99/month</font>
                    <p>
                      <font style="color: red;">$9.99/month<font style="font-size:0.7em">(Beta Price)</font></font>
                    </p>
                    </span>

                </h4>
                <!-- <p class="text-muted mb-0">Per Month</p> -->
              </div>


                <div class="pt-3 text-left">
                  <p style="margin-bottom: 0px">
                    **Text limit : 750,000  word count per month for all the chat sessions.
                  </p>
                  <p style="margin-bottom: 0px">
                    *** 4 hrs turnaround for critical issues.
                  </p>
                  <p>
                    **** Customization support is our service where we will build custom features in the bot as per your requirements. (Only available in enterprise tiear)
                  </p>

                </div>


              <div class="pt-3 text-center">
                <a *ngIf="isAuthenticated"  class="btn btn-primary btn-rounded" (click)="createSubscription()">Subscribe</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 d-flex">
          <div class="pricing-box mt-4">
            <div>
              <h4 class="f-20 text-center">Premium Plan</h4>
              <div class="mt-4 pt-2">
                <!-- <b class="mb-2 f-18">Features</b> -->

                <div class="mb-4">
                  <b> 750K+ words per month </b>
                </div>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >Unlimited number of bots
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >Access to admin interface
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >API integration
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >Premier Customer service support<sup><small>*</small></sup>
                </p>
                <p class="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="green"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 12l2 2l4 -4" /></svg
                  >Customization support<sup><small>**</small></sup>
                </p>
              </div>
            </div>

            <div>
              <div class="pricing-plan mt-4 pt-2">
                <h4 class="text-muted">
                  <span class="plans pl-3 text-dark">Contact for pricing</span>
                </h4>
              </div>



                <div class="pt-3 text-left">

                  <p style="margin-bottom: 0px">
                    * 2 hrs turnaround for non critical issues.
                  </p>
                  <p>
                    ** Customization support is our service where we will build custom features in the bot as per your requirements. (Only available in enterprise tiear)
                  </p>

                </div>


            </div>
          </div>
        </div>
      </div>

      <div class="foot" *ngIf="!isMyRoute()">
        <app-footer />
      </div>
    </div>
  </section>
</div>

