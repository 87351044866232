import { Component, OnInit } from "@angular/core";
import { PromptService } from "../promptService";
import { Router } from "@angular/router";

@Component({
  selector: "app-prompt-list",
  templateUrl: "./prompt-list.component.html",
  styleUrls: ["./prompt-list.component.css"],
})
export class PromptListComponent implements OnInit {
  promptList: any = null;

  itemsToDisplay: number = 5;

  loadMoreItems() {
    this.itemsToDisplay += 5;
  }

  ngOnInit() {
    this.promptservice.getMyPromptlist().subscribe(
      (response) => {
        this.promptList = response;
        console.log(this.promptList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  constructor(private promptservice: PromptService, private router: Router) {}
}
