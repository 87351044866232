import { AuthService } from "../auth/auth.service";
import { AppConstants } from "../app-constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class QueryService {
  constructor(
    private appConstants: AppConstants,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  query(queryRequest: any): Observable<any> {
    // Set the HTTP headers

    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());
    const url = this.appConstants.queryUrl();
    console.log(" query url --->" + url);

    // Make the API request
    return this.http.post(url, queryRequest, { headers: headers });
  }
}
