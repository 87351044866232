<div class="container">
  <p class="title">Display</p>
  <div class="sub-container">
    <form class="info-container" (ngSubmit)="submitForm()">
      <p class="description">
        Change bot style according to your brand.
      </p>
      <div class="tab-buttons-container">
        <button
          type="button"
          type="button"
          class="tab-button"
          [ngClass]="{ active: activeTab === 0 }"
          [ngStyle]="{
            'border-bottom': activeTab === 0 ? 'blue 2px solid' : ''
          }"
          [ngStyle]="{
            'border-bottom': activeTab === 0 ? 'blue 2px solid' : ''
          }"
          (click)="changeTab(0)"
        >
          Launch Icon
        </button>

        <button
          type="button"
          type="button"
          class="tab-button"
          [ngClass]="{ active: activeTab === 2 }"
          [ngStyle]="{
            'border-bottom': activeTab === 2 ? 'blue 2px solid' : ''
          }"
          [ngStyle]="{
            'border-bottom': activeTab === 2 ? 'blue 2px solid' : ''
          }"
          (click)="changeTab(2)"
        >
          Style
        </button>
        <button
          type="button"
          type="button"
          class="tab-button"
          [ngClass]="{ active: activeTab === 3 }"
          [ngStyle]="{
            'border-bottom': activeTab === 3 ? 'blue 2px solid' : ''
          }"
          [ngStyle]="{
            'border-bottom': activeTab === 3 ? 'blue 2px solid' : ''
          }"
          (click)="changeTab(3)"
        >
          Settings
        </button>
      </div>
      <div *ngIf="toolModel">
        <bot-icon-tab
          *ngIf="activeTab === 0"
          [toolSettingsModel]="toolModel"
          [toolTips]="tooltips"
        ></bot-icon-tab>
        <launch-screen-tab
          *ngIf="activeTab === 1"
          [toolSettingsModel]="toolModel"
          [toolTips]="tooltips"
        ></launch-screen-tab>
        <chat-style-tab
          *ngIf="activeTab === 2"
          [toolSettingsModel]="toolModel"
          [toolTips]="tooltips"
        ></chat-style-tab>
        <settings-tab
          *ngIf="activeTab === 3"
          [toolSettingsModel]="toolModel"
          [toolTips]="tooltips"
        ></settings-tab>
      </div>
      <button type="submit" class="btn upd save-changes-button mx-4 mt-5" *ngIf="toolModel">
        Save Changes
        <span
          *ngIf="isLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </form>
  </div>
</div>
