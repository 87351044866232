import {
  Component,
  Input,
  Injector,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router"; // Import ActivatedRoute
import { CommonModule } from "@angular/common";
import { DisplaySettingsModel } from "../../display-tab/display-settings.model";
import { ChooseThemeComponent } from "../chatStyleComponents/choose-theme/choose-theme.component";
import { ChatHeaderComponent } from "../chatStyleComponents/chat-header/chat-header.component";
import { ChatBackgroundComponent } from "../chatStyleComponents/chat-background/chat-background.component";
import { ResponseMessagesComponent } from "../chatStyleComponents/response-messages/response-messages.component";
import { ButtonSettingsComponent } from "../chatStyleComponents/button-settings/button-settings.component";
import { UserMessagesComponent } from "../chatStyleComponents/user-messages/user-messages.component";
import { TextWithTipComponent } from "../../core/text-with-tip/text-with-tip.component";
import { GlobalStyleComponent } from "../chatStyleComponents/global-style/global-style.component";

@Component({
  selector: "chat-style-tab",
  standalone: true,
  imports: [
    CommonModule,
    TextWithTipComponent,
    ChooseThemeComponent,
    ChatHeaderComponent,
    ChatBackgroundComponent,
    ResponseMessagesComponent,
    ButtonSettingsComponent,
    UserMessagesComponent,
    GlobalStyleComponent
  ],
  templateUrl: "./chat-style.component.html",
  styleUrls: ["./chat-style.component.css"],
})
export class ChatStyleComponent {
  @Input() toolSettingsModel!: DisplaySettingsModel;
  @Input()
  toolTips: { fieldHelpKey: string; fieldHelpValue: string }[] = [];
  toolId:string=""

  constructor(
    private route: ActivatedRoute // Inject ActivatedRoute
  ) {}

  activeItem: number | null = null;

  setActiveItem(index: number) {
    if (this.activeItem === index) {
      this.activeItem = null;
    } else {
      this.activeItem = index;
    }
    console.log(this.toolSettingsModel);
  }

  getTooltip(str: string): string | undefined {
    return this.toolTips.find((t) => t.fieldHelpKey === str)?.fieldHelpValue;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.toolId = params["toolId"];
    });
  }
}
