import {Component, OnDestroy, OnInit} from '@angular/core';
import {TokenUsageService} from './token-usage.service'
import {TokenUsage} from './tokenusage.model'

import {Observable,Subject,Subscription} from "rxjs";

import { User } from "../auth/user.model"
import { AuthService } from "../auth/auth.service";



@Component({
  selector: 'app-token-usage',
  templateUrl: './token-usage.component.html',
  styleUrls: ['./token-usage.component.css']
})
export class TokenUsageComponent implements OnInit,OnDestroy {

  get_token_usage_subscription : Subscription | undefined;

  get_token_usg_row_cnt_subscription : Subscription | undefined

    get_my_info_subscription :Subscription | undefined


  tokenUsageList : TokenUsage[] = [];

  tokenUsageRownCount : number =0;

  pageCount : number = 0;

  pageSize = 10;

  currentPage = 0;
  user : User | null = null

  constructor(private tokebUsageService: TokenUsageService,private authService : AuthService) { }

  ngOnInit(){

      this.authService.getmyinfo_subject.subscribe(
          (data)=>{

              this.user = data;
              this.tokebUsageService.getMyUsageRowCount(this.user?.userId);

              this.tokebUsageService.getMyUsage(this.user?.userId,this.currentPage,this.pageSize);

          },
          (error) =>{
              console.log('error occured while getting my user data')
          }
      )

    this.get_token_usage_subscription = this.tokebUsageService.getTokenUsageSubject.subscribe(
        (data) =>{

          this.tokenUsageList = data;
        },
        (error) => {
          console.log('error '+error);
        }
    )

    this.get_token_usage_subscription = this.tokebUsageService.getTokenUsageRowCountSubject.subscribe(
        (data) =>{

          this.tokenUsageRownCount = data;

          const pageCount = Math.floor(this.tokenUsageRownCount / this.pageSize);
          const remainder = this.tokenUsageRownCount % this.pageSize;

         if (remainder > 0) {
           this.pageCount = pageCount +1;
         }

          console.log("page count "+ this.tokenUsageRownCount );
          console.log("page count "+ this.pageSize );
         console.log("page count "+ this.pageCount );
          console.log("remainder "+ this.pageCount );

        },
        (error) => {
          console.log('error '+error);

        }
    )
      this.authService.getMyInfo();



  }


  getCount(count: number): number[] {
    return new Array(count).fill(0).map((_, index) => index);
  }

  setCurrentPage(i:number) {
      this.currentPage = i;
      this.tokebUsageService.getMyUsage(this.user?.userId,this.currentPage,this.pageSize);

  }

  previous() {
    if(this.currentPage >0) {
      this.currentPage = this.currentPage -1;
      this.tokebUsageService.getMyUsage(this.user?.userId,this.currentPage,this.pageSize);
    }

  }

  next() {
    if(this.currentPage < this.pageCount-1) {
      this.currentPage = this.currentPage + 1 ;
      this.tokebUsageService.getMyUsage(this.user?.userId,this.currentPage,this.pageSize);
    }
  }



  ngOnDestroy(){
    this.get_token_usage_subscription?.unsubscribe();
    this.get_token_usg_row_cnt_subscription?.unsubscribe();
    this.get_my_info_subscription?.unsubscribe();

  }

}
