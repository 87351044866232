import { Component, OnInit,OnDestroy } from '@angular/core';
import { ToolService } from '../tool.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-bots',
  templateUrl: './create-bots.component.html',
  styleUrls: ['./create-bots.component.css']
})
export class CreateBotsComponent implements OnInit ,OnDestroy{
  public data: any;

  constructor(private toolsService: ToolService, public messageService: MessageService,  public dialog: MatDialogRef<'CreateBotDlg'> ) {

      this.dialog.afterClosed().subscribe(result => {
          //console.log(`Dialog result: ${result}`);
          console.log('---inside afterclosed handler ---')
          this.toolAddedSubscription?.unsubscribe();
      });

  }


 isError: boolean = false;
    isSuccess: boolean = false;
    serverMessage: string = ''

    kbList : any = null;
    selectedKbOption: any | undefined;

    //toolModel = new ToolModel();
    indexList: any = null;
    //selectedIndexId : any ;
    selectedIndex : any;
    
    split : number = 0

    toolAddedSubscription: Subscription | undefined;

    selectedToolType:string = '';

    showCreationDiv = false;
    showQuickCreate = false;

    botNameText: string = '';


    srcAddServerMessage: string = '';
    srcAddSuccess: boolean = false;

    file: File | undefined;

    srcType :string =''


    isLoading = false;

    allowedOriginsText:string = ''

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

      console.log("!!! create bot component nginit !!!!");

      this.toolsService.initializeSubjects();

      console.log('this.toolsService.toolCreatedSubject hasError:'+this.toolsService.toolCreatedSubject.hasError);



        this.toolAddedSubscription = this.toolsService.toolCreatedSubject.subscribe(
            (next) => {
                console.log('createbots component--- success---');
                this.isLoading = false;
                this.dialog.close();
                this.messageService.showSuccess(
                    "Success! Now you can start using this bot."
                );
                this.toolsService.getMyToolslist();

            },
            (error) => {
                console.log('createbots  --- error--- '+ JSON.stringify(error));

                this.isLoading = false;
                //this.dialog.close()
                this.messageService.showError(
                    "Oops !! we hit the snag while creating this bot.");



            },
            () => {
                console.log("------ complete is called ----")
            }

        )

  }


  createBot(form:NgForm) {

    this.isLoading = true;
    
    this.toolsService.createBotAndKb(this.botNameText,this.selectedToolType);

}

    ngOnDestroy(){
        console.log("--- create bot unsubscribe called ---");
        this.toolAddedSubscription?.unsubscribe();
    }

}
