<div class="container">
<!--  <div class="item" [ngClass]="{ active: activeItem === 0 }">-->
<!--    <div class="item-header" (click)="setActiveItem(0)">-->
<!--      <div class="item-name">-->
<!--        <p>Message Settings</p>-->
<!--      </div>-->
<!--      <img-->
<!--        [src]="-->
<!--          activeItem === 0-->
<!--            ? '../../../assets/icons/upArrow.svg'-->
<!--            : '../../../assets/icons/downArrow.svg'-->
<!--        "-->
<!--        alt="Arrow Icon"-->
<!--        class="arrow-icon"-->
<!--      />-->
<!--    </div>-->
<!--    <div [style.display]="activeItem === 0 ? 'block' : 'none'">-->
<!--      <app-message-settings [toolSettingsModel]="toolSettingsModel" />-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="item" [ngClass]="{ active: activeItem === 1 }">-->
<!--    <div class="item-header" (click)="setActiveItem(1)">-->
<!--      <div class="item-name">-->
<!--        <p>Voice Settings</p>-->
<!--      </div>-->
<!--      <img-->
<!--        [src]="-->
<!--          activeItem === 1-->
<!--            ? '../../../assets/icons/upArrow.svg'-->
<!--            : '../../../assets/icons/downArrow.svg'-->
<!--        "-->
<!--        alt="Arrow Icon"-->
<!--        class="arrow-icon"-->
<!--      />-->
<!--    </div>-->
<!--    <div [style.display]="activeItem === 1 ? 'block' : 'none'">-->
<!--      <app-voice-settings [toolSettingsModel]="toolSettingsModel" />-->
<!--    </div>-->
<!--  </div>-->
  <div class="item" [ngClass]="{ active: activeItem === 2 }">
    <div class="item-header" (click)="setActiveItem(2)">
      <div class="item-name">
        <p>Chat menu Settings</p>
      </div>
      <img
        [src]="
          activeItem === 2
            ? '../../../assets/icons/upArrow.svg'
            : '../../../assets/icons/downArrow.svg'
        "
        alt="Arrow Icon"
        class="arrow-icon"
      />
    </div>
    <div [style.display]="activeItem === 2 ? 'block' : 'none'">
      <app-chat-menu-settings [toolSettingsModel]="toolSettingsModel" />
    </div>
  </div>
</div>
