import { Component,OnInit, OnDestroy } from "@angular/core";
import { TextWithTipComponent } from "../core/text-with-tip/text-with-tip.component";
import { ToolModel } from "src/app/tools/tool.model";
import { MessageService } from "src/app/shared/services/message.service";
import { ActivatedRoute } from "@angular/router";
import { ToolService } from "src/app/tools/tool.service";
import { Subscription } from "rxjs";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "bot-details-tab",
  standalone: true,
  imports: [TextWithTipComponent, FormsModule],
  templateUrl: "./bot-details-tab.component.html",
  styleUrls: ["./bot-details-tab.component.css"],
})
export class BotDetailsTabComponent implements OnInit, OnDestroy{
  isLoading: boolean = false;
  isError: boolean = false;
  isSuccess: boolean = false;
  toolId: any;
  toolName: any;
  toolModel = new ToolModel();
  contextPromptList: any = [];
  condenseQuestionPromptList: any = [];
  condensePromptList: any = [];
  systemPromptList: any = [];
  tooltips: { fieldHelpKey: string; fieldHelpValue: string }[] = [];
  promptMapSubscription: Subscription | undefined;
  toolGetSubscription: Subscription | undefined;
  toolUpdateSubscription: Subscription | undefined;
  constructor(
    private route: ActivatedRoute,
    private toolsService: ToolService,
    public messageService: MessageService
  ) {}



  updateTool() {
    console.log(this.toolModel);
    this.isLoading = true;
    this.toolsService.updateTool(this.toolModel);
  }

  ngOnInit() {
    this.fetchTooltip();
    this.toolId = this.route.snapshot.queryParamMap.get("toolId");
    this.toolName = this.route.snapshot.queryParamMap.get("toolName");
    console.log("this.toolId " + this.toolId);
    this.toolsService.getTool(this.toolId);
    this.toolsService.getTooltip();

    this.toolGetSubscription = this.toolsService.getToolSubject.subscribe(
      (response: ToolModel) => {
        this.toolModel = response;
        this.isLoading = false;
        console.log('get tool call'+JSON.stringify(this.toolModel))
        //this.updateSelectedPrompts();
      },
      (error: any) => {
        console.log(error);
        this.isLoading = false;
      }
    );
    this.toolUpdateSubscription = this.toolsService.updateToolSubject.subscribe(
      (response: any) => {
        this.isSuccess = true;
        //this.toolsService.getTool(this.toolId);
        this.messageService.showSuccess(response.message);
        this.isLoading = false;
        // this.showServerMessage('updateToolMessage',response.message)
      },
      (error: any) => {
        this.isError = true;
        this.messageService.showError(error);
        this.isLoading = false;
        // this.showServerMessage('updateToolMessage',error)
      }
    );
    this.toolsService.getToolDisplaySettings(this.toolId);
  }

  submitForm() {
    console.log('Submit call : '+this.toolModel);
    this.isLoading = true;
    this.toolsService.updateTool(this.toolModel);
  }
  fetchTooltip() {
    this.toolsService.getTooltip().subscribe((data) => {
      this.tooltips = data;
    });
  }
  getBotNameTooltip(str: string): string | undefined {
    return this.tooltips.find((t) => t.fieldHelpKey === str)?.fieldHelpValue;
  }

  ngOnDestroy(): void {
      this.toolGetSubscription?.unsubscribe();
      this.toolUpdateSubscription?.unsubscribe();
      this.promptMapSubscription?.unsubscribe();
  }
}
