import { Injectable } from "@angular/core";
import { AppConstants } from "../app-constants";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { User } from "../auth/user.model"

@Injectable()
export class TokenUsageService {
  getTokenUsageSubject = new Subject<any>();

  getTokenUsageRowCountSubject = new Subject<any>();


  constructor(
    private appConstants: AppConstants,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  getMyUsage(userId:number = -1, offset: number, pageSize: number) {
    console.log("inside get MyUsage");
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getMyTokenUsageUrl(userId,offset, pageSize);

    console.log("url -->" + url);
    this.http.get(url, { headers: headers }).subscribe(
      (response) => {
        this.getTokenUsageSubject.next(response);
      },
      (error) => {
        this.getTokenUsageSubject.next(error);
      }
    );
  }

  getMyUsageRowCount(userId: number = -1) {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());



    const url = this.appConstants.getTokenUsageRowCountUrl(userId);

    console.log("url -->" + url);
    this.http.get(url, { headers: headers }).subscribe(
      (response) => {
        this.getTokenUsageRowCountSubject.next(response);
      },
      (error) => {
        this.getTokenUsageRowCountSubject.next(error);
      }
    );
  }
}


