<div class="container">
  <select [(ngModel)]="toolSettingsModel.backgroundOption" class="select-box">
    <option class="option" value="solid">Solid</option>
    <option class="option" value="video">Video</option>
    <option class="option" value="gradient">Gradient</option>
    <option class="option" value="image">Image</option>
  </select>
  <div
    *ngIf="toolSettingsModel.backgroundOption === 'solid'"
    class="option-content"
  >
    <div class="color-input-container">
      <p class="bold-text">Background color</p>
      <color-input [(value)]="toolSettingsModel.backgroundColor"></color-input>
    </div>
    <div class="color-input-container">
      <p class="bold-text">Chat transparency</p>
      <input
        type="number"
        class="number-input"
        [(ngModel)]="toolSettingsModel.backgroundTransparency"
      />
    </div>
  </div>
  <div
    *ngIf="toolSettingsModel.backgroundOption === 'video'"
    class="option-content"
  >
    <div class="input-box">
      <label class="bold-text">Video</label>
      <customFileInput
        [fieldName]="'wallpaperVideoUrl'"
        [(value)]="toolSettingsModel.wallpaperVideoUrl"
        [tool]="toolId"
        id="test"
      ></customFileInput>
    </div>
  </div>
  <div
    *ngIf="toolSettingsModel.backgroundOption === 'gradient'"
    class="option-content"
  >
    <div class="grid-container">
      <div class="color-input-container">
        <p class="bold-text">Top stop color</p>
        <color-input [(value)]="toolSettingsModel.topStopColor"></color-input>
      </div>
      <div class="color-input-container">
        <p class="bold-text">Mid stop color</p>
        <color-input [(value)]="toolSettingsModel.midStopColor"></color-input>
      </div>
      <div class="color-input-container">
        <p class="bold-text">Bottom stop color</p>
        <color-input [(value)]="toolSettingsModel.bottomStopColor"></color-input>
      </div>
      <div class="input-box">
        <p class="bold-text">Chat transparency</p>
        <input
          type="number"
          class="number-input"
          [(ngModel)]="toolSettingsModel.backgroundTransparency"
        />
      </div>
    </div>
  </div>
  <div
    *ngIf="toolSettingsModel.backgroundOption === 'image'"
    class="option-content"
  >
    <div class="input-box">
      <label class="bold-text">Wallpaper</label>
      <customFileInput
        [fieldName]="'wallpaperImageUrl'"
        [(value)]="toolSettingsModel.wallpaperImageUrl"
        [tool]="toolId"
        id="new"
      ></customFileInput>
    </div>
  </div>
</div>
