import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ToolService } from "src/app/tools/tool.service";

@Component({
  selector: "customFileInput",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./file-input.component.html",
  styleUrls: ["./file-input.component.css"],
})
export class FileInputComponent {
  @Input() value!: string;
  @Input() tool!: string;
  @Input() fieldName!: string;
  @Output() valueChange = new EventEmitter<string>();
  isLoading: boolean = false;

  constructor(private toolService: ToolService) {}

  onFileSelected(event: any) {
    console.log("file selcted" + this.tool + this.value);
    const file = event.target.files[0];
    if (file) {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("brandFile", file);

      this.toolService.uploadFile(formData, this.tool, this.fieldName).subscribe(
        (data) => {
          if (data && data.data) {
            // this.value =
            //   "https://test.nlp-tools.app/llmToolsJavaApi/tools/" + data.data;
            this.value = data.data;
          }
          this.isLoading = false;
          this.valueChange.emit(this.value);
        },
        (error) => {
          console.error("Error uploading file: ", error);
          this.isLoading = false;
        }
      );
    } else {
      this.value = "";
      this.valueChange.emit(this.value);
    }
  }
}
