import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  toggleMenu = new BehaviorSubject(true);

  constructor(private toastr: ToastrService) {}

  showSuccess(message: string, type = 'success'): void {
    this.toastr.success(message);

  }

  showError(message: string, type: string = 'error'): void {
    this.toastr.error(message);

  }
}
