import { Component, OnDestroy, OnInit } from "@angular/core";
import { SettingsService } from "../settings.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-api-key-list",
  templateUrl: "./api-key-list.component.html",
  styleUrls: ["./api-key-list.component.css"],
})
export class ApiKeyListComponent implements OnInit, OnDestroy {
  apiKeyListSubscription: Subscription | undefined;
  add_api_key_subscription: Subscription | undefined;
  delete_api_key_subscription: Subscription | undefined;
  apiKeyList: any = null;

  constructor(
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.apiKeyListSubscription =
      this.settingsService.listKeysSubject.subscribe(
        (data) => {
          this.apiKeyList = data;
          console.log(
            "ApiKeyListComponent : apiKeyList " +
              JSON.stringify(this.apiKeyList)
          );
        },
        (error) => {
          console.log("error " + error);
        }
      );

    this.add_api_key_subscription =
      this.settingsService.addKeySubject.subscribe(
        (data) => {
          this.settingsService.listKeys();
        },
        (error) => {
          console.log("error " + error);
        }
      );

    this.delete_api_key_subscription =
      this.settingsService.deleteKeySubject.subscribe(
        (data) => {
          this.settingsService.listKeys();
        },
        (error) => {
          console.log("error " + error);
        }
      );

    this.settingsService.listKeys();
  }

  ngOnDestroy() {
    this.apiKeyListSubscription?.unsubscribe();
  }

  deleteKey(idx: number) {
    this.settingsService.deleteKey(this.apiKeyList[idx].id);
  }
}
