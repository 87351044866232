<!--<button type="button" class="btn btn-primary" (click)="getKey()">Generate API Key</button>-->
<!--<div *ngIf="status">-->
<!--<div style="font-weight: bold">{{apiKey}}</div>-->
<!--<div style="font-size: small">Please copy this key. We do not store this key and cannot be retrieved later on .However you can generate a new one.The key is valid for 10 days.</div>-->
<!--</div>-->


<div class="d-flex row col-12">
    <div class="container col-lg-8 col-12 mt-3">
        <div class="first">
            <app-api-key-list></app-api-key-list>
        </div>
    </div>
    <div class="container col-lg-4 col-12 mt-3">
        <div class="second">
            <div style="margin-bottom: 10px">
                <h1>New Key Details</h1>
            </div>
            <form #myForm="ngForm" (ngSubmit)="addKey(myForm)" class="form-horizontal">
                <div class="row mt-5">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label for="key">Key Name</mat-label>
                        <input matInput type="text" id="key" name="key" ngModel required minlength="12"
                            placeholder="Enter Key here" [(ngModel)]="apiKeyModel.key" #keyField="ngModel" />
                        <mat-error *ngIf="keyField.invalid && (keyField.dirty || keyField.touched)">
                            Key is required and should be
                            atleast 12 characters in length.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row mt-4">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label for="provider">Provider:</mat-label>
                        <mat-select name="provider" id="provider" [(ngModel)]="apiKeyModel.provider" required
                            #providerField="ngModel">
                            <mat-option value="OpenAI">OpenAI</mat-option>
                        </mat-select>
                        <mat-error *ngIf="providerField.invalid && (providerField.dirty || providerField.touched)">
                            Provider is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row mt-4">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label for="keyType">KeyType:</mat-label>
                        <mat-select name="keyType" id="keyType" [(ngModel)]="apiKeyModel.keyType" required
                            #keyTypeField="ngModel">
                            <mat-option value="REGULAR">Regular</mat-option>
                            <mat-option value="EMBEDDING">Embedding</mat-option>
                        </mat-select>
                        <mat-error *ngIf="keyTypeField.invalid && (keyTypeField.dirty || keyTypeField.touched)">
                            KeyType is required.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="button-container text-left d-flex flex-row-reverse" style="margin-top: 1em">
                    <button type="submit" class="btn add" [disabled]="!myForm.valid">Add Key</button>
                </div>



                <!-- <div class="form-group row">
                    <div class="col-sm-4 offset-sm-1 mt-3">
                        <button type="submit" class="btn btn-primary" [disabled]="!myForm.valid">Add Key</button>
                    </div>
                </div> -->
            </form>

            <!-- <div class="container mt-5">
                {{message}}
            </div> -->
        </div>
    </div>
</div>




<!-- <div class="container mt-5">

    <div style="margin-bottom: 10px">
        Add Api keys for LLM Provider:
    </div>


    <form #myForm="ngForm" (ngSubmit)="addKey(myForm)" class="form-horizontal">
        <div class="row">
            <label for="key" class="col-sm-1 col-form-label">Key:</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="key" placeholder="Enter Key here" name="key"
                    [(ngModel)]="apiKeyModel.key" required minlength="12" #keyField="ngModel">
                <span class="help-block" *ngIf="!keyField.valid && keyField.touched"> Key is required and should be
                    atleast 12 characters in length.</span>
            </div>
        </div>
        <div class="row mt-3">
            <label for="provider" class="col-sm-1 col-form-label">Provider:</label>
            <div class="col-sm-4">
                <select class="form-control" id="provider" name="provider" [(ngModel)]="apiKeyModel.provider" required
                    #providerField="ngModel">
                    <option value="OpenAI">OpenAI</option>
                </select>
            </div>

            <span class="help-block" *ngIf="!providerField.valid && providerField.touched"> Key Provider is
                required.</span>

        </div>
        <div class="row mt-3">
            <label for="keyType" class="col-sm-1 col-form-label">KeyType:</label>
            <div class="col-sm-4">
                <select class="form-control" id="keyType" name="keyType" [(ngModel)]="apiKeyModel.keyType" required
                    #keyTypeField="ngModel">
                    <option value="REGULAR">Regular</option>
                    <option value="EMBEDDING">Embedding</option>
                </select>
            </div>
            <span class="help-block" *ngIf="!keyTypeField.valid && keyTypeField.touched"> Key Type is required.</span>
        </div>
        <div class="form-group row">
            <div class="col-sm-4 offset-sm-1 mt-3">
                <button type="submit" class="btn btn-primary" [disabled]="!myForm.valid">Add Key</button>
            </div>
        </div>
    </form>

    <div class="container mt-5">
        {{message}}
    </div>

</div>


<div class="container mt-5">
    <app-api-key-list></app-api-key-list>
</div> -->