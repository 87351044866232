import { Component } from "@angular/core";

@Component({
  selector: "overview-tab",
  standalone: true,
  imports: [],
  templateUrl: "./overview-tab.component.html",
  styleUrls: ["./overview-tab.component.css"],
})
export class OverviewTabComponent {}
