import { Component, Input } from '@angular/core'
import { DisplaySettingsModel } from '../../../display-tab/display-settings.model'
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'app-chat-menu-settings',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './chat-menu-settings.component.html',
  styleUrls: ['./chat-menu-settings.component.css'],
})
export class ChatMenuSettingsComponent {
  @Input()
  toolSettingsModel!: DisplaySettingsModel
}
