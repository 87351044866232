<div class="container d-flex row col-12">
    <div class="container col-lg-8 col-12 mt-3">
        <div class="first">
            <app-prompt-list></app-prompt-list>
        </div>
    </div>
    <div class="container col-lg-4 col-12 mt-3">
        <div class="second">
            <!-- <button class="btn btn-primary mb-3" (click)="addNewPrompt()">Create New Prompt</button> -->
            <app-prompt-create></app-prompt-create>
        </div>
    </div>
</div>

<div class="container" style="margin-top: 10px">
</div>