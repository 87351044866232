<div class="container">
  <p class="config-text">Configure</p>
  <div class="config-container">
    <div class="config-section">
      <p>Get Started</p>
      <p>
        Configure your bot and upload your data. Navigate to the relevant
        section to view and update bot settings. Get code snippet to deploy your
        bot on your website.
      </p>
      <button>Deploy Bot</button>

    </div>
    <div>
      <div>
        <p>Train Your Bot</p>
        <p>
          Train your bot by uploading your own data. This will be critical to make sure bot responds to the query according to your business documents.
        </p>
        <button>Upload your document</button>
      </div>
      <div>
        <p>Configure Branding and Styles</p>
        <p>
          Make your bot your own by adding your brand details – icons , colors and lot of other features
        </p>
        <button>Configure Display</button>
      </div>
    </div>
  </div>
</div>
