import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ToolModel } from "../tool.model";
import { ToolService } from "../tool.service";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-tool-try",
  templateUrl: "./tool-try.component.html",
  styleUrls: ["./tool-try.component.css"],
})
export class ToolTryComponent implements OnInit, OnDestroy {
  @ViewChild("chatContainer") chatContainer: ElementRef | undefined;

  toolGetSubscription: Subscription | undefined;
  initChatAgentSubscription: Subscription | undefined;
  chatSubscription: Subscription | undefined;
  querySubscription: Subscription | undefined;

  toolId: any;
  toolModel = new ToolModel();
  apiStr: string = "";
  messages: any[] = [];
  newMessage: string = "";
  chatstatus: string = "";
  queryStr: string = "";
  queryResponse: string = "";

  showchat = false;
  showApi = true;
  showQueryDiv: boolean = false;
  toolType: any;
  isLoading = false;

  conversationSessionId: string = "";

  constructor(
    private route: ActivatedRoute,
    private toolsService: ToolService
  ) {}

  ngOnInit() {
    console.log("ToolListComponent created ");
    this.toolId = this.route.snapshot.queryParamMap.get("toolId");
    this.toolType = this.route.snapshot.queryParamMap.get("toolType");

    console.log("this.toolId " + this.toolId);

    //if(this.toolType == '1') {
    this.initChatAgent();
    //}

    this.toolGetSubscription = this.toolsService.getToolSubject.subscribe(
      (response: ToolModel) => {
        this.toolModel = response;
        console.log(" returned toolModel = " + JSON.stringify(this.toolModel));
      },
      (error: any) => {
        console.log(error);
      }
    );

    this.initChatAgentSubscription =
      this.toolsService.initChatAgentSubject.subscribe(
        (data) => {
          console.log(" -- init chatagent --- success ");
          this.chatstatus = "Agent Initialized!!";
          this.conversationSessionId = data.conversationSessionId;
          console.log(
            " this.conversationSessionId " + this.conversationSessionId
          );
          this.addMessageToList(data.chatResponse, "AI");
          console.log(" length of messages array " + this.messages.length);
        },
        () => {
          this.addMessageToList(
            "oops!!! server error , agent could not be initialized!",
            "AI",
            true
          );
          console.log("InitChatAgent : error occured in chat agent creation.");
        }
      );

    this.chatSubscription = this.toolsService.chatSubject.subscribe(
      (data) => {
        this.isLoading = false;
        console.log(" --  chat --- success ");
        console.log(this.messages);
        this.chatstatus =
          "Message Received!! (scroll the chat panel if the response is not visible)";
        this.addMessageToList(data.chatResponse, "AI");
        console.log(" length of messages array " + this.messages.length);
      },
      () => {
        this.isLoading = false;
        this.addMessageToList("oops!!! server error !", "AI", true);
        console.log("chat : error occured while getting reply.");
      }
    );

    this.querySubscription = this.toolsService.querySubject.subscribe(
      (data) => {
        this.isLoading = false;
        console.log(" --  query --- success ");

        this.queryResponse = data.response;

        console.log(" query response " + this.queryResponse);
      },
      () => {
        this.isLoading = false;

        console.log("query : error occured while querying .");
      }
    );

    this.showAPISignature();
    this.showApi = true;
  }

  initChatAgent() {
    this.messages = [];

    var formData: any = new FormData();
    formData.append("agentId", this.toolId);
    formData.append("toolType", this.toolType);
    formData.append("conversationSessionId", this.conversationSessionId);
    formData.append("toolWebKey", "");
    this.toolsService.initChatAgent(formData);
  }

  addMessageToList(message: string, from: string, error: boolean = false) {
    const m = {from: "", message: message, error: error};
    if (from == "AI") {
      m.from = "AI";
    } else {
      m.from = "You";
    }
    this.messages.push(m);

    // const lastDiv = this.listContainer.nativeElement.lastChild;
    // lastDiv.focus();

    // if (lastDiv instanceof HTMLElement && lastDiv.tagName.toLowerCase() === 'div') {
    //     lastDiv.focus();
    // }
    //
    console.log(this.chatContainer);
    //this.hiddentDiv?.nativeElement.scrollIntoView(true);
    if (this.chatContainer !== undefined) {
      const div = this.chatContainer?.nativeElement;

      if (div !== null && div !== undefined) {
        div.scrollTop = div.scrollHeight + 40;
      }

    }
  }



  showAPISignature() {

    if (this.toolType == '1') {

      const agent_id = this.toolId;
      const template_str = 'curl -X POST -H "Authorization: Bearer <api_key>"  -H "Content-Type: application/json"  -d \'{"agentId": "{{agent_key}}", "message":"<message>"}\'  https://www.cognix.ai/llmToolsJavaApi/tools/chat';
      this.apiStr = template_str.replace("{{agent_key}}", agent_id.toString());

    } else if (this.toolType == '2') {

      const tool_id = this.toolId;
      const template_str = 'curl -X POST -H "Authorization: Bearer <api_key>" -H "Content-Type: application/json"  -d \'{"toolId": "{{tool_id}}", "queryStr":"<query>"}\'  https://www.cognix.ai/llmToolsJavaApi/tools/query';
      this.apiStr = template_str.replace("{{tool_id}}", tool_id.toString());

    }
  }

  chat() {
    this.isLoading = true;

    //var formData: any = new FormData();
    console.log(" new message " + this.newMessage);
    //formData.append("agentId",this.selectedAgentId);
    //formData.append("message",this.newMessage);
    this.addMessageToList(this.newMessage, "User");

    this.toolsService.chat(
      this.toolId,
      this.newMessage,
      this.conversationSessionId,
      ""
    );
    this.chatstatus = "Message Sent!";
    this.newMessage = "";
  }

  query() {
    this.isLoading = true;

    this.toolsService.query(
      this.toolId,
      this.queryStr,
      this.conversationSessionId,
      ""
    );
  }

 

  ngOnDestroy() {
    this.toolGetSubscription?.unsubscribe();
    this.initChatAgentSubscription?.unsubscribe();
    this.chatSubscription?.unsubscribe();
    this.querySubscription?.unsubscribe();
  }
}
