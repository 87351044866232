import { Component, OnInit, OnDestroy } from "@angular/core";
import { DChannelComponent } from "../d-channel/d-channel.component";
import { CommonModule } from "@angular/common";
import { ToolService } from "../../../tool.service";
import { ActivatedRoute } from '@angular/router';
import { Subscription } from "rxjs";



@Component({
  selector: "deployment-tab",
  standalone: true,
  imports: [DChannelComponent, CommonModule],
  templateUrl: "./deployment-tab.component.html",
  styleUrls: ["./deployment-tab.component.css"],
})
export class DeploymentTabComponent implements OnInit, OnDestroy {
  showingMobileHelp: boolean = false;
  codeSnippetMap = new  Map<string,string>();
  getDeplymentCodeSnippetSubscription : Subscription | undefined;
  deployments: any[] = [
    {
      type: "PlainJSBasedWebsite",
      name: "Website Integration",
      description:
        "Deploy your chatbot to a website or test it out in a standalone mode",
      deployed: true,
      logoUrl: "../../../assets/icons/website.svg",
      added: true,
      accessableToMe: true,
      code:'',
      show:true
    },
    {
      type: "spa",
      name: "Single Page Application",
      description:
        "If you have angular or react based single page application. The bot can be integrated easily with your website.",
      deployed: false,
      logoUrl: "../../../assets/icons/messenger.svg",
      added: false,
      accessableToMe: false,
      code:'',
      show:false
    },
    {
      type: "api",
      name: "API Integration",
      description:
        " If you just want to integrate with our chat APIs that can be done as well.",
      deployed: false,
      logoUrl: "../../../assets/icons/whatsapp.svg",
      added: false,
      accessableToMe: false,
      code:'',
      show: false
    },
    {
      type: "wordpress",
      name: "Wordpress Plugin",
      description:
        "Download wordpress plugin via 'Download Worpress plugin' link in the top menu </a>",
      deployed: false,
      logoUrl: "../../../assets/icons/telegram.svg",
      added: false,
      accessableToMe: false,
      code:'',
      show:false
    },
  ];
  // get deployedItemsCount(): number {
  //   return this.deployments.filter((item) => item.deployed).length;
  // }

  constructor(private route: ActivatedRoute, private toolsService: ToolService) {
    console.log('--DeploymentTabComponent constructor--');
  }

  ngOnInit() {
    console.log('--DeploymentTabComponent ngOnInit--');
    var toolId = this.route.snapshot.queryParamMap.get("toolId");
    this.getDeplymentCodeSnippetSubscription = this.toolsService.getDeplymentCodeSnippetSubject.subscribe(
        {
          next: (data) =>{
            console.log('data = '+JSON.stringify(data))
            console.log(typeof data);

            this.codeSnippetMap =  new Map(Object.entries(data));
            console.log(typeof this.codeSnippetMap)
            this.deployments.forEach((element) => {
              element.code = this.codeSnippetMap.get(element.type)
              // if(element.type == 'PlainJSBasedWebsite') {
              //   element.show = true;
              // }

            })

          },
          error: (error) =>{

            console.log("Error occured while getting deployment code snippets "+JSON.stringify(error))

          },
          complete: () =>{
            console.debug(" Get get Deployment code snippet complete");

          }

        }

    );

    this.toolsService.getDeploymentCodeSnippets(toolId);

  }

  ngOnDestroy(){
    this.getDeplymentCodeSnippetSubscription?.unsubscribe();
  }
  
  
  
}
