<div class="input-wrapper">
  <input
    type="text"
    [(ngModel)]="value"
    placeholder="Type the link or click +Browse"
    class="text-input"
  />
  <div class="color-picker-icon" (click)="openColorPicker()">
    <div class="color-circle" [style.background-color]="value"></div>
  </div>
  <input
    type="color"
    id="colorInput"
    class="color-input"
    (change)="onColorSelected($event)"
  />
</div>
