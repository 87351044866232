import { Component, OnInit } from "@angular/core";
import { KbService } from "../KbService";
//import {KbCreateModel} from "./kb-create.model";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { KbOperationRequestDto } from "../KbOperationRequestDto";
import { MatSelectChange } from "@angular/material/select";
import { MessageService } from "src/app/shared/services/message.service";

@Component({
  selector: "app-kb-create",
  templateUrl: "./kb-create.component.html",
  styleUrls: ["./kb-create.component.css"],
})
export class KbCreateComponent implements OnInit {
  //showDropdown2 = false;

  fileUploadDataSourceSelected = false;
  googleDocsDataSourceSelected = false;
  webPageDataSourceSelected = false;
  webSiteDataSourceSelected = false;

  //dataSourceTypeValue :string = '';
  //indexTypeDropDownValue : string = '';
  //kbName : string = '';
  //kbAdditionalInfo: string = '';
  //@ViewChild('localFile') fileInput: ElementRef | undefined;

  isError: boolean = false;
  isSuccess: boolean = false;
  serverMessage: string = "";
  serverMessageKbRecordCreation: string = "";
  srcAddServerMessage: string = "";
  indexcreationServerMessage: string = "";

  kbRecordCreationSuccess: boolean = true;
  kbSourceAddSuccess: boolean = false;
  kbIndexCreationSuccess: boolean = false;

  createdKbId: number = -1;
  createdSrcId: number = -1;
  createdIndexId: number = -1;

  passedInKbId: any;
  passedInIndexId: any;
  passedInKbName: string | null = "";
  addIndexAction: boolean = false;
  updateIndexAction: boolean = false;

  // ui element toggle switches
  showIndexTypeField: boolean = true;

  showSelectIndexButtonSection: boolean = true;
  showCreateKBSection: boolean = true;
  showAddSourceSection: boolean = true;
  showIndexSelectionSection: boolean = true;

  srcType: string = "";

  // google doc
  googleDocIds: string = "";

  fileData: ArrayBuffer | undefined;

  isLoading1 = false;
  isLoading2 = false;
  isLoading3 = false;

  // public kbCreateForm: FormGroup = new FormGroup({
  //     localFile: new FormControl(),
  //     indexType: new FormControl({value: '', disabled: true}),
  //     kbName: new FormControl(),
  //     kbAdditionalInfo: new FormControl(),
  //     srcType: new FormControl({value: '', disabled: true}),
  //     srcDataGoogleDocs: new FormControl(),
  //     srcDataWebPages: new FormControl(),
  //     srcDataWebsite: new FormControl()
  //
  // })

  public kbCreateForm: FormGroup = new FormGroup({});

  createForm() {
    this.kbCreateForm = new FormGroup({
      localFile: new FormControl(),
      indexType: new FormControl({ value: "", disabled: true }),
      kbName: new FormControl(),
      kbAdditionalInfo: new FormControl(),
      srcType: new FormControl({ value: "", disabled: true }),
      srcDataGoogleDocs: new FormControl(),
      srcDataWebPages: new FormControl(),
      srcDataWebsite: new FormControl(),
    });
  }

  constructor(
    private kbService: KbService,
    private route: ActivatedRoute,
    public messageService: MessageService
  ) {}

  ngOnInit() {
    this.createForm();
    this.passedInKbId = this.route.snapshot.queryParamMap.get("kbId");
    this.passedInKbName = this.route.snapshot.queryParamMap.get("kbName");
    this.passedInIndexId = this.route.snapshot.queryParamMap.get("indexId");
    const addIndexActionStr =
      this.route.snapshot.queryParamMap.get("addIndexAction");
    this.addIndexAction = Boolean(addIndexActionStr);
    if (this.addIndexAction) {
      //this.toggeleFieldsForAddIndexOperation();
      this.toggleFields();
    }

    const updateIndexActionStr =
      this.route.snapshot.queryParamMap.get("updateIndexAction");
    this.updateIndexAction = Boolean(updateIndexActionStr);

    if (this.updateIndexAction) {
      //this.toggeleFieldsForUpdateIndexOperation()
      this.toggleFields();
    }
    console.log(
      "kbCreate passed in data --->" +
        addIndexActionStr +
        " " +
        updateIndexActionStr
    );
    console.log(
      "kbCreate passed in data --->" +
        this.passedInKbId +
        " " +
        this.passedInIndexId +
        " " +
        this.passedInKbName +
        " " +
        this.addIndexAction +
        " " +
        this.updateIndexAction
    );
  }

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files![0];
    console.log("file -->" + file);

    const fileReader = new FileReader();

    fileReader.readAsText(file);

    console.log("after reading file." + fileReader.result);
    fileReader.onload = () => {
      console.log(" Inside file reader onLoad " + fileReader.result);

      this.kbCreateForm?.patchValue({
        localFile: file,
      });
      this.kbCreateForm?.get("localFile")?.updateValueAndValidity();
    };

    this.fileData = new ArrayBuffer(file.size);
    const fileReader1 = new FileReader();
    fileReader1.readAsArrayBuffer(file);
    fileReader1.onload = () => {
      console.log(" Inside file reader1 onLoad " + fileReader1.result);

      if (
        fileReader1.result != null &&
        typeof fileReader1.result !== "string"
      ) {
        this.fileData = fileReader1.result;
      }
    };
  }

  createKbRecord() {
    this.isLoading1 = true;

    var kbName = this?.kbCreateForm?.get("kbName")?.value;
    var kbAdditionalInfo = this?.kbCreateForm?.get("kbAdditionalInfo")?.value;

    this.kbService.createKbRecord(kbName, kbAdditionalInfo).subscribe(
      (response) => {
        this.isLoading1 = false;
        this.kbRecordCreationSuccess = true;
        this.createdKbId = response.createdId;

        var message =
          response.message + "Next, add data/document to the knowledgebase.";

        this.messageService.showSuccess(message);
        // this.showServerMessage('serverMessageKbRecordCreation',message)
        console.log("this.createdKbId" + this.createdKbId);
        this.kbCreateForm.get("srcType")?.enable();
      },
      (error) => {
        this.isLoading1 = false;

        this.messageService.showError(
          "Oops ! something went wrong while creating record."
        );
        // this.showServerMessage( 'serverMessageKbRecordCreation','Oops ! something went wrong while creating record.');
        this.kbRecordCreationSuccess = false;

        this.createdKbId = -1;
      }
    );
  }

  addSrcToKb() {
    if (this.addIndexAction) {
      console.log(
        "addSrcToExistingKb: adding source to the existing KB : " +
          this.passedInKbId
      );
      this.addSrcToKbInternal(this.passedInKbId);
    } else {
      console.log(
        "addSrcToKb: adding source to the newly created KB : " +
          this.createdKbId
      );
      this.addSrcToKbInternal(this.createdKbId);
    }
  }

  addSrcToKbInternal(kbId: number) {
    this.isLoading2 = true;

    var formData: any = new FormData();
    var srcType = this?.kbCreateForm?.get("srcType")?.value;

    var srcDataList = this.populateFormData(formData, srcType);

    var data = { srcType: srcType, dataName: srcDataList };

    formData.append(
      "dataRequestDto",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );

    this.kbService.addSrcToKb(formData, kbId).subscribe(
      (response) => {
        this.isLoading2 = false;
        this.kbSourceAddSuccess = true;
        this.createdSrcId = response.createdId;
        this.messageService.showSuccess(response.message);
        // this.showServerMessage('srcAddServerMessage',response.message);

        console.log("this.createdSrcId" + this.createdSrcId);
        this.kbCreateForm.get("indexType")?.enable();
      },
      (error) => {
        this.isLoading2 = false;
        this.kbSourceAddSuccess = false;
        this.messageService.showError(
          "Error! we hit snag while adding document to the knowledge base"
        );
        // this.showServerMessage( 'srcAddServerMessage',"Error! we hit snag while adding document to the knowledge base");
        this.kbSourceAddSuccess = false;
        this.createdSrcId = -1;
      }
    );
  }

  createIndex() {
    this.isLoading3 = true;

    var indexType = this?.kbCreateForm?.get("indexType")?.value;

    var data = { indexType: indexType };

    var kbId = this.addIndexAction ? this.passedInKbId : this.createdKbId;

    this.kbService.createIndex(kbId, this.createdSrcId, indexType).subscribe(
      (response) => {
        this.isLoading3 = false;
        this.kbIndexCreationSuccess = true;

        if (this.addIndexAction) {
          this.messageService.showSuccess(
            "Success !! Document has been added to the knowledgebase"
          );
          // this.showServerMessage('indexcreationServerMessage',"Success !! Document has been added to the knowledgebase");
        } else {
          this.messageService.showSuccess(
            "KnowledgeBase created successfully."
          );
          // this.showServerMessage('indexcreationServerMessage',"KnowledgeBase created successfully.");
        }

        this.createdIndexId = response.createdId;
        //after successful creation we are restting the form and variables so that fields can show up disabled
        this.kbRecordCreationSuccess = false;
        this.kbSourceAddSuccess = false;
        this.createForm();
      },
      (error) => {
        this.isLoading3 = false;
        this.kbIndexCreationSuccess = false;

        this.messageService.showError("Error! while creating knowledgebase.");
        // this.showServerMessage('indexcreationServerMessage',"Error! while creating knowledgebase.");
        this.createdIndexId = -1;
      }
    );
  }

  addSrcToExistingIndex() {
    this.isLoading3 = true;

    var formData: any = new FormData();
    var srcType = this?.kbCreateForm?.get("srcType")?.value;

    var srcDataList = this.populateFormData(formData, srcType);

    var data = { srcType: srcType, dataName: srcDataList };

    console.log("addSrcToExistingIndex : data : " + JSON.stringify(data));

    formData.append(
      "dataRequestDto",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );

    this.kbService.addSrcToTheIndx(this.passedInIndexId, formData).subscribe(
      (response) => {
        this.isLoading3 = false;

        if (this.updateIndexAction) {
          this.messageService.showSuccess(
            "New Document has been added successfully"
          );
          // this.showServerMessage('srcAddServerMessage'," New Document has been added successfully");
        } else {
          this.messageService.showSuccess(
            "Document has been added successfully. Please choose few optional settings or just hit 'create knowledge base button below'"
          );
          // this.showServerMessage('srcAddServerMessage'," Document has been added successfully. Please choose few optional settings or just hit 'create knowledge base button below'");
        }

        this.kbSourceAddSuccess = true;
        this.createdSrcId = response.createdId;
        console.log("this.createdSrcId" + this.createdSrcId);
      },
      (error) => {
        console.log(" addSrcToExistingIndex : Error Occured");
        this.isLoading3 = false;
        this.kbSourceAddSuccess = false;
        this.createdSrcId = -1;

        if (this.updateIndexAction) {
          this.messageService.showError(
            "Error! we hit snag while updating the index"
          );
          // this.showServerMessage( 'srcAddServerMessage',"Error! we hit snag while updating the index");
        } else {
          this.messageService.showError(
            "Error! we hit snag while adding data to the knowledge base"
          );
          // this.showServerMessage( 'srcAddServerMessage',"Error! we hit snag while adding data to the knowledge base");
        }
      }
    );
  }

  populateFormData(formData: FormData, srcType: string): string {
    var srcDataList = "";
    if (srcType === "GoogleDocs") {
      srcDataList = this?.kbCreateForm?.get("srcDataGoogleDocs")?.value;
    } else if (srcType === "WebPages") {
      srcDataList = this?.kbCreateForm?.get("srcDataWebPages")?.value;
    } else if (srcType === "Website") {
      srcDataList = this?.kbCreateForm?.get("srcDataWebsite")?.value;
    }
    formData.append(
      "uploadedFile",
      this?.kbCreateForm?.get("localFile")?.value
    );

    return srcDataList;
  }

  onSubmit() {
    var formData: any = new FormData();

    const kbOperationRequestDto = new KbOperationRequestDto();

    const srcType = this?.kbCreateForm?.get("srcType")?.value;

    kbOperationRequestDto.srcType = srcType;
    if (srcType === "GoogleDocs") {
      kbOperationRequestDto.srcDataList =
        this?.kbCreateForm?.get("srcDataGoogleDocs")?.value;
    } else if (srcType === "WebPages") {
      kbOperationRequestDto.srcDataList =
        this?.kbCreateForm?.get("srcDataWebPages")?.value;
    } else if (srcType === "Website") {
      kbOperationRequestDto.srcDataList =
        this?.kbCreateForm?.get("srcDataWebsite")?.value;
    }
    formData.append(
      "uploadedFile",
      this?.kbCreateForm?.get("localFile")?.value
    );

    kbOperationRequestDto.kbName = this?.kbCreateForm?.get("kbName")?.value;
    kbOperationRequestDto.kbAdditionalInfo =
      this?.kbCreateForm?.get("kbAdditionalInfo")?.value;
    kbOperationRequestDto.indexType =
      this?.kbCreateForm?.get("indexType")?.value;
    kbOperationRequestDto.srcType = srcType;

    formData.append(
      "kbOperationRequestDto",
      new Blob([JSON.stringify(kbOperationRequestDto)], {
        type: "application/json",
      })
    );

    console.log("  this.fileData?.byteLength = " + this.fileData?.byteLength);

    //this.kbService.postFormData(this.uploadedFile,this.indexTypeDropDownValue,this.kbAdditionalInfo,this.kbName)
    this.kbService.postFormData(formData).subscribe(
      (response) => {
        console.log("API response:", response);
        this.isSuccess = true;
        this.serverMessage = response.message;
        // handle the response from the API endpoint
      },
      (error) => {
        console.error("API error:", error);
        this.isError = true;
        this.serverMessage =
          "Oops!! something went wrong. Please try after sometime.";
        // handle the error from the API endpoint
      }
    );
  }

  addIndex() {
    console.log("Add index got invoked() " + this.addIndexAction);

    var formData: any = new FormData();

    const kbOperationRequestDto = new KbOperationRequestDto();

    const srcType = this?.kbCreateForm?.get("srcType")?.value;

    kbOperationRequestDto.srcType = srcType;

    if (srcType === "GoogleDocs") {
      kbOperationRequestDto.srcDataList =
        this?.kbCreateForm?.get("srcDataGoogleDocs")?.value;
    } else if (srcType === "WebPages") {
      kbOperationRequestDto.srcDataList =
        this?.kbCreateForm?.get("srcDataWebPages")?.value;
    } else if (srcType === "Website") {
      kbOperationRequestDto.srcDataList =
        this?.kbCreateForm?.get("srcDataWebsite")?.value;
    } else {
      formData.append(
        "uploadedFile",
        this?.kbCreateForm?.get("localFile")?.value
      );
    }

    kbOperationRequestDto.indexType =
      this?.kbCreateForm?.get("indexType")?.value;
    kbOperationRequestDto.srcType = srcType;
    kbOperationRequestDto.kbId = this.passedInKbId;

    formData.append(
      "kbOperationRequestDto",
      new Blob([JSON.stringify(kbOperationRequestDto)], {
        type: "application/json",
      })
    );

    //this.kbService.postFormData(this.uploadedFile,this.indexTypeDropDownValue,this.kbAdditionalInfo,this.kbName)
    this.kbService.addIndex(formData).subscribe(
      (response) => {
        console.log("API response:", response);
        this.isSuccess = true;
        this.serverMessage = response.message;
        // handle the response from the API endpoint
      },
      (error) => {
        console.error("API error:", error);
        this.isError = true;
        this.serverMessage =
          "Oops!! something went wrong. Please try after sometime.";
        // handle the error from the API endpoint
      }
    );
  }

  updateIndex() {
    console.log("Update index got invoked() " + this.updateIndexAction);

    var formData: any = new FormData();

    const kbOperationRequestDto = new KbOperationRequestDto();

    const srcType = this?.kbCreateForm?.get("srcType")?.value;

    kbOperationRequestDto.srcType = srcType;

    kbOperationRequestDto.indexId = this.passedInIndexId;

    if (srcType === "GoogleDocs") {
      kbOperationRequestDto.srcDataList =
        this?.kbCreateForm?.get("srcDataGoogleDocs")?.value;
    } else if (srcType === "WebPages") {
      kbOperationRequestDto.srcDataList =
        this?.kbCreateForm?.get("srcDataWebPages")?.value;
    } else if (srcType === "Website") {
      kbOperationRequestDto.srcDataList =
        this?.kbCreateForm?.get("srcDataWebsite")?.value;
    } else {
      formData.append(
        "uploadedFile",
        this?.kbCreateForm?.get("localFile")?.value
      );
    }

    formData.append(
      "kbOperationRequestDto",
      new Blob([JSON.stringify(kbOperationRequestDto)], {
        type: "application/json",
      })
    );

    //this.kbService.postFormData(this.uploadedFile,this.indexTypeDropDownValue,this.kbAdditionalInfo,this.kbName)
    this.kbService.updateIndex(formData).subscribe(
      (response) => {
        console.log("API response:", response);
        this.isSuccess = true;
        this.serverMessage = response.message;
        // handle the response from the API endpoint
      },
      (error) => {
        console.error("API error:", error);
        this.isError = true;
        this.serverMessage =
          "Oops!! something went wrong. Please try after sometime.";
        // handle the error from the API endpoint
      }
    );
  }

  toggeleFieldsForAddIndexOperation() {
    this.showIndexTypeField = true;
    this.showCreateKBSection = false;
    this.kbCreateForm.get("srcType")?.enable();
  }

  toggeleFieldsForUpdateIndexOperation() {
    this.showIndexTypeField = false;
    this.showCreateKBSection = false;
    this.showSelectIndexButtonSection = false;
    this.kbCreateForm.get("srcType")?.enable();
  }

  toggleFields() {
    this.showCreateKBSection = !this.addIndexAction && !this.updateIndexAction;
    this.showAddSourceSection = this.updateIndexAction || this.addIndexAction;
    this.showIndexSelectionSection = !this.updateIndexAction;

    if (this.addIndexAction || this.updateIndexAction) {
      this.kbCreateForm.get("srcType")?.enable();
    }
  }

  onDataSourceOptionChange(event: MatSelectChange) {
    const value = event.value;
    this.turnoffAllShowDataSourceFlags();
    if (value === "File") {
      this.fileUploadDataSourceSelected = true;
    } else if (value === "GoogleDocs") {
      this.googleDocsDataSourceSelected = true;
    } else if (value === "WebPages") {
      this.webPageDataSourceSelected = true;
    } else if (value === "Website") {
      this.webSiteDataSourceSelected = true;
    }
  }

  turnoffAllShowDataSourceFlags() {
    this.fileUploadDataSourceSelected = false;
    this.googleDocsDataSourceSelected = false;
    this.webPageDataSourceSelected = false;
    this.webSiteDataSourceSelected = false;
  }

  showServerMessage(messageType: string, message: string, time: number = 5000) {
    if (messageType == "serverMessageKbRecordCreation") {
      this.serverMessageKbRecordCreation = message;
      setTimeout(() => {
        this.serverMessageKbRecordCreation = "";
      }, time); // close the alert after 5 seconds (5000 milliseconds)
    } else if (messageType == "srcAddServerMessage") {
      this.srcAddServerMessage = message;
      setTimeout(() => {
        this.srcAddServerMessage = "";
      }, time); // close the alert after 5 seconds (5000 milliseconds)
    } else if (messageType == "indexcreationServerMessage") {
      this.indexcreationServerMessage = message;
      setTimeout(() => {
        this.indexcreationServerMessage = "";
      }, time);
    } else {
      this.serverMessage = message;
      setTimeout(() => {
        this.serverMessage = "";
      }, time); // close the alert after 5 seconds (5000 milliseconds)
    }
  }
}
