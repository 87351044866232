import { Component } from '@angular/core';

@Component({
  selector: 'app-kb',
  templateUrl: './kb.component.html',
  styleUrls: ['./kb.component.css']
})
export class KbComponent {


  constructor() {
  }

}
