import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"],
})
export class PaymentComponent implements OnInit, OnDestroy {
  param_subscription: Subscription | undefined;

  paymentStatus: boolean = false;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.param_subscription = this.activatedRoute.params.subscribe(
      (params) => {
        const status = params["status"];
        this.paymentStatus = status;
      },
      (error) => {}
    );
  }

  ngOnDestroy() {
    this.param_subscription?.unsubscribe();
  }
}
