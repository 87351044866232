
<div class="container">

    <div style="border-bottom: skyblue solid 1px">

        <div  id="navbarSupportedContent">
            <!-- Navbar brand -->
            <a class=" navbar-brand mt-lg-0" href="#">
                <img
                        src="assets/nlp_tools_A.png"
                        height="80"
                        alt="MDB Logo"
                        loading="lazy"
                />
            </a>
        </div>
    </div>
    <div style="font-weight: bold ;margin-top: 2em;margin-bottom: 1.5em">
        Your account has been activated. Please signin with your username/email and password.
    </div>


        <br>


    <a href="#" class="btn btn-primary" role="button">Sign In</a>



</div>
