import { Component, Input } from '@angular/core'
import { ColorInputComponent } from '../../../core/color-input/color-input.component'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { FileInputComponent } from '../../../core/file-input/file-input.component'
import { TextWithTipComponent } from '../../../core/text-with-tip/text-with-tip.component'
import { DisplaySettingsModel } from '../../../display-tab/display-settings.model'

@Component({
  selector: 'app-chat-header',
  standalone: true,
  imports: [
    ColorInputComponent,
    FormsModule,
    CommonModule,
    FileInputComponent,
    TextWithTipComponent,
  ],
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.css'],
})
export class ChatHeaderComponent {
  @Input()
  toolSettingsModel!: DisplaySettingsModel
  @Input()
  toolTips: { fieldHelpKey: string; fieldHelpValue: string }[] = []
  @Input() toolId: string = "";

  constructor() {
    console.log(this.toolSettingsModel)
  }

  ngOnInit() {
    console.log('--------------------chat header component------------')
    console.log(this.toolSettingsModel)
  }

  selectedHeader: string = 'icon'
}
