export class KbOperationRequestDto {
    kbName : string = '';
    kbAdditionalInfo:string = '';
    indexType: string = '';
    srcType: string = '';
    kbId: number = 0;
    indexId: number=0;
    srcDataList: string ='';

}
