import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../app.service";
import { Subscription } from "rxjs";
import { AuthService } from "../auth/auth.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  globalMessageSubscription: Subscription | undefined;
  userMessageSubscription: Subscription | undefined;

  globalMessages: string[] | undefined;

  userMessages: string[] | undefined;

  constructor(
    private router: Router,
    private appService: AppService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.globalMessageSubscription =
      this.appService.globalMessageSubject.subscribe(
        (response) => {
          console.log(" messages ", JSON.stringify(response));
          this.globalMessages = response;
        },
        (error) => {
          console.log("Error occured while getting gloabl messages");
        }
      );

    this.userMessageSubscription =
      this.appService.userMessagesSubject.subscribe(
        (response) => {
          console.log(" userMessages ", JSON.stringify(response));
          this.userMessages = response;
        },
        (error) => {
          console.log("Error occured while getting user messages");
        }
      );

    this.appService.getGlobalMessages();
    this.appService.getUserMessage();
  }

  ngOnDestroy() {
    this.globalMessageSubscription?.unsubscribe();
    this.userMessageSubscription?.unsubscribe();
  }

  onHome() {
    this.router.navigate(["dashboard/tools"]);
  }
  onKnowledge() {
    this.router.navigate(["dashboard/knowledgeBase"]);
  }
  onQuery() {
    this.router.navigate(["dashboard/query"]);
  }
  onPrompts() {
    this.router.navigate(["dashboard/prompts"]);
  }
  onSetting() {
    this.router.navigate(["dashboard/settings"]);
  }
  onTokenUsage() {
    this.router.navigate(["dashboard/tokenUsage"]);
  }

  onSubscription() {
    this.router.navigate(["dashboard/subscription"]);
  }
}
