import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { SubscriptionService } from "./subscription.service";
import { SubscriptionModel } from "./subscription.model";
import { TransactionModel } from "./transaction.model";

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.css"],
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  subscriptionListSubscription: Subscription | undefined;

  checkActiveSubscriptionSubscription: Subscription | undefined;

  subscriptionList: SubscriptionModel[] | undefined;

  transactionListSubsscription: Subscription | undefined;
  transactionList: TransactionModel[] | undefined;

  autoRenewOn: boolean = false;

  activeSubscriptionExists: boolean = false;

  activeSubscription: SubscriptionModel | undefined;

  activeSubscriptionStatus: boolean = false;

  constructor(
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    this.subscriptionListSubscription =
      this.subscriptionService.listSubscriptonsSubject.subscribe(
        (data) => {
          this.subscriptionList = data;
          console.log(
            "SubscriptionComponent : subscriptionList " +
              JSON.stringify(this.subscriptionList)
          );

          this.findActiveSubscription();
        },
        (error) => {
          console.log("error " + error);
        }
      );

    this.transactionListSubsscription =
      this.subscriptionService.listTransactionsSubject.subscribe(
        (data) => {
          this.transactionList = data;
          console.log(
            "SubscriptionComponent : transactionList " +
              JSON.stringify(this.transactionList)
          );
        },
        (error) => {
          console.log("error " + error);
        }
      );

    this.checkActiveSubscriptionSubscription =
      this.subscriptionService.checkActiveSubscriptionSubject.subscribe(
        (data) => {
          this.activeSubscriptionStatus = data;
        },
        (error) => {
          this.activeSubscriptionStatus = false;
        }
      );

    this.subscriptionService.getSubscriptionList();
    this.subscriptionService.getTransactionList();
    this.subscriptionService.checkActiveSubscription();
  }

  findActiveSubscription() {
    if (this.subscriptionList) {
      this.activeSubscription = this.subscriptionList.find(
        (subscription) => subscription.status === "Active"
      );
    }
  }
  autoRenew() {
    if (this.activeSubscription) {
      if (this.autoRenewOn) {
        this.subscriptionService.toggleAutoRenew(
          this.activeSubscription["subscriptionId"],
          true
        );
      } else {
        this.subscriptionService.toggleAutoRenew(
          this.activeSubscription["subscriptionId"],
          false
        );
      }
    }
  }
  pay() {
    this.subscriptionService.makePayment();
  }

  createSubscription() {
    this.subscriptionService.createSubscription(4);
  }

  manageSubscriptions() {
    this.subscriptionService.manageSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptionListSubscription?.unsubscribe();
    this.transactionListSubsscription?.unsubscribe();
    this.checkActiveSubscriptionSubscription?.unsubscribe();
  }
}
