import { Component } from '@angular/core';

@Component({
  selector: 'app-userguide',
  templateUrl: './userguide.component.html',
  styleUrls: ['./userguide.component.css']
})
export class UserguideComponent {

}
