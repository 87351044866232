<div class="container">
  <div class="checkbox-container">
    <input type="checkbox" id="fileUpload" class="checkbox-input" [(ngModel)]="toolSettingsModel.fileUploadEnabled" />
    <label class="checkbox-label" for="fileUpload">Enable ‘File Upload’ menu</label>
  </div>
  <div class="checkbox-container">
    <input type="checkbox" id="customerInfo" class="checkbox-input" [(ngModel)]="toolSettingsModel.askCustomerInfoEnabled" />
    <label class="checkbox-label" for="customerInfo">Enable ‘Gather Customer Info’ </label>
  </div>
  <div class="checkbox-container">
    <input type="checkbox" class="checkbox-input" id="clearHistory" [(ngModel)]="toolSettingsModel.clearConversationHistoryMenuEnabled" />
    <label class="checkbox-label" for="clearHistory">Enable ‘Clear Conversation History’ menu</label>
  </div>
  <div class="checkbox-container">
    <input type="checkbox" class="checkbox-input" id="showIcon" [(ngModel)]="toolSettingsModel.chatMenuIconsShown" />
    <label class="checkbox-label" for="showIcon">Show icons with chat menu</label>
  </div>
  <!-- <button  type="button" class="add-menu-button">+ Add Menu Option</button> -->
</div>
