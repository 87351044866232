import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { DashboardComponent } from './dashboard/dashboard.component'
import { KbComponent } from './kb/kb.component'
import { KbCreateComponent } from './kb/kb-create/kb-create.component'
import { QueryComponent } from './query/query.component'
import { ToolsComponent } from './tools/tools.component'
import { SettingsComponent } from './settings/settings.component'
import { PromptComponent } from './prompt/prompt.component'
import { PromptCreateComponent } from './prompt/prompt-create/prompt-create.component'
import { AuthGuard } from './auth/auth-guard.service'
import { ToolListComponent } from './tools/tool-list/tool-list.component'
import { ActivationCompleteComponent } from './auth/activation-complete/activation-complete.component'
import { ToolEditComponent } from './tools/tool-edit/tool-edit.component'
import { ToolTryComponent } from './tools/tool-try/tool-try.component'
import { TokenUsageComponent } from './token-usage/token-usage.component'
import { SubscriptionComponent } from './subscription/subscription.component'
import { PricingComponent } from './pricing/pricing.component'
import { PaymentComponent } from './payment/payment.component'
import { SupportComponent } from './support/support.component'
import { WordpressPluginInfoComponent } from './wordpress-plugin-info/wordpress-plugin-info.component'
import { ToolConfigureComponent } from './tools/configure/configure.component'
import {UserguideComponent} from './userguide/userguide.component'

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },

  { path: 'activationComplete', component: ActivationCompleteComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'wpPluginInfo', component: WordpressPluginInfoComponent },
  // {path: 'userGuide', component: UserguideComponent},
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: 'knowledgeBase',
        component: KbComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'knowledgeBase/create',
        component: KbCreateComponent,
        canActivate: [AuthGuard],
      },
      { path: 'query', component: QueryComponent, canActivate: [AuthGuard] },
      {
        path: 'tools',
        component: ToolsComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ToolListComponent },
          {
            path: 'list',
            component: ToolListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'config',
            component: ToolConfigureComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit',
            component: ToolEditComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'try',
            component: ToolTryComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
      },

      {
        path: 'tokenUsage',
        component: TokenUsageComponent,
        canActivate: [AuthGuard],
      },
      { path: 'prompts', component: PromptComponent, canActivate: [AuthGuard] },
      {
        path: 'prompts/create',
        component: PromptCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'subscription',
        component: SubscriptionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'payment',
        component: PaymentComponent,
        children: [{ path: ':status', component: PaymentComponent }],
      },
      {
        path: 'pricing',
        component: PricingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'support',
        component: SupportComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  { path: '**', redirectTo: 'auth' },
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
