import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.css']
})
export class PromptComponent {
  constructor(private router: Router) {
  }

  addNewPrompt(){
    this.router.navigate(['/dashboard/prompts/create']);
  }

}
