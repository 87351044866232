import { Injectable } from "@angular/core";
import { AppConstants } from "../app-constants";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Subject, Observable, map } from "rxjs";
//import {KbCreateModel} from "./kb-create/kb-create.model";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class KbService {
  kb_created_subject = new Subject();

  constructor(
    private appConstants: AppConstants,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  createKbRecord(kbName: string, additionalInfo: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", this.authService.getAuthToken());
    const data = { kbName: kbName, kbAdditionalInfo: additionalInfo };
    let url = this.appConstants.createKbRecordUrl();
    return this.http.post(url, data, { headers: headers });
  }

  addSrcToKb(formData: FormData, kbId: number): Observable<any> {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    var url = this.appConstants.addSrcToKbUrl(kbId);

    // Make the API request
    return this.http.post(url, formData, { headers: headers });
  }

  createIndex(kbId: number, srcId: number, indexType: number): Observable<any> {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    var url = this.appConstants.createIndexUrl(kbId, srcId);

    const data = { indexType: indexType };

    return this.http.post(url, data, { headers: headers });
  }

  addSrcToTheIndx(indexId: number, formData: FormData): Observable<any> {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    var url = this.appConstants.addSrcToIndex(indexId);

    return this.http.post(url, formData, { headers: headers });
  }

  postFormData(formData: FormData): Observable<any> {
    // Set the HTTP headers

    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    var url = this.appConstants.createKbUrl();

    // Make the API request
    return this.http.post(url, formData, { headers: headers });
  }

  addIndex(formData: FormData): Observable<any> {
    // Set the HTTP headers

    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());
    var url = this.appConstants.getAddIndexToKbUrl();
    console.log(" Add Index url --->" + url);

    // Make the API request
    return this.http.post(url, formData, { headers: headers });
  }

  updateIndex(formData: FormData): Observable<any> {
    // Set the HTTP headers

    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    var url = this.appConstants.updateIndexUrl();

    console.log(" update Index url --->" + url);

    // Make the API request
    return this.http.post(url, formData, { headers: headers });
  }

  getMyKBlist(): Observable<any> {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    return this.http
      .get(this.appConstants.getMyKbListUrl(), { headers: headers })
      .pipe(
        map((response) => {
          return JSON.parse(JSON.stringify(response));
        })
      );
  }

  getMyKBIndexeslist(): Observable<any> {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    return this.http
      .get(this.appConstants.getKBIndexesList(), { headers: headers })
      .pipe(
        map((response) => {
          return JSON.parse(JSON.stringify(response));
        })
      );
  }

  convertToCompositeIndex(id: number, compIndexType: string): Observable<any> {
    // Set the HTTP headers

    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    var url = this.appConstants.convertToCompositeIndexUrl(id);

    let params = new HttpParams().set("indexType", compIndexType);

    console.log(" convert Index url --->" + url);

    // Make the API request
    return this.http.post(url, params, { headers: headers });
  }
}
