import {Prompt} from '../prompt/prompt.model'
export class ToolConfigModel {
    id: number = 0;
    toolId: number = 0;
    temperature: number = 0;
    maxInputSize: number = 0;
    maxOutputSize: number = 0;
    maxChunkOverlap: number = 0;
    maxTokens: number = 0;
    modelName: string = '';
    llmProvider: string = '';
    embeddingProvider: string = '';
    similarityTopK: number = 0;
    returnDirect: boolean = false;
    returnSources: boolean = false;
    returnMessage: boolean = false;
    verboseChat: boolean = false;
    embeddingModel: string = '';
    applyMetaDataFilter: boolean = false;
    applyRecencyFilter:boolean = false;
    contextPromptDto: Prompt = new Prompt()
    condensePromptDto: Prompt = new Prompt()
    systemPromptDto: Prompt = new Prompt()
    condenseQuestionPromptDto: Prompt = new Prompt()
}
