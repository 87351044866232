<mat-card class="position-relative">
    <h3 class="py-4 px-5">Chat History</h3>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1" [mat-dialog-close]="true"
        id="Capa_1" width="16px" height="16px" viewBox="0 0 94.926 94.926" xml:space="preserve">
        <g>
            <path
                d="M55.931,47.463L94.306,9.09c0.826-0.827,0.826-2.167,0-2.994L88.833,0.62C88.436,0.224,87.896,0,87.335,0   c-0.562,0-1.101,0.224-1.498,0.62L47.463,38.994L9.089,0.62c-0.795-0.795-2.202-0.794-2.995,0L0.622,6.096   c-0.827,0.827-0.827,2.167,0,2.994l38.374,38.373L0.622,85.836c-0.827,0.827-0.827,2.167,0,2.994l5.473,5.476   c0.397,0.396,0.936,0.62,1.498,0.62s1.1-0.224,1.497-0.62l38.374-38.374l38.374,38.374c0.397,0.396,0.937,0.62,1.498,0.62   s1.101-0.224,1.498-0.62l5.473-5.476c0.826-0.827,0.826-2.167,0-2.994L55.931,47.463z" />
        </g>
    </svg>
    <mat-card-content class="px-5" [ngClass]="{'scroll' :  sessions.length}">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                *ngFor="let item of sessions;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>Session Id </strong>- {{item.sessionId}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let data of item.conversationRequestList">
                    <div> <strong>Request : </strong>
                        <p>{{data.requestText}}</p>
                    </div>
                    <div> <strong>Response : </strong>
                        <p>{{data.responseText}}</p>
                    </div>
                </div>
                <div *ngIf="item.conversationRequestList.length === 0">
                    No Chat Found
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div *ngIf="loading">
            Please wait, while we are fetching Chat History...
        </div>
        <div *ngIf="!loading && sessions.length === 0">
            No Chat History Found
        </div>
    </mat-card-content>
</mat-card>