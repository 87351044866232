export class PromptModel {
    name:string ='';
    text:string = '';
    promptType:string = '';
}

export class Prompt {
    id:number = -1;
    name: string = '';
    text : string = '';
    userId:number = 1;
    createdAt: string = ''
    updatedAt : string = ''
    promptType: number = 0

}

export class PromptMapWrapper {
    promptMap : Map<string,[Prompt]> = new Map()

}
