import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, take } from 'rxjs';
import { ToolService } from '../tool.service';

@Component({
  selector: 'app-chat-history-view',
  templateUrl: './chat-history-view.component.html',
  styleUrls: ['./chat-history-view.component.css']
})
export class ChatHistoryViewComponent implements OnInit{
  panelOpenState = false;
  sessions: any[] = [];
  loading = false;
  constructor(private toolsService: ToolService, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    console.log('ToolListComponent created ', this.data)
    this.loading = true;
    this.toolsService.conversationHistory(this.data.id).pipe(take(1)).subscribe(
      (response: any) => {
        this.loading = false;
        this.sessions = [...response];
      },
      (error: any) => {
        this.loading = false;
        console.log(error);
      }
    )
  }
}
