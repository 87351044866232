import { Component } from '@angular/core';

@Component({
  selector: 'app-activation-complete',
  templateUrl: './activation-complete.component.html',
  styleUrls: ['./activation-complete.component.css']
})
export class ActivationCompleteComponent {

}
