<div class="container">
  <div class="form-group">
    <div class="form-label">Bot Position</div>
    <select
      class="select-input"
      [(ngModel)]="toolSettingsModel.chatWidgetPosition"
    >
      <option value="bottom-left">Bottom left</option>
      <option value="bottom-right">Bottom right</option>
      <option value="top-left">Top Left</option>
      <option value="top-right">Top Right</option>
    </select>
  </div>
  <div class="form-group">
    <div class="form-label">Bot Launch Type</div>
    <select [(ngModel)]="toolSettingsModel.launchType" class="select-input" (change)="onChange($event)">
      <option value="icon">Launch icon only</option>
      <!-- <option value="callout">Launch icon with callout</option> -->
    </select>
  </div>
  <div *ngIf="toolSettingsModel.launchType === 'icon'" class="file-input-container">
    <div class="form-label">
      <pi [text]="'Launch Icon'" [toolTip]="getTooltip('launch_icon')"></pi>
    </div>
    <customFileInput
      [fieldName]="'iconUrl'"
      [(value)]="toolSettingsModel.iconUrl"
      [tool]="toolId"
    ></customFileInput>
  </div>
  <div *ngIf="toolSettingsModel.launchType === 'callout'" class="file-input-container">
    <div class="form-label">
      <pi [text]="'Launch Icon'" [toolTip]="getTooltip('launch_icon')"></pi>
    </div>
    <customFileInput
      [fieldName]="'iconUrl'"
      [(value)]="toolSettingsModel.iconUrl"
      [tool]="toolId"
    ></customFileInput>
    <div class="form-group">
      <div class="form-label">
        <pi
          [text]="'Callout Message'"
          [toolTip]="getTooltip('callout_message')"
        ></pi>
      </div>
      <input
        type="text"
        placeholder="Write a reply ..."
        class="text-input"
        [(ngModel)]="toolSettingsModel.calloutMessage"
      />
    </div>
  </div>
  <div class="checkbox-container">
    <input
      type="checkbox"
      id="autopopup"
      class="checkbox-input"
      disabled
      [(ngModel)]="toolSettingsModel.autoEnablePopUpDelay"
    />
    <label for="autopopup" class="checkbox-label">Chat Auto Pop-up</label>
  </div>
</div>
