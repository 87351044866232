import { Component, OnInit } from "@angular/core";
import { KbService } from "../KbService";
import { Router } from "@angular/router";
import { MessageService } from "src/app/shared/services/message.service";

@Component({
  selector: "app-kb-list",
  templateUrl: "./kb-list.component.html",
  styleUrls: ["./kb-list.component.css"],
})
export class KbListComponent implements OnInit {
  isError: boolean = false;
  isSuccess: boolean = false;
  serverMessage: string = "";

  kbList: any = null;
  selectedKbIndex: number = 0;
  selectedKbIndexForVisible: number = -1;
  isAddIndex = false;
  showFileuploadDataSource = false;
  showGoogleDocsDataSource = false;
  showWebPageDataSource = false;

  dataSourceTypeValue: string = "";
  indexTypeDropDownValue: string = "";

  showIndexTypeDropDown: boolean = false;
  compIndexType: string = "";

  itemsToDisplay: number = 5;

  loadMoreItems() {
    this.itemsToDisplay += 5;
  }

  ngOnInit() {
    this.kbService.getMyKBlist().subscribe(
      (response) => {
        this.kbList = response;
        console.log(this.kbList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  constructor(
    private kbService: KbService,
    private router: Router,
    public messageService: MessageService
  ) {}

  addIndex(idx: any) {
    const kbId = this.kbList[idx].id;
    const kbName = this.kbList[idx].customName;
    const addIndexActionStr = true;
    this.router.navigate(["/dashboard/knowledgeBase/create"], {
      queryParams: {
        kbId: kbId,
        kbName: kbName,
        addIndexAction: addIndexActionStr,
      },
    });
  }

  updateIndex(kbSelectedIndex: any, idx: any) {
    const kbName = this.kbList[kbSelectedIndex].customName;
    const updateIndexAction = true;
    this.router.navigate(["/dashboard/knowledgeBase/create"], {
      queryParams: {
        indexId: idx,
        kbName: kbName,
        updateIndexAction: updateIndexAction,
      },
    });
  }

  viewKnowledge(i: any) {
    this.showIndexTypeDropDown = false;
    this.selectedKbIndex = i;
    this.selectedKbIndexForVisible = i;
  }

  viewKnowledgeIsVisible(indexId: any) {
    if (this.selectedKbIndexForVisible == indexId) {
      return true;
    }
    return false;
  }

  addKnowledgeBase() {
    this.router.navigate(["/dashboard/knowledgeBase/create"]);
  }

  convert() {
    if (!this.showIndexTypeDropDown) {
      this.showIndexTypeDropDown = true;
      return;
    }

    const kbId = this.kbList[this.selectedKbIndex].id;
    this.kbService.convertToCompositeIndex(kbId, this.compIndexType).subscribe(
      (response) => {
        console.log("API response:", response);
        this.isSuccess = true;
        this.messageService.showSuccess(response.message);
        // this.serverMessage = response.message;
        // handle the response from the API endpoint
      },
      (error) => {
        console.error("API error:", error);
        this.isError = true;
        this.messageService.showError(
          "Oops!! something went wrong. Please try after sometime."
        );
        // this.serverMessage = 'Oops!! something went wrong. Please try after sometime.'
        // handle the error from the API endpoint
      }
    );
  }
}
