import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HeaderComponent } from './shared/header/header.component'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { AuthService } from './auth/auth.service'
import { AppConstants } from './app-constants'
import { DashboardComponent } from './dashboard/dashboard.component'
import { KbComponent } from './kb/kb.component'
import { KbListComponent } from './kb/kb-list/kb-list.component'
import { KbCreateComponent } from './kb/kb-create/kb-create.component'
import { KbService } from './kb/KbService'
import { QueryComponent } from './query/query.component'
import { QueryService } from './query/queryService'
import { ToolsComponent } from './tools/tools.component'
import { SettingsComponent } from './settings/settings.component'
import { PromptComponent } from './prompt/prompt.component'
import { PromptCreateComponent } from './prompt/prompt-create/prompt-create.component'
import { PromptListComponent } from './prompt/prompt-list/prompt-list.component'
import { PromptService } from './prompt/promptService'
import { AuthGuard } from './auth/auth-guard.service'
import { ToolListComponent } from './tools/tool-list/tool-list.component'
import { ToolService } from './tools/tool.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToolEditComponent } from './tools/tool-edit/tool-edit.component'
import { ToolTryComponent } from './tools/tool-try/tool-try.component'
import { AppService } from './app.service'
import { ApiKeyListComponent } from './settings/api-key-list/api-key-list.component'
import { SettingsService } from './settings/settings.service'
import { TokenUsageComponent } from './token-usage/token-usage.component'
import { TokenUsageService } from './token-usage/token-usage.service'
import { FooterComponent } from './shared/footer/footer.component'
import { CustomCommonModule } from './common.module'
import { ToastrModule } from 'ngx-toastr'
import { provideAnimations } from '@angular/platform-browser/animations'

import { provideToastr } from 'ngx-toastr'
import { CreateBotsComponent } from './tools/create-bots/create-bots.component'
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { SubscriptionComponent } from './subscription/subscription.component'
import { SubscriptionService } from './subscription/subscription.service'
import { PricingComponent } from './pricing/pricing.component'
import { PaymentComponent } from './payment/payment.component'
import { SupportComponent } from './support/support.component'
import { SupportService } from './support/support.service'
import { WordpressPluginInfoComponent } from './wordpress-plugin-info/wordpress-plugin-info.component'
import { ChatHistoryViewComponent } from './tools/chat-history-view/chat-history-view.component';
import { UserguideComponent } from './userguide/userguide.component'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    KbComponent,
    KbListComponent,
    KbCreateComponent,
    QueryComponent,
    ToolsComponent,
    SettingsComponent,
    PromptComponent,
    PromptCreateComponent,
    PromptListComponent,
    ToolListComponent,
    ToolEditComponent,
    ToolTryComponent,
    ApiKeyListComponent,
    TokenUsageComponent,
    FooterComponent,
    CreateBotsComponent,
    SubscriptionComponent,
    PricingComponent,
    PaymentComponent,
    SupportComponent,
    WordpressPluginInfoComponent,
    ChatHistoryViewComponent,
    UserguideComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CustomCommonModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true,
    }),
  ],
  providers: [
    AuthService,
    AuthGuard,
    HttpClient,
    AppConstants,
    KbService,
    QueryService,
    PromptService,
    ToolService,
    AppService,
    SettingsService,
    TokenUsageService,
    provideAnimations(), // required animations providers
    provideToastr(),
    SubscriptionService,
    SupportService, // Toastr providers
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
