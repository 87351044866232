<div class="container">

  <div>
    <button type="submit" class="btn buttton my-4" routerLink="/dashboard/knowledgeBase">Back
    </button>

    <h1 *ngIf="addIndexAction">
      Add Document to the knowledgebase: <span style="font-style: italic">{{passedInKbName}}</span>
    </h1>

    <h1 *ngIf="updateIndexAction">
      Update existing index for the knowledgebase: <span style="font-style: italic">{{passedInKbName}}</span>
    </h1>

    <h1 *ngIf="!addIndexAction && !updateIndexAction">
      Create New KnowledgeBase
    </h1>


  </div>

  <form [formGroup]="kbCreateForm">

    <div *ngIf="showCreateKBSection">
      <div class="row mt-3">
        <div class="col-md-12">

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="kbName">Knowledge Base Name</mat-label>
            <input matInput type="text" [disabled]="kbRecordCreationSuccess" id="kbName" name="kbName" ngModel required
              formControlName="kbName" placeholder="Enter knowledgebase name" required="true" />
          </mat-form-field>
          <!-- <label for="kbName">Knowledge Base Name</label>
          <input type="text" [disabled]="kbRecordCreationSuccess" class="form-control" id="kbName"
            formControlName="kbName" placeholder="Enter knowledgebase name" required style="width: 50%;"> -->
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="kbAdditionalIndo">Description</mat-label>
            <input matInput type="text" [disabled]="kbRecordCreationSuccess" id="kbAdditionalIndo"
              name="kbAdditionalIndo" ngModel required formControlName="kbAdditionalInfo"
              placeholder="Enter any description" />
          </mat-form-field>

          <!-- <label for="kbAdditionalIndo">Description</label>
          <input type="text" class="form-control" id="kbAdditionalIndo" formControlName="kbAdditionalInfo"
            placeholder="Enter any description" style="width: 50%;"> -->
        </div>
      </div>
      <div class="row" style="margin-top: 1em;">
        <div class="col-md-12">
          <button type="submit" class="btn buttton" (click)="createKbRecord()">Next
            <span *ngIf="isLoading1" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
          
          <span *ngIf="serverMessageKbRecordCreation != '' && !kbRecordCreationSuccess" class="alert alert-danger"
            style="margin-left: 1em;font-weight: bold">{{ serverMessageKbRecordCreation }}</span>
          <span *ngIf="serverMessageKbRecordCreation !='' && kbRecordCreationSuccess" class="alert alert-success"
            style="margin-left: 1em;font-weight: bold">{{ serverMessageKbRecordCreation }}</span>

          <!-- <button type="button" class="btn btn-secondary btn-sm" (click)="createKbRecord()">Next
            <span *ngIf="isLoading1" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span></button>
          <span *ngIf="serverMessageKbRecordCreation != '' && !kbRecordCreationSuccess" class="alert alert-danger"
            style="margin-left: 1em;font-weight: bold">{{ serverMessageKbRecordCreation }}</span>
          <span *ngIf="serverMessageKbRecordCreation !='' && kbRecordCreationSuccess" class="alert alert-success"
            style="margin-left: 1em;font-weight: bold">{{ serverMessageKbRecordCreation }}</span> -->
        </div>
      </div>

    </div>

    <div *ngIf="showAddSourceSection">
      <div class="row">
        <div class="col-md-12">



          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label for="dataSourceDropDown">Document Type</mat-label>
            <mat-select name="srcType" id="dataSourceDropDown" [(ngModel)]="srcType" formControlName="srcType"
              (selectionChange)="onDataSourceOptionChange($event)">
              <mat-option value="File">Upload Document (.txt/.pdf/.doc)</mat-option>
              <mat-option value="WebPages">Web Page</mat-option>
              <mat-option value="Website" *ngIf="!(addIndexAction || updateIndexAction)">Website</mat-option>
              <mat-option value="GoogleDocs" disabled>Google Docs (Coming Soon)</mat-option>
              <mat-option value="S3" disabled>S3 (Coming soon)</mat-option>
            </mat-select>
          </mat-form-field>


          <!-- <label for="dataSourceDropDown">Document Type</label>
          <select class="form-select" id="dataSourceDropDown" (change)="onDataSourceOptionChange($event)"
            style="width: 40%;" formControlName="srcType" [disabled]="kbRecordCreationSuccess">
            <option selected>Select an option...</option>
            <option value="File">Load a local document</option>
            <option value="WebPages">Web Page</option>
            <option value="Website" *ngIf="!(addIndexAction || updateIndexAction)">Website</option>
            <option value="GoogleDocs" disabled>Google Docs(Coming Soon)</option>
            <option value="S3" disabled>S3 (Coming soon)</option>
          </select> -->
        </div>
      </div>
      <div class="row mt-3" *ngIf="fileUploadDataSourceSelected">
        <div class="col-md-12 mt-3">

          <!-- <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="localFile">Upload Docs</mat-label>
            <input mat-icon-button type="file" id="localFile" name="localFile" matPrefix (click)="onFileSelected($event)" />
            <mat-icon matSuffix>folder</mat-icon>
          </mat-form-field> -->

          <label for="localFile">Upload Docs</label>
          <input type="file" class="form-control" id="localFile" name="localFile" (change)="onFileSelected($event)">
        </div>
      </div>
      <div class="row mt-3" *ngIf="googleDocsDataSourceSelected">
        <div class="col-md-12">

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="textfield">List of google docs</mat-label>
            <textarea matInput id="textarea" name="textarea" ngModel required cdkTextareaAutosize
              formControlName="srcDataGoogleDocs" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="5"></textarea>
          </mat-form-field>

          <!-- <label for="textfield">List of google docs</label>
          <textarea id="textarea" class="form-control" rows="3" formControlName="srcDataGoogleDocs"></textarea> -->
        </div>
      </div>
      <div class="row mt-3" *ngIf="webPageDataSourceSelected">
        <div class="col-md-12">

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="textfield">List of web pages</mat-label>
            <input matInput type="text" id="textfield" name="textfield" required
              placeholder="single webpage ex: https://en.wikipedia.org/wiki/Metabolite etc."
              formControlName="srcDataWebPages" />
          </mat-form-field>

          <!-- <label for="textfield">List of web pages</label>
          <input type="text" class="form-control" id="textfield"
            placeholder="single webpage ex: https://en.wikipedia.org/wiki/Metabolite etc."
            formControlName="srcDataWebPages"> -->
        </div>

      </div>
      <div class="row mt-3" *ngIf="webSiteDataSourceSelected">
        <div class="col-md-12">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="websiteName">Website Name</mat-label>
            <input matInput type="text" id="websiteName" name="websiteName" required
              placeholder="example : www.anywebsite.com" formControlName="srcDataWebsite" />
          </mat-form-field>

          <!-- <label for="websiteName">Website Name</label>
          <input type="text" class="form-control" id="websiteName" placeholder="example : www.anywebsite.com"
            formControlName="srcDataWebsite"> -->
          <div style="font-size: x-small"> We will crawl over the entire website and try to add webpages into KB.
            Currently only 20 pages are supported</div>
        </div>

      </div>

      <div class="row" *ngIf="!updateIndexAction">
        <div class="col-md-12" style="margin-top: 1em;">
          <div *ngIf="!webSiteDataSourceSelected">

            <button type="submit" class="btn buttton" (click)="addSrcToKb()">Next
              <span *ngIf="isLoading2" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>

            <!-- <button type="button" class="btn btn-secondary btn-sm" (click)="addSrcToKb()">Next
              <span *ngIf="isLoading2" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span></button> -->
          </div>
          <div *ngIf="webSiteDataSourceSelected">
            <button type="submit" class="btn buttton" (click)="addSrcToKb()">Schedule Knowledgebase
              Creation
            </button>

            <!-- <button type="button" class="btn btn-secondary btn-sm" (click)="addSrcToKb()">Schedule Knowledgebase
              Creation</button> -->
          </div>

          <div>


          </div>

        </div>
      </div>
      <div class="row" style="margin-top:0.5em">
        <span *ngIf="srcAddServerMessage != '' && !kbSourceAddSuccess" class="alert alert-danger"
          style="margin-left: 1em;font-weight: bold ;font-size: small ; display: inline-block;">{{ srcAddServerMessage
          }}</span>
        <span *ngIf="srcAddServerMessage != '' && kbSourceAddSuccess" class="alert alert-success"
          style="margin-left: 1em;font-weight: bold">{{ srcAddServerMessage }}</span>

      </div>

    </div>

    <div *ngIf="!webSiteDataSourceSelected">
      <!-- If website is selected then index type selection is not allowed -->


      <div *ngIf="showIndexSelectionSection">

        <div class="row mt-3" *ngIf="showIndexTypeField">
          <div class="col-md-12">

            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label for="indexTypeDropDown">Index Type</mat-label>
              <mat-select name="indexTypeDropDown" id="indexTypeDropDown" formControlName="indexType"
                [disabled]="!kbRecordCreationSuccess && !kbSourceAddSuccess">
                <mat-option selected>Select an option...</mat-option>
                <mat-option value="vector">Vector Index</mat-option>
                <mat-option value="list">List Index</mat-option>
                <mat-option value="tree">Tree Index</mat-option>
                <mat-option value="keyword">Keyword Index</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <label for="indexTypeDropDown">Index Type</label>
            <select class="form-select" id="indexTypeDropDown" formControlName="indexType" style="width: 40%;"
              [disabled]="!kbRecordCreationSuccess && !kbSourceAddSuccess">
              <option selected>Select an option...</option>
              <option value="vector">Vector Index</option>
              <option value="list">List Index</option>
              <option value="tree">Tree Index</option>
              <option value="keyword">Keyword Index</option>
            </select> -->
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <button type="submit" class="btn buttton" (click)="createIndex()"
            [disabled]="!kbRecordCreationSuccess || !kbSourceAddSuccess"
            *ngIf="!addIndexAction && !updateIndexAction">Complete KnowledgeBase Creation
            <span *ngIf="isLoading3" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>

          <!-- <button type="button" class="btn btn-primary" (click)=createIndex()
            [disabled]="!kbRecordCreationSuccess || !kbSourceAddSuccess"
            *ngIf="!addIndexAction && !updateIndexAction">Complete KnowledgeBase Creation
            <span *ngIf="isLoading3" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span></button> -->


          <button type="submit" class="btn buttton" (click)="createIndex()" disabled]="!kbSourceAddSuccess"
            *ngIf="addIndexAction && !updateIndexAction">Add Document To the Knowledgebase
            <span *ngIf="isLoading3" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>

          <!-- <button type="button" class="btn btn-primary" (click)=createIndex() [disabled]="!kbSourceAddSuccess"
            *ngIf="addIndexAction && !updateIndexAction">Add Document To the Knowledgebase
            <span *ngIf="isLoading3" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span></button> -->


          <button type="submit" class="btn buttton" (click)="addSrcToExistingIndex()"
            *ngIf="!addIndexAction && updateIndexAction">Update Document
            <span *ngIf="isLoading3" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>

          <!-- <button type="button" class="btn btn-primary" (click)=addSrcToExistingIndex()
            *ngIf="!addIndexAction && updateIndexAction">Update Document
            <span *ngIf="isLoading3" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span></button> -->
          <span *ngIf="indexcreationServerMessage != '' && !kbIndexCreationSuccess" class="alert alert-danger"
            style="margin-left: 1em;font-weight: bold">{{ indexcreationServerMessage }}</span>
          <span *ngIf="indexcreationServerMessage != '' && kbIndexCreationSuccess" class="alert alert-success"
            style="margin-left: 1em;font-weight: bold">{{ indexcreationServerMessage }}</span>

          <span *ngIf="srcAddServerMessage != '' && !kbSourceAddSuccess && updateIndexAction" class="alert alert-danger"
            style="margin-left: 1em;font-weight: bold">{{ srcAddServerMessage }}</span>
          <span *ngIf="srcAddServerMessage != '' && kbSourceAddSuccess && updateIndexAction" class="alert alert-success"
            style="margin-left: 1em;font-weight: bold">{{ srcAddServerMessage }}</span>


        </div>
      </div>
    </div>

  </form>
</div>