<div class="row">
  <h1>Token Usage</h1>

  <div *ngIf="tokenUsageList == null || tokenUsageList.length === 0">
    No Token Usage exists.
  </div>
  <div
    class="table-responsive"
    *ngIf="tokenUsageList != null && tokenUsageList.length > 0"
  >
    <table class="table table-borderless" *ngIf="tokenUsageList.length !== 0">
      <thead>
        <tr>
          <th>Usage Time</th>
          <th>Embedding Token</th>
          <th>Prompt Token</th>
          <th>Completion Token</th>
          <th>Total Token</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="table-row"
          *ngFor="let tokenUsage of tokenUsageList; let i = index"
        >
          <td>{{ tokenUsage.usageTimestamp | date : "M/d/yyyy h:mm a" }}</td>
          <td>{{ tokenUsage.embeddingToken }}</td>
          <td>{{ tokenUsage.promptToken }}</td>

          <td>{{ tokenUsage.completionToken }}</td>
          <td>{{ tokenUsage.totalToken }}</td>
        </tr>
      </tbody>
    </table>
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item">
          <a
            class="page-link"
            (click)="previous()"
            aria-label="Previous"
            style="cursor: pointer"
          >
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item" *ngFor="let i of getCount(pageCount)">
          <a
            class="page-link"
            (click)="setCurrentPage(i)"
            style="cursor: pointer"
            >{{ i + 1 }}</a
          >
        </li>
        <li class="page-item">
          <a
            class="page-link"
            (click)="next()"
            aria-label="Next"
            style="cursor: pointer"
          >
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
