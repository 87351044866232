import { Component, Input } from "@angular/core";
import { ColorInputComponent } from "../../../core/color-input/color-input.component";
import { FileInputComponent } from "../../../core/file-input/file-input.component";
import { TextWithTipComponent } from "../../../core/text-with-tip/text-with-tip.component";
import { DisplaySettingsModel } from "../../../display-tab/display-settings.model";

@Component({
  selector: "app-response-messages",
  standalone: true,
  imports: [ColorInputComponent, FileInputComponent, TextWithTipComponent],
  templateUrl: "./response-messages.component.html",
  styleUrls: ["./response-messages.component.css"],
})
export class ResponseMessagesComponent {
  @Input()
  toolSettingsModel!: DisplaySettingsModel;
  @Input()
  toolTips: { fieldHelpKey: string; fieldHelpValue: string }[] = [];
  @Input() toolId: string = "";


  getTooltip(str: string): string | undefined {
    return this.toolTips.find((t) => t.fieldHelpKey === str)?.fieldHelpValue;
  }
}
