<div class="container">
  <div class="sidebar">
    <button
      class="tab-button"
      [class.active]="activeTab === 0"
      (click)="changeTab(0)"
    >
      Overview
    </button>
    <button
      class="tab-button"
      [class.active]="activeTab === 1"
      (click)="changeTab(1)"
    >
      Bot Details
    </button>
    <button
      class="tab-button"
      [class.active]="activeTab === 2"
      (click)="changeTab(2)"
    >
      Document Sources
    </button>

    <button
      class="tab-button"
      [class.active]="activeTab === 3"
      (click)="changeTab(3)"
    >
      Display Settings
    </button>
    <button
      class="tab-button"
      [class.active]="activeTab === 4"
      (click)="changeTab(4)"
    >
      Machine Learning Configuration
    </button>
    <button
      class="tab-button"
      [class.active]="activeTab === 5"
      (click)="changeTab(5)"
    >
      Deployment
    </button>
  </div>
  <div class="content">
    <overview-tab *ngIf="activeTab === 0"></overview-tab>
    <bot-details-tab *ngIf="activeTab === 1"></bot-details-tab>
    <upload-documents-tab *ngIf="activeTab === 2"></upload-documents-tab>
    <display-tab *ngIf="activeTab === 3"></display-tab>
    <ml-config-tab *ngIf="activeTab === 4"></ml-config-tab>
    <deployment-tab *ngIf="activeTab === 5"></deployment-tab>
  </div>
</div>
