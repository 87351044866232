import { Component, Input } from "@angular/core";
import { ColorInputComponent } from "../../../core/color-input/color-input.component";
import { DisplaySettingsModel } from "../../../display-tab/display-settings.model";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-button-settings",
  standalone: true,
  imports: [ColorInputComponent, FormsModule],
  templateUrl: "./button-settings.component.html",
  styleUrls: ["./button-settings.component.css"],
})
export class ButtonSettingsComponent {
  @Input()
  toolSettingsModel!: DisplaySettingsModel;
  @Input()
  toolTips: { fieldHelpKey: string; fieldHelpValue: string }[] = [];
  @Input() toolId: string = "";
}
