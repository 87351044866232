import { ToolConfigModel } from "./tool-config.model";
export class ToolModel {
  name: string = "";
  id: number = 0;
  description: string = "";
  toolType: string = "";
  //indexIds : [] = [];
  indexFileNames: [] = [];
  toolTypeName: string = "";
  defaultChatBotAnswer: string = "";
  kbId: string = "";
  sourceFileNames: [] = [];
  createdAt: string = "";
  webInterfaceKey: string = "";
  allowedOrigins: string = "";
  website: string = "";
  primaryLanguage: string = "";
  primaryTimezone: string = "";

  toolConfigDto: ToolConfigModel = new ToolConfigModel();

  //used for queryTool
  //indexFileNameForQueryTool : string = '';
}
