<div class="container" *ngIf="kbList == null || kbList.length == 0">

  <p style="font-weight: bold;"> No KnowledgeBase or Index to Query . Please create the knowledgebase first.</p>

</div>


<div class="d-flex row col-12" *ngIf="kbList != null && kbList.length > 0  ">
  <div class="container col-lg-8 col-12 mt-3">
    <div class="first">

      <div class="container chat-head">
        <div class="row">
          <div class="col-6 d-flex justify-content-start align-items-center">
            <h1>Bot</h1>
          </div>
        </div>
      </div>

      <div class="row row-margin">
        <div class="col">
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label for="kbDropDown">Knowledge Bases</mat-label>
            <mat-select name="selectedKbOption" id="kbDropDown" [(ngModel)]="selectedKbOption"
                (selectionChange)="onKbOptionChange()">
                <mat-option *ngFor="let kb of kbList" [value]="kb">{{ kb.customName }}</mat-option>
            </mat-select>
        </mat-form-field>


          <!-- <label for="kbDropDown">Knowledge Bases</label>
          <select id="kbDropDown" class="form-select" [(ngModel)]="selectedKbOption" (change)="onKbOptionChange()">
            <option *ngFor="let kb of kbList" [ngValue]="kb">{{ kb.customName }}</option>
          </select> -->
        </div>
        <div class="col">

          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label for="indexDropDown">Indexes</mat-label>
            <mat-select name="selectedIndex" id="indexDropDown" [(ngModel)]="selectedIndex">
                <mat-option *ngFor="let index of selectedKbOption.indexDtoLiteList" [value]="index">{{ index.indexFileName }}</mat-option>
            </mat-select>
        </mat-form-field>

          <!-- <label for="indexDropDown">Indexes</label>
          <select id="indexDropDown" class="form-select" [(ngModel)]="selectedIndex">
            <option *ngFor="let index of selectedKbOption.indexDtoLiteList" [ngValue]="index">{{ index.indexFileName }}
            </option>
          </select> -->
        </div>
      </div>



      <div class="adv">
        <button type="button" class="btn btn-link"
          (click)="clearSelectedPrompts() && (showAdvanced = !showAdvanced )  ">{{
          showAdvanced ? 'Hide Advanced Options' : 'Advanced Options' }}</button>
        <!--    <a href="#" class="pull-right" (click)="showAdvanced = !showAdvanced">{{ showAdvanced ? 'Hide' : 'Advanced' }}</a>-->
      </div>

      <div id="collapsePanel" [hidden]="!showAdvanced">
        <div class="panel-body">
          <div class="row row-margin">
            <div class="col">

              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label for="promptDropDown">My Custom Prompts</mat-label>
                <mat-select name="selectedPrompts" id="promptDropDown" [(ngModel)]="selectedPrompts" multiple >
                    <mat-option *ngFor="let prompt of promptList">{{prompt.name}}</mat-option>
                </mat-select>
            </mat-form-field>

              <!-- <label for="promptDropDown">My Custom Prompts</label>
              <select id="promptDropDown" class="form-select" [(ngModel)]="selectedPrompts" multiple>
                <option *ngFor="let prompt of promptList" [ngValue]="prompt">{{prompt.name}}</option>
              </select> -->
            </div>
            <div class="col">
            </div>
          </div>
        </div>
      </div>

      <div class="row row-margin">
        <div class="col">

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="textfield">Query</mat-label>
            <input matInput type="text" id="textfield" name="textfield" ngModel required
                [(ngModel)]="queryText" required #textfield="ngModel" />
            
        </mat-form-field>


          <!-- <label for="textfield">Query</label>
          <input type="text" id="textfield" class="form-control" [(ngModel)]="queryText"> -->
        </div>
      </div>

      <div class="row row-margin">
        <div class="col">


          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="textarea">Response</mat-label>
            <textarea matInput id="textarea" name="textarea" ngModel required
            [(ngModel)]="queryResponse" required #descriptipn="ngModel" cdkTextareaAutosize
                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="10"
                cdkAutosizeMaxRows="15"></textarea>
        </mat-form-field>

          <!-- <label for="textarea">Response</label>
          <textarea id="textarea" class="form-control" rows="10" [(ngModel)]="queryResponse"></textarea> -->
        </div>
      </div>

      <div class="button-container text-left d-flex flex-row-reverse" style="margin-top: 1em">
        <button type="submit" class="btn sub" (click)="query()">Submit</button>
      </div>

      <!-- <div class="row row-margin">
      <div class="col">
        <button class="btn btn-primary" (click)="query()">Submit</button>
      </div>
    </div> -->





    </div>
  </div>
  <div class="container col-lg-4 col-12 mt-3">

  </div>
</div>




<div class="container" *ngIf="kbList != null && kbList.length > 0  ">

</div>