<h1>List of Api Keys</h1>

<div *ngIf="apiKeyList == null ||  apiKeyList.length === 0">
    You have not added any keys yet.
</div>
<div class="table-responsive" *ngIf="apiKeyList != null&& apiKeyList.length > 0">
    <table class="table table-borderless" *ngIf="apiKeyList.length !== 0">
        <thead>
            <tr>
                <th>Provider</th>
                <th>Key Type</th>
                <th>Key</th>
                <th>Added on</th>
                <th>Action</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let apiKey of apiKeyList;let i = index">
                <td>{{ apiKey.provider }}</td>
                <td>{{ apiKey.keyType }}</td>
                <td>{{ apiKey.displayText }}</td>
                <td> {{ apiKey.createdAt | date:'M/d/yyyy h:mm a' }}</td>
                <td>

                    <a href="javascript:void(0);" (click)="deleteKey(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24"
                            height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#000" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M4 7l16 0"></path>
                            <path d="M10 11l0 6"></path>
                            <path d="M14 11l0 6"></path>
                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                        </svg>
                    </a>

                    <!-- <div class="btn-group btn-group-sm" role="group" aria-label="Button group">
                    <button type="button" class="btn btn-secondary btn-sm" (click) = "deleteKey(i)">Delete Key</button>
                </div> -->
                </td>

            </tr>
        </tbody>
    </table>
</div>