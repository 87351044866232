
<div style="margin-top: 1em">
  <div *ngIf="activeSubscriptionStatus" style="margin-top: 1em">
    <span>There is an <b>active</b> subscription.</span>
    <div class="button-container" style="margin-top: 1em">
      <button class="btn btn-primary" (click)="manageSubscriptions()">
        Manage Subscriptions
      </button>
    </div>
  </div>
</div>
<div *ngIf="!activeSubscriptionStatus" style="margin-top: 1em">
  <h4>There is no active subscription. Click here to select a plan to
  <a
    href="javascript:void(0);"
    routerLink="/dashboard/pricing"
    routerLinkActive="active"
    >subscribe</a
  >.
    </h4>
</div>
