import { Component, Input } from '@angular/core'
import { DisplaySettingsModel } from '../../display-tab/display-settings.model'

@Component({
  selector: 'launch-screen-tab',
  standalone: true,
  imports: [],
  templateUrl: './launch-screen.component.html',
  styleUrls: ['./launch-screen.component.css'],
})
export class LaunchScreenComponent {
  @Input()
  toolSettingsModel!: DisplaySettingsModel
  @Input()
  toolTips: { fieldHelpKey: string; fieldHelpValue: string }[] = []
}
