import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Clipboard } from '@angular/cdk/clipboard'
interface DeploymentItem {
  name: string;
  description: string;
  deployed: boolean;
  logoUrl: string;
  added: boolean;
  accessableToMe: boolean;
  code:string;
  show:boolean;
}

@Component({
  selector: "d-channel",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./d-channel.component.html",
  styleUrls: ["./d-channel.component.css"],
})
export class DChannelComponent {
  @Input() item!: DeploymentItem;
  //show = this.item!.show
  constructor(private clipboard: Clipboard) {
  }

  copyToClipboard(){
    this.clipboard.copy(this.item.code)
  }
}
