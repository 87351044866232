import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { MessageSettingsComponent } from '../settingComponents/manage-settings/message-settings.component'
import { VoiceSettingsComponent } from '../settingComponents/voice-settings/voice-settings.component'
import { ChatMenuSettingsComponent } from '../settingComponents/chat-menu/chat-menu-settings.component'
import { CommonModule } from '@angular/common'
import { DisplaySettingsModel } from '../../display-tab/display-settings.model'

@Component({
  selector: 'settings-tab',
  standalone: true,
  imports: [
    CommonModule,
    MessageSettingsComponent,
    VoiceSettingsComponent,
    ChatMenuSettingsComponent,
  ],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent {
  @Input()
  toolSettingsModel!: DisplaySettingsModel
  @Input()
  toolTips: { fieldHelpKey: string; fieldHelpValue: string }[] = []

  activeItem: number | null = null

  setActiveItem(index: number) {
    if (this.activeItem === index) {
      this.activeItem = null
    } else {
      this.activeItem = index
    }
  }
}
