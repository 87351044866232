import { Component } from '@angular/core';

@Component({
  selector: 'app-wordpress-plugin-info',
  templateUrl: './wordpress-plugin-info.component.html',
  styleUrls: ['./wordpress-plugin-info.component.css']
})
export class WordpressPluginInfoComponent {

}
