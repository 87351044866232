import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { BotDetailsTabComponent } from "./components/bot-details-tab/bot-details-tab.component";
import { DeploymentTabComponent } from "./components/deployment-tab/deployment-tab.component";
import { DisplayTabComponent } from "./components/display-tab/display-tab.component";
import { OverviewTabComponent } from "./components/overview-tab/overview-tab.component";
import { UploadDocumentsComponent } from "./components/upload-documents/upload-documents.component";
import { MlConfigComponent } from "./components/ml-config/ml-config.component";
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: "configure",
  standalone: true,
  imports: [
    CommonModule,
    BotDetailsTabComponent,
    DeploymentTabComponent,
    DisplayTabComponent,
    OverviewTabComponent,
    UploadDocumentsComponent,
    MlConfigComponent,
  ],
  templateUrl: "./configure.component.html",
  styleUrls: ["./configure.component.css"],
})
export class ToolConfigureComponent implements OnInit {
  activeTab = 0;
  botName = "Mugisha";
  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(val => {
      console.log("==============>",val['activeTab'])
      if(val['activeTab']) {
        this.activeTab = +val['activeTab'];
      }
    })
  }


  changeTab(number: number) {
    this.activeTab = number;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeTab: this.activeTab+'',
      },
      queryParamsHandling: "merge",
    });
  }
}
