import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ToolModel } from '../../../tool.model'
import { KbService } from '../../../../kb/KbService'
import { ToolService } from '../../../tool.service'
import { MessageService } from 'src/app/shared/services/message.service'
import { ActivatedRoute } from '@angular/router'
import { PromptService } from '../../../../prompt/promptService'
import { Router } from '@angular/router'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { Prompt, PromptMapWrapper } from 'src/app/prompt/prompt.model'
import { CommonModule } from '@angular/common'
import { Subscription } from 'rxjs'

@Component({
  selector: 'ml-config-tab',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    CommonModule,
  ],
  templateUrl: './ml-config.component.html',
  styleUrls: ['./ml-config.component.css'],
})
export class MlConfigComponent implements OnInit, OnDestroy {
  isLoading: boolean = false
  isError: boolean = false
  isSuccess: boolean = false
  toolId: any
  toolName: any
  toolModel = new ToolModel()
  contextPromptList: any = []
  condenseQuestionPromptList: any = []
  condensePromptList: any = []
  systemPromptList: any = []
  promptMapSubscription: Subscription | undefined
  toolGetSubscription: Subscription | undefined
  toolUpdateSubscription: Subscription | undefined
  promptMapWrapper: PromptMapWrapper | undefined
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private kbService: KbService,
    private toolsService: ToolService,
    private promptService: PromptService,
    public messageService: MessageService
  ) {}

  updateTool() {
    console.log(this.toolModel)
    this.isLoading = true
    this.toolsService.updateTool(this.toolModel)
  }

  ngOnInit() {
    this.toolId = this.route.snapshot.queryParamMap.get('toolId')
    this.toolName = this.route.snapshot.queryParamMap.get('toolName')
    console.log('this.toolId ' + this.toolId)
    this.toolsService.getTool(this.toolId)

    this.toolGetSubscription = this.toolsService.getToolSubject.subscribe(
      (response: ToolModel) => {
        this.toolModel = response
        console.log(' returned toolModel = ' + JSON.stringify(this.toolModel))
        this.isLoading = false
        //this.updateSelectedPrompts();
      },
      (error: any) => {
        console.log(error)
        this.isLoading = false
      }
    )
    this.toolUpdateSubscription = this.toolsService.updateToolSubject.subscribe(
      (response: any) => {
        this.isSuccess = true
        this.toolsService.getTool(this.toolId)
        this.messageService.showSuccess(response.message)
        this.isLoading = false
        // this.showServerMessage('updateToolMessage',response.message)
      },
      (error: any) => {
        this.isError = true
        this.messageService.showError(error)
        this.isLoading = false
        // this.showServerMessage('updateToolMessage',error)
      }
    )
    this.promptMapSubscription =
      this.promptService.getPromptMapSubject.subscribe(
        (response: PromptMapWrapper) => {
          this.promptMapWrapper = response
          console.log('Prompt Map ' + JSON.stringify(this.promptMapWrapper))
          console.log(typeof this.promptMapWrapper)
          console.log(typeof this.promptMapWrapper?.promptMap)
          console.log(
            'this.promptMapWrapper.promptMap ' +
              JSON.stringify(this.promptMapWrapper?.promptMap)
          )
          if (this.promptMapWrapper) {
            this.contextPromptList =
              this.promptMapWrapper.promptMap.get('ContextPrompt')
            this.condensePromptList =
              this.promptMapWrapper.promptMap.get('CondensePrompt')
            this.condenseQuestionPromptList =
              this.promptMapWrapper.promptMap.get('CondenseQuestionPrompt')
            this.systemPromptList =
              this.promptMapWrapper.promptMap.get('SystemPrompt')
            console.log(
              'this.systemPromptList ' + JSON.stringify(this.systemPromptList)
            )
          }
        },
        (error) => {
          console.log('Error occured while gettting prompt map ' + error)
        }
      )
    this.toolsService.getToolDisplaySettings(this.toolId)
    this.promptService.getPromptMap()
  }

  ngOnDestroy(): void {
    this.promptMapSubscription?.unsubscribe();
    this.toolGetSubscription?.unsubscribe();
    this.toolUpdateSubscription?.unsubscribe();
  }

  comparePrompts(p1: Prompt, p2: Prompt) {
    return p1.id == p2.id
  }
}
