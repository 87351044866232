import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { SettingsService } from "./settings.service";

import { ApikeyModel } from "./apikey.model";
import { NgForm } from "@angular/forms";
import { MessageService } from "../shared/services/message.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"],
})
export class SettingsComponent implements OnInit, OnDestroy {
  apiKey: any = "";
  add_api_key_subscription: Subscription | undefined;
  delete_api_key_subscription: Subscription | undefined;

  apiKeyModel = new ApikeyModel();

  message: string = "";
  status: boolean = false;
  constructor(
    private settingsService: SettingsService,
    public messageService: MessageService
  ) {}

  ngOnInit() {
    this.add_api_key_subscription =
      this.settingsService.addKeySubject.subscribe(
        (data) => {
          this.message = data.message;
          this.status = true;
          console.log(this.message, "gergergerge");
          if (this.message.includes("successfully")) {
            this.messageService.showSuccess(this.message);
          } else {
            this.messageService.showError(this.message);
          }
        },
        (error) => {
          this.status = false;
          this.messageService.showError(error);
        }
      );
  }

  addKey(form: NgForm) {
    this.settingsService.addKey(
      this.apiKeyModel.key,
      this.apiKeyModel.provider,
      this.apiKeyModel.keyType
    );
    this.settingsService.listKeys();
    form.resetForm();
  }

  showServerMessage(messageType: string, message: string, time: number = 5000) {
    setTimeout(() => {
      this.message = "";
    }, time); // close the alert after 5 seconds (5000 milliseconds)
  }

  ngOnDestroy() {
    this.add_api_key_subscription?.unsubscribe();
    this.delete_api_key_subscription?.unsubscribe();
  }
}
