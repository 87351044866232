<div class="container">
  <div class="avatar-section">
    <div class="avatar-label">
      <pi [text]="'Avatar'" [toolTip]="getTooltip('avataar')"></pi>
    </div>
    <customFileInput
      [fieldName]="'botAvatarImageUrl'"
      [(value)]="toolSettingsModel.botAvatarImageUrl"
      [tool]="toolId"
    ></customFileInput>
  </div>
  <div class="grid-container mt-3">
    <div class="grid-item">
      <p class="label">Text bubble color</p>
      <color-input
        [(value)]="toolSettingsModel.botResponseBubbleColor"
      ></color-input>
    </div>
    <div class="grid-item">
      <p class="label">Text color</p>
      <color-input
        [(value)]="toolSettingsModel.botResponseBubbleTextColor"
      ></color-input>
    </div>
  </div>
</div>
