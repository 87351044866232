import { Injectable } from "@angular/core";
import { AppConstants } from "../app-constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class SettingsService {
  addKeySubject = new Subject<any>();
  deleteKeySubject = new Subject<any>();
  listKeysSubject = new Subject<any>();

  constructor(
    private appConstants: AppConstants,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  addKey(key: string, provider: string, keyType: string) {
    console.log("inside add Key");
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getAddKeyUrl();

    var keyModel = { apiKey: key, provider: provider, keyType: keyType };

    console.log("url -->" + url);
    this.http.post(url, keyModel, { headers: headers }).subscribe(
      (response) => {
        this.addKeySubject.next(response);
      },
      (error) => {
        this.addKeySubject.next(error);
      }
    );
  }

  deleteKey(keyId: number) {
    console.log("inside deleteKey");

    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getDeleteKeyUrl(keyId);

    console.log("url -->" + url);
    this.http.post(url, "", { headers: headers }).subscribe(
      (response) => {
        this.addKeySubject.next(response);
      },
      (error) => {
        this.addKeySubject.next(error);
      }
    );
  }

  listKeys() {
    console.log("inside listKeys");

    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getListKeysUrl();

    console.log("url -->" + url);
    this.http.get(url, { headers: headers }).subscribe(
      (response) => {
        this.listKeysSubject.next(response);
      },
      (error) => {
        this.listKeysSubject.next(error);
      }
    );
  }
}
