import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from './auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let shouldproceed = false;
    if (!this.authService.isAuthenticated()) {
      shouldproceed =  false;
      this.router.navigate(['auth/signin']);
    } else {
        shouldproceed = true;
        // refresh the token as well so the timestamp gets updated
        this.authService.refreshAndStoreToken();
    }

    console.log('AuthGuard canActivate shouldproceed ' +  shouldproceed);
    return shouldproceed;
  }
}
