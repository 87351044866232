import {Injectable} from "@angular/core";
import {AppConstants} from "../app-constants";
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { Subject,Observable,map} from 'rxjs'
import {AuthService} from "../auth/auth.service";
import {SubscriptionModel} from "./subscription.model";
import {TransactionModel } from "./transaction.model";
declare const Stripe:any;
import {environment} from '../../environments/environment';




@Injectable()
export class SubscriptionService {

    addSubscriptionSubject = new Subject<any>();
    autoRenewSubject = new Subject<any>();
    listSubscriptonsSubject = new Subject<SubscriptionModel[]>();
    listTransactionsSubject = new Subject<TransactionModel[]>();
    checkActiveSubscriptionSubject = new Subject<boolean>();

    constructor(private appConstants: AppConstants, private authService: AuthService,
                private router: Router, private http: HttpClient) {
    }


    getSubscriptionList() {
        console.log('inside getSubscriptionList');

        var headers = new HttpHeaders();

        headers = headers.append('Authorization', this.authService.getAuthToken());

        const url = this.appConstants.getSubscriptionListUrl();

        console.log('url -->' + url);
        this.http.get<SubscriptionModel[]>(url, {headers: headers})
            .pipe(map(response => this.mapSubscriptionObjects(response)))
            .subscribe(
            (response )=>{

                this.listSubscriptonsSubject.next(response);

            },
            (error) => {
                this.listSubscriptonsSubject.next(error);
            }
        )





    }

    private mapSubscriptionObjects(response: any[]): SubscriptionModel[] {
        return response.map(item => ({
            subscriptionId: item.subscriptionId,
            startDate: item.startDate,
            endDate: item.endDate,
            status: item.status,
            autoRenew: item.autoRenew
        } as SubscriptionModel));
    }



    getTransactionList() {
        console.log('inside getTransactionList');

        var headers = new HttpHeaders();

        headers = headers.append('Authorization', this.authService.getAuthToken());

        const url = this.appConstants.getTransactionListUrl();

        console.log('url -->' + url);
        this.http.get<TransactionModel[]>(url, {headers: headers})
            .pipe(map(response => this.mapTransactionObjects(response)))
            .subscribe(
                (response )=>{

                    this.listTransactionsSubject.next(response);

                },
                (error) => {
                    this.listTransactionsSubject.next(error);
                }
            )





    }

    private mapTransactionObjects(response: any[]): TransactionModel[] {
        return response.map(item => ({
            transactionId:item.transactionId,
            subscriptionId: item.subscriptionId,
            amount: item.amount,
            status: item.status,
            transactionDate: item.transactionDate,
        } as TransactionModel));
    }


    toggleAutoRenew(id:number, turnOn:boolean) {

        console.log('inside toggleAutoRenew');
        var headers = new HttpHeaders();

        headers = headers.append('Authorization', this.authService.getAuthToken());
        var url = '';
        if(turnOn) {
             url = this.appConstants.getEnableAutoRenewSubscriptionUrl(id);
        } else {
            url = this.appConstants.getDisableAutoRenewSubscriptionUrl(id);
        }



        console.log('url -->' + url);
        this.http.post(url,'',{headers: headers}).subscribe(
            (response )=>{

                this.autoRenewSubject.next(response);

            },
            (error) => {
                this.autoRenewSubject.next(error);
            }
        )
    }

    makePayment(){

        var headers = new HttpHeaders();
        headers = headers.append('Authorization', this.authService.getAuthToken());

        var url = this.appConstants.getCreateCheckoutSessionUrl();

        this.http.post<any>(url,'',{headers: headers}).pipe(map((response)=>{
            console.log(' response text'+response);
            return response;
        })).subscribe(
            (response )=>{
                console.log('Payment successful')
                this.redirectTocheckout(response.sessionId);
            },
            (error) => {
                console.log('Payment failed '+ JSON.stringify(error))
            }
        )

    }


    
    createSubscription(id:number){
        var headers = new HttpHeaders();
        headers = headers.append('Authorization', this.authService.getAuthToken());

        var url = this.appConstants.getCreateSubscriptionCheckoutUrl(id);

        this.http.post<any>(url,'',{headers: headers}).pipe(map((response)=>{
            console.log(' response text'+response);
            return response;
        })).subscribe(
            (response )=>{
                console.log('Subscription creation successful')
                this.redirectTocheckout(response.sessionId);
            },
            (error) => {
                console.log('Subscription creation failed '+ JSON.stringify(error))
            }
        )
    }

    redirectTocheckout(sessionId:string){

        var stripe = Stripe(environment.stripe_key);
        stripe.redirectToCheckout(
            {
                sessionId : sessionId
            }
        );

    }
    
    manageSubscriptions(){
        var headers = new HttpHeaders();
        headers = headers.append('Authorization', this.authService.getAuthToken());

        var url = this.appConstants.getCreateBillingPortalForCustomerUrl();

        this.http.post<any>(url,'',{headers: headers}).pipe(map((response)=>{
            console.log(' manageSubscriptions text'+response);
            return response;
        })).subscribe(
            (response )=>{
                console.log('manageSubscriptions successful')
                this.redirectToBillingPortal(response.url);
            },
            (error) => {
                console.log('manageSubscriptions failed '+ JSON.stringify(error))
            }
        )
        
    }

    redirectToBillingPortal(url:string) :void{

            window.open(url, "_blank");

    }

    checkActiveSubscription() {
        var headers = new HttpHeaders();
        headers = headers.append('Authorization', this.authService.getAuthToken());

        var url = this.appConstants.getIsThereAnActiveSubscription();
        var status = false;
        this.http.get<boolean>(url,{headers:headers}).subscribe(
            (data) =>{
                console.log('active subscription status returned '+data)
                this.checkActiveSubscriptionSubject.next(data);
           },
            (error)=>{
                console.log('error occured while checking active subscription '+error)
                this.checkActiveSubscriptionSubject.error(error);

                //status =  false;
            }
        )
    }






}
