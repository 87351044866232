import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "pi",
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatButtonModule],
  templateUrl: "./text-with-tip.component.html",
  styleUrls: ["./text-with-tip.component.css"],
})
export class TextWithTipComponent {
  @Input() text: string = "";
  @Input() toolTip: string | undefined = "";
}
