<div class="comp" (click)="$event.preventDefault();">
  <span>{{ text }}</span>
  <span *ngIf="toolTip !== undefined">
    <button
      class="info-button"
      matTooltip="{{ toolTip }}"
      [matTooltipPosition]="'after'"
    >
      <img src="../../../assets/icons/i.svg" alt="info-icon" />
    </button>
  </span>
</div>
