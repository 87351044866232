import { Component } from "@angular/core";

@Component({
  selector: "app-tools",
  templateUrl: "./tools.component.html",
  styleUrls: ["./tools.component.css"],
})
export class ToolsComponent {
  constructor() {}
}
