<form #myForm="ngForm">
    <div class="form-group" style="margin-top: 1em">
        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="promptName">Name:</mat-label>
            <input matInput type="text" id="promptName" name="promptName" ngModel [(ngModel)]="promptDto.name"
                #name="ngModel" />
            <!-- <mat-error *ngIf="!name.valid && name.touched">Name is required.</mat-error> -->
        </mat-form-field>

        <!-- <label for="promptName">Name:</label>
                    <input type="text" class="form-control" id="promptName" name="promptName" [(ngModel)]="promptDto.name"> -->
    </div>
    <div class="form-group" style="margin-top: 1em">
        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="promptTypeDropDown">Prompt Type</mat-label>
            <mat-select name="promptType" id="promptTypeDropDown" [(ngModel)]="selectedPromptType"
                (selectionChange)="onPromptTypeChange($event)">
                <mat-option *ngFor="let promptType of promptTypeList" [value]="promptType">{{ promptType.name
                    }}</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- <label for="promptTypeDropDown">Prompt Type</label>
                    <select name="promptType" id="promptTypeDropDown" class="form-select" [(ngModel)]="selectedPromptType" (ngModelChange)="onPromptTypeChange($event)">
                        <option *ngFor="let promptType of promptTypeList" [ngValue]="promptType">{{promptType.name}}</option>
                    </select> -->
    </div>
    <div class="form-group" style="margin-top: 1em">
        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="promptText">Prompt Text(This is a default prompt, feeel free to customize
                it):</mat-label>
            <textarea matInput id="promptText" name="promptText" ngModel [(ngModel)]="promptDto.text"
                #descriptipn="ngModel" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="10"
                cdkAutosizeMaxRows="15"></textarea>
            <!-- <mat-error *ngIf="!descriptipn.valid && descriptipn.touched">Description is
                            required.</mat-error> -->
        </mat-form-field>

        <!-- <label for="promptText">Prompt Text(This is a default prompt, feeel free to customize it):</label>
                    <textarea class="form-control" id="promptText" name="promptText" [(ngModel)]="promptDto.text" rows="10" ></textarea> -->
    </div>
    <div class="form-group d-flex flex-row-reverse" style="margin-top: 1em">
        <button type="submit" class="btn sub" [disabled]="!myForm.valid" (click)="submitForm()">Submit</button>

        <!-- <button type="submit" class="btn btn-primary" (click)="submitForm()">Submit</button> -->
    </div>
    <!-- <div *ngIf="isError" class="alert alert-danger">{{ serverMessage }}</div>
    <div *ngIf="isSuccess" class="alert-success">{{ serverMessage }}</div> -->
</form>