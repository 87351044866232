import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToolModel } from "../tool.model";
import { ToolDocModel } from "../tool-doc.model"
import { ToolService } from "../tool.service";
import { Subscription } from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { MessageService } from 'src/app/shared/services/message.service';
import {PromptService} from '../../prompt/promptService'
import {PromptMapWrapper,Prompt} from "../../prompt/prompt.model";
import { NgForm } from '@angular/forms';




@Component({
    selector: 'app-tool-edit',
    templateUrl: './tool-edit.component.html',
    styleUrls: ['./tool-edit.component.css']
})
export class ToolEditComponent implements OnInit, OnDestroy {

    showCreationDiv: boolean = false;
    isError: boolean = false;
    isSuccess: boolean = false;

    updateToolMessage: string = '';
    addDocumentMessage: string = '';

    showchatBotSpecificFields = false;
    showQAToolSpecificFields = false;

    toolGetSubscription: Subscription | undefined;
    toolUpdateSubscription: Subscription | undefined;
    addDocSubscription: Subscription | undefined;
    getToolDocListSubscription: Subscription | undefined;
    updateKeywordsSubecription: Subscription | undefined;
    promptMapSubscription: Subscription | undefined;

    toolId: any;
    toolName: any;

    toolModel = new ToolModel();

    isLoading: boolean = false;

    isLoadingUpdateKeywords:boolean = false;


    fileUploadDataSourceSelected = false;
    googleDocsDataSourceSelected = false;
    webPageDataSourceSelected = false;
    webSiteDataSourceSelected = false;

    file!: File;

    srcType: string = ''
    srcDataGoogleDocs: string = '';
    srcDataWebPages: string = '';

    tempSliderValue: number = 0;

    toolDocModelList: ToolDocModel[] = []

    promptMapWrapper:PromptMapWrapper | undefined

    contextPromptList :any = []
    condenseQuestionPromptList : any = []
    condensePromptList :any = []
    systemPromptList :any = []

    // selectedContextPromot: any = {}
    // selectedCondensePrompt: any = {}
    // selectedCondenseQuestionPrompt: any = {}
    // selectedSystemPrompt: any = {}



    constructor(private route: ActivatedRoute, private toolsService: ToolService,private promptService :PromptService,
        public messageService: MessageService) {
    }


    ngOnInit() {
        console.log('ToolListComponent created ');
        this.toolId = this.route.snapshot.queryParamMap.get('toolId');
        this.toolName = this.route.snapshot.queryParamMap.get('toolName');
        console.log('this.toolId ' + this.toolId);

        this.toolGetSubscription = this.toolsService.getToolSubject.subscribe(
            (response: ToolModel) => {
                this.toolModel = response;
                console.log(' returned toolModel = ' + JSON.stringify(this.toolModel))
                this.isLoading = false;
                //this.updateSelectedPrompts();
            },
            (error: any) => {
                console.log(error);
                this.isLoading = false;
            }
        )
        this.getToolDocListSubscription = this.toolsService.getToolDocListSubject.subscribe(
            (response: ToolDocModel[]) => {
                console.log('ToolDocModel list '+JSON.stringify(response))
                this.toolDocModelList = response;

            },
            (error: any) => {
                console.log(error);

            }
        )

        this.toolUpdateSubscription = this.toolsService.updateToolSubject.subscribe(
            (response: any) => {
                this.isSuccess = true;
                this.toolsService.getTool(this.toolId);
                this.messageService.showSuccess(
                    response.message
                );
                this.isLoading = false;
                // this.showServerMessage('updateToolMessage',response.message)
            },
            (error: any) => {
                this.isError = true;
                this.messageService.showError(
                    error
                );
                this.isLoading = false;
                // this.showServerMessage('updateToolMessage',error)
            }

        )

        this.addDocSubscription = this.toolsService.addDocSubject.subscribe(
            (response: any) => {
                this.isSuccess = true;
                this.toolsService.getTool(this.toolId);
                this.messageService.showSuccess(
                    response.message
                );
                this.isLoading = false;
                // this.showServerMessage('addDocMessage', response.message)
            },
            (error: any) => {
                this.isError = true;
                this.messageService.showError(
                    error
                );
                this.isLoading = false;
                // this.showServerMessage('addDocMessage', error)
            }

        )
    
        this.updateKeywordsSubecription = this.toolsService.updateKeywordsSubject.subscribe(
            ()=> {
                this.isLoadingUpdateKeywords = false;
            }
            ,() =>{
                this.isLoadingUpdateKeywords = false;
            }
        )

        this.promptMapSubscription = this.promptService.getPromptMapSubject.subscribe(
            (response: PromptMapWrapper) =>{
                this.promptMapWrapper = response;
                console.log('Prompt Map '+JSON.stringify(this.promptMapWrapper))
                console.log(typeof this.promptMapWrapper)
                console.log(typeof this.promptMapWrapper?.promptMap)
                console.log('this.promptMapWrapper.promptMap '+JSON.stringify(this.promptMapWrapper?.promptMap))
                if(this.promptMapWrapper) {
                    this.contextPromptList = this.promptMapWrapper.promptMap.get('ContextPrompt');
                    this.condensePromptList = this.promptMapWrapper.promptMap.get('CondensePrompt');
                    this.condenseQuestionPromptList = this.promptMapWrapper.promptMap.get('CondenseQuestionPrompt');
                    this.systemPromptList = this.promptMapWrapper.promptMap.get('SystemPrompt');
                    console.log('this.systemPromptList '+JSON.stringify(this.systemPromptList))
                }




            },
            (error) =>{
                console.log('Error occured while gettting prompt map '+error)
            }
        )

        this.promptService.getPromptMap();
        this.toolsService.getTool(this.toolId);
        this.toolsService.getDocListForTool(this.toolId)
    }


    updateTool() {
        console.log(this.toolModel)
        this.isLoading = true;
        this.toolsService.updateTool(this.toolModel);
    }


    // submitForm(form: NgForm) {
    //     console.log('inside Submit form');
    //     this.isError = false;
    //     this.isSuccess = false;
    //
    //     let selectedIndexIds: any = []
    //
    //     console.log(selectedIndexIds)
    //
    //     this.toolModel.indexFileNames = selectedIndexIds;
    //
    //     console.log(this.toolModel)
    //
    //     //this.toolsService.addTool(this.toolModel);
    //     form.reset();
    //
    // }


    toggleFields(event: Event) {
        const dropDown = event.target as HTMLInputElement;
        if (dropDown.value == "1") {
            this.showchatBotSpecificFields = true;
        } else if (dropDown.value == "2") {
            this.showchatBotSpecificFields = false;
        }
    }

    ngOnDestroy() {
        this.toolGetSubscription?.unsubscribe();
        this.addDocSubscription?.unsubscribe();
        this.toolUpdateSubscription?.unsubscribe();
        this.getToolDocListSubscription?.unsubscribe();
        this.updateKeywordsSubecription?.unsubscribe();
        this.promptMapSubscription?.unsubscribe();
    }


    showServerMessage(messageType: string, message: string, time: number = 5000) {
        if (messageType == 'updateToolMessage') {
            this.updateToolMessage = message;
            setTimeout(() => {
                this.updateToolMessage = '';
            }, time);
        }
        if (messageType == 'addDocMessage') {
            this.addDocumentMessage = message;
            setTimeout(() => {
                this.addDocumentMessage = '';
            }, time); 
        }

    }


    turnoffAllShowDataSourceFlags() {
        this.fileUploadDataSourceSelected = false;
        this.googleDocsDataSourceSelected = false;
        this.webPageDataSourceSelected = false;
        this.webSiteDataSourceSelected = false;
    }

    onDataSourceOptionChange(event: MatSelectChange) {
        const value = event.value;
        this.turnoffAllShowDataSourceFlags();
        if (value === 'File') {
            this.fileUploadDataSourceSelected = true;
        } else if (value === 'GoogleDocs') {
            this.googleDocsDataSourceSelected = true;
        } else if (value === 'WebPages') {
            this.webPageDataSourceSelected = true;
        } else if (value === 'Website') {
            this.webSiteDataSourceSelected = true;
        }
    }




    addDocument(form : NgForm) {
        const formData = new FormData();
        if (this.srcType === 'File') {
            formData.append("uploadedFile", this.file);
            this.toolsService.addDocToTheTool(formData, this.toolId);
        } else if (this.srcType === 'WebPages') {
            formData.append("webPageList", this.srcDataWebPages);
            this.toolsService.addWebPagesToTheTool(formData,this.toolId)
        }
        this.isLoading = true;
    }

    // populateFormData(formData: FormData, srcType: string): string {
    //
    //
    //     var srcData = '';
    //     if (srcType === 'GoogleDocs') {
    //
    //         srcData = this.srcDataGoogleDocs;
    //
    //     } else if (srcType === 'WebPages') {
    //
    //         srcData = this.srcDataWebPages;
    //
    //     } else if (srcType === 'File') {
    //         formData.append("uploadedFile", this.file);
    //     }
    //
    //
    //     return srcData;
    //
    //
    // }


    updateKeywords(idx:any) {
        this.isLoadingUpdateKeywords = true;
        var toolDocModel = this.toolDocModelList[idx];
        this.toolsService.updateKeyWords(this.toolId,toolDocModel.id,toolDocModel.keywords);
    }

    comparePrompts(p1: Prompt,p2: Prompt ) {
        return p1.id == p2.id
    }


    // updateSelectedPrompts(){
    //     for( const prompt of this.contextPromptList) {
    //         console.log("--------- "+this.toolModel.toolConfigDto.contextPromptId);
    //         console.log("----------"+prompt.id);
    //         if (prompt.id == this.toolModel.toolConfigDto.contextPromptId) {
    //
    //             console.log("------ inside if");
    //
    //             this.selectedContextPromot = prompt;
    //             break;
    //         }
    //     }
    //
    //     for( const prompt of this.condensePromptList) {
    //         if (prompt.id == this.toolModel.toolConfigDto.condensePromptId) {
    //             console.log("------ inside if--2");
    //
    //             this.selectedCondensePrompt = prompt;
    //             console.log("------ inside if--2 "+JSON.stringify(this.selectedCondensePrompt));
    //             break;
    //         }
    //     }
    //
    //     for( const prompt of this.condenseQuestionPromptList) {
    //         if (prompt.id == this.toolModel.toolConfigDto.condenseQuestionPromptId) {
    //             this.selectedCondenseQuestionPrompt = prompt;
    //             break;
    //         }
    //     }
    //
    //     for( const prompt of this.systemPromptList) {
    //         if (prompt.id == this.toolModel.toolConfigDto.systemPromptId) {
    //             this.selectedSystemPrompt = prompt;
    //             break;
    //         }
    //     }
    //
    //
    // }

}
