<div class="row">
    <h3>List of Prompts</h3>

    <div *ngIf="promptList == null ||  promptList.length === 0">
        You have not created any prompts yet.
    </div>
    <div class="table-responsive" *ngIf="promptList != null && promptList.length > 0">


        <table class="table table-borderless" *ngIf="promptList.length !== 0">
            <thead>
                <!-- <tr>
                    <th>Name</th>
                    <th>Prompt Text</th>
                </tr> -->
            </thead>
            <tbody>
                <tr *ngFor="let prompt of promptList.slice(0, itemsToDisplay);let i = index">
                    <td> <h4>Prompt Name: {{ prompt.name }}</h4>  <p>{{ prompt.text }}</p> </td>
                    <!-- <td>{{ prompt.name }}</td>
                    <td>{{ prompt.text }}</td> -->
                </tr>
            </tbody>
        </table>
        <button mat-flat-button class="knownew" style="padding: 0 15px; white-space: nowrap;" (click)="loadMoreItems()">Load More</button>

    </div>
