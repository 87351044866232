<div class="d-flex row col-12">
    <div class="container col-lg-8 col-12 mt-3">
        <div class="first">

            <div id="chatDiv" style="margin-top:1em;margin-bottom: 1em;" [hidden]="this.toolType == '2'">

                <div class="container chat-head">
                    <div class="row">
                        <div class="col-6 d-flex justify-content-start align-items-center">
                            <h1>Bot</h1>
                        </div>
                        <div class="col-6 d-flex justify-content-end align-items-center">
                            <a href="javascript:void(0);" (click)="showchat=false">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x"
                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#000"
                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M18 6l-12 12"></path>
                                    <path d="M6 6l12 12"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>


                <form (submit)="chat()">
                    <div #chatContainer class="chat-container" style="height: 300px;overflow-y:auto">
                        <div class="message" style="padding:0.3em" [ngClass]="message.from == 'AI'?'ai-message':''"
                            *ngFor="let message of messages">

                            {{ message.from }}:{{ message.message }}

                        </div>
                    </div>
                    <div #hiddentDiv id="hiddenDiv">
                    </div>


                    <div class="input-group chat-command">

                        <mat-form-field class="field custom-field" appearance="outline" style="width: 100%;">
                            <mat-label>Message</mat-label>

                            <input matInput id="newMessage" name="newMessage" type="text"
                                placeholder="Type your message..." [(ngModel)]="newMessage">

                            <button mat-icon-button matSuffix type="submit" [disabled]="isLoading">
                                <mat-icon *ngIf="!isLoading">send</mat-icon>
                                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                            </button>
                        </mat-form-field>

                        <!-- <input id="newMessage" name="newMessage" type="text" class="form-control"
                        placeholder="Type your message..." [(ngModel)]="newMessage"> -->
                        <!-- <div class="input-group-append">
                        <button class="btn btn-primary" style="margin-left:0.5em;margin-right:0.5em" type="submit">Send
                            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span></button>
                    </div> -->
                    </div>
                    <div style="font-size: x-small;color: grey;font-style: italic">
                        {{chatstatus}}
                    </div>
                </form>

            </div>

            <div id="queryDiv" style="margin-top:1em;margin-bottom: 1em;padding:0.1em;" [hidden]="this.toolType == '1'">
                <form (submit)="query()">

                    <div class="container chat-head">
                        <div class="row">
                            <div class="col-6 d-flex justify-content-start align-items-center">
                                <h1>Bot</h1>
                            </div>
                            <div class="col-6 d-flex justify-content-end align-items-center">
                                <a href="javascript:void(0);" (click)="showQueryDiv=false">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x"
                                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#000"
                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M18 6l-12 12"></path>
                                        <path d="M6 6l12 12"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div container="queryResponnse" style="margin-bottom: 1em">
                        <textarea id="textarea" class="form-control" rows="10" placeholder="Response from AI"
                            disabled="true">{{queryResponse}}</textarea>
                    </div>


                    <div class="input-group chat-command">

                        <mat-form-field class="field custom-field" appearance="outline" style="width: 100%;">
                            <mat-label>Question</mat-label>

                            <input matInput id="queryStr" name="queryStr" type="text"
                                placeholder="Enter your question here.." [(ngModel)]="queryStr">

                            <button mat-icon-button matSuffix type="submit" [disabled]="isLoading">
                                <mat-icon *ngIf="!isLoading">send</mat-icon>
                                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                            </button>
                        </mat-form-field>


                        <!-- <button class="btn btn-primary" type="button" (click)="showQueryDiv = false">Close</button> -->
                        <!-- <input type="text" class="form-control" name="queryStr" id="queryStr" [(ngModel)]="queryStr"
                        style="margin-right: 0.5em" placeholder="Enter your question here.." />
                    <div class="input-group-append">
                        <button class="btn btn-primary" style="margin-left:0.5em;margin-right:0.5em" type="submit">Query
                            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span></button>
                        
                    </div> -->
                    </div>

                </form>

            </div>

        </div>

    </div>

    <div class="container col-lg-4 col-12 mt-3">
        <div class="second">

            <div class="container">
                <h1>API's Integration</h1>
                <!--<button class="btn btn-primary btn-sm" style="margin-left:0.5em;margin-right:0.5em" type="button" (click)="showAPISignature();this.showApi=true">API Enppoint</button>-->
                <span>
                    The bots can be called via API and can be integrated with other applications/websites. Please use
                    the
                    following
                    API to invoke this bot.
                </span>
                <div style="position:relative;background-color: #f5f5f5; padding: 10px;" [hidden]="!showApi">
                    <!--        <button id="close-btn" (click)="showApi = false" >X</button>-->
                    <code>
                        {{apiStr}}
                    </code>
                    <p style="font-style: italic;font-size: x-small; padding-top: 3px; margin-bottom: -3px;">Api key can
                        be generated from the settings page.</p>
                </div>
                <!-- <div class="guide">

                </div> -->
                <button mat-flat-button class="guide" style="padding: 20px 0;">Download Integration Guide</button>
            </div>

        </div>

    </div>
</div>