import { Component, OnInit } from "@angular/core";
import { KbService } from "../kb/KbService";
import { QueryService } from "./queryService";
import { PromptService } from "../prompt/promptService";

@Component({
  selector: "app-query",
  templateUrl: "./query.component.html",
  styleUrls: ["./query.component.css"],
})
export class QueryComponent implements OnInit {
  kbList: any = null;
  selectedKbOption: any | undefined;
  selectedIndex: any | "";
  queryResponse: string = "";
  errorMessage: string = "";
  queryText: string = "";

  indexDtoList: any[] = [];

  promptList: any = null;

  selectedPrompts: any[] = [];

  showAdvanced = false;

  constructor(
    private kbService: KbService,
    private promptService: PromptService,
    private queryService: QueryService  ) {}

  ngOnInit() {
    this.kbService.getMyKBlist().subscribe(
      (response) => {
        this.kbList = response;
        console.log(this.kbList);
        this.selectedKbOption = this.kbList[0];
        console.log(" compIndexList " + this.kbList[0].indexDtoLiteList);
        console.log("this.indexDtoList " + this.indexDtoList);

        this.selectedIndex = this.kbList[0].indexDtoLiteList[0];
      },
      (error) => {
        console.log(error);
      }
    );

    this.promptService.getMyPromptlist().subscribe(
      (response) => {
        this.promptList = response;
        console.log(this.promptList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  query() {
    var queryRequest = {
      queryText: "",
      promptIdList: [{}],
      kbId: "",
      indexes: [{}],
    };
    queryRequest.kbId = this.selectedKbOption.id;
    this.selectedPrompts.forEach((obj) => {
      queryRequest.promptIdList.push(obj.id);
    });
    queryRequest.promptIdList.splice(0, 1);

    queryRequest.indexes.push({
      indexId: this.selectedIndex.id,
      indexFileName: this.selectedIndex.indexFileName,
    });
    //delete the first empty element
    queryRequest.indexes.splice(0, 1);
    queryRequest.queryText = this.queryText;

    console.log("queryRequest --->" + JSON.stringify(queryRequest));

    this.queryService.query(queryRequest).subscribe(
      (response) => {
        this.queryResponse = response.message;
      },
      (error) => {
        console.log("eror while querying " + error);
        this.errorMessage = error;
      }
    );
  }

  onKbOptionChange() {
    // this.selectedIndex = this.selectedKbOption.nonCompIndexList[0];
    // console.log(this.selectedKbOption.compIndexList)
    // console.log(this.selectedKbOption.nonCompIndexList)
    //
    // this.indexDtoList.splice(0, this.indexDtoList.length);
    // this.selectedKbOption.compIndexList.forEach((obj:any) => {
    //   //console.log(obj);
    //   this.indexDtoList.push(obj);
    // });
    //
    // this.selectedKbOption.nonCompIndexList.forEach((obj:any) => {
    //   //console.log(obj);
    //   this.indexDtoList.push(obj);
    // });

    //console.log('In onKbOptionChange this.indexDtoList '+this.indexDtoLiteList)
    this.selectedIndex = this.selectedKbOption.indexDtoLiteList[0];
  }

  clearSelectedPrompts() {
    this.selectedPrompts = [];
    return true;
  }
}
