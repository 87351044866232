<div class="container">

    <div style="border-bottom: skyblue solid 1px">

        <div  id="navbarSupportedContent">
            <!-- Navbar brand -->
            <a class=" navbar-brand mt-lg-0" href="#">
                <img
                        src="assets/nlp_tools_A.png"
                        height="80"
                        alt="MDB Logo"
                        loading="lazy"
                />
            </a>
        </div>
    </div>
    <div class="container" style="margin-top: 1em; margin-bottom: 1em">
        <h3>NLP-Tools WordPress Plugin</h3>
        <h5>Description</h5>
        The WordPress plugin provides an easy way to integrate our tools into your existing WordPress website. Currently, we offer:
        <ul>
            <li>Customer Service Chat Bot</li>
            <li>Customer Service Question Answer Bot</li>
        </ul>
        <span>
        The bots work by utilizing APIs deployed on our site www.cognix.ai. Upon installation, site admins are asked to register with cognix.ai. Upon successful registration, the plugin displays the available pages on the site. This list is used to build a chatbot or Question Answer bot. The list of these URLs is sent to our server, where we build bots based on the information on these pages. Upon successful creation, the plugin adds a bot icon to the website, which can then be used by the visitors on your website.
    </span>

        <dl style="margin-top: 1em">
            <dt>
                How to add additional information to the bot.
            </dt>
            <dd>
                The bot can be updated with more information, such as documents containing help desk documentation, product manuals, FAQs, etc. Site owners/site admins have full flexibility regarding the kind of information that can be fed to the bot. To update the bot with documentation, log in to cognix.ai with the credentials created during the registration process and upload the documentation.
                <strong>Please ensure that no Personally Identifiable Information (PII) or sensitive business information that you do not wish to disclose to others is included in the uploaded documentation. </strong>            </dd>

            <dt>
                How to customize the location and the 'open' button for the bot.
            </dt>
            <dd>
                By default, the bot opens in the center of the website. The location and button can be customized as follows:
                <br>
                When the bot is created, the API returns this tag:
                <br>
                <code>&lt;element-chat-bot agent_id="id" web_key="xxxxxxx" position_class="top-right" &gt; &lt;element-chat-bot&gt;</code>
                <br>
                The position_class can have these values:
                <ul>
                    <li>center</li>
                    <li>top-right</li>
                    <li>top-left</li>
                    <li>bottom-right</li>
                    <li>bottom-left</li>
                </ul>
                The appearance of the button that opens the chatbot can be customized as well.
                <section>
                    <p>Step 1: Add a button in the HTML</p>
                    <code>&lt;button id="openPopupBtn"&gt;Open Bot1 &lt;button&gt;</code>
                </section>
                <p>Step 2: Change the chatbot tag as follows (refer to the button created in step 1 in the tag). <strong>Please note the bot_ui_cntrl_id attribute points to the button created in step 1.</strong><br><strong>Make sure the id of the button is unique across the entire webpage.</strong></p>
                <code>&lt;element-chat-bot agent_id="128" web_key="Y11DVjjsQgjGNL4vGYOnikZhyDx85cMx" position_class="top-right" bot_open_control="custom" <strong>bot_ui_cntrl_id="openPopupBtn"</strong> &gt;&lt;element-chat-bot&gt;</code>
            </dd>

            <dt>
                How to avoid service interruption.
            </dt>

            <dd>
                All the bots have a trial period/free word count limit. Please subscribe before the limits expire to avoid service interruption. The trial period and/or free word count limit can be extended on a case-by-case basis. Please contact <a href="mailto:admin@cognix.ai">admin</a> to change your free trial period/token count.
            </dd>
        </dl>
    </div>

    <div class="container">
        <section class="d-flex flex-column flex-md-row justify-content-between p-4">
            <!-- Left -->
            <div class="me-5">
                <p class="mb-0">&copy; 2024 cognix.ai. All rights reserved.</p>
            </div>
            <!-- Left -->

            <!-- Right -->
            <div>
                <p class="mb-0">
                    In case of issues or new feature requests, send an email to
                    <a href="mailto:admin@cognix.ai" class="mail-link">admin@cognix.ai</a>
                </p>
            </div>
            <!-- Right -->
        </section>
    </div>



</div>

