import { Injectable } from "@angular/core";
import { AppConstants } from "../app-constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, Observable, map } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { PromptModel, PromptMapWrapper } from "./prompt.model";

@Injectable()
export class PromptService {
  getPromptMapSubject = new Subject<PromptMapWrapper>();

  constructor(
    private appConstants: AppConstants,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  addPrompt(promptModel: PromptModel): Observable<any> {
    console.log("inside addPrompt");
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.promptCreateUrl();

    console.log("url -->" + url);

    return this.http.post(url, promptModel, { headers: headers });
  }

  getMyPromptlist(): Observable<any> {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getMyPromptListUrl();

    console.log("getMyPromptlist url --->" + url);

    return this.http.get(url, { headers: headers }).pipe(
      map((response) => {
        return JSON.parse(JSON.stringify(response));
      })
    );
  }

  getPromptTypeList(): Observable<any> {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getPromptTypeListUrl();

    console.log("getPromptTypeList url --->" + url);

    return this.http.get(url, { headers: headers }).pipe(
      map((response) => {
        return JSON.parse(JSON.stringify(response));
      })
    );
  }

  getPromptMap() {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getPromptTypePromptmapUrl();

    return this.http
      .get<PromptMapWrapper>(url, { headers: headers })
      .pipe(
        map((response) => {
          var mapObj = this.convertObjectToMap(response);

          console.log("  mapObj        " + JSON.stringify(mapObj));

          var wrapper = new PromptMapWrapper();
          wrapper.promptMap = mapObj;

          //return JSON.parse(JSON.stringify(response));
          return wrapper;
        })
      )
      .subscribe(
        (response: PromptMapWrapper) => {
          this.getPromptMapSubject.next(response);
        },
        (error) => {
          this.getPromptMapSubject.error(error);
        }
      );
  }

  convertObjectToMap(obj: { [key: string]: any }): Map<string, any> {
    var map = new Map<string, any>();

    for (const key in obj) {
      console.log("key " + key);
      if (obj.hasOwnProperty(key)) {
        console.log("value = " + JSON.stringify(obj[key]));
        map.set(key, obj[key]);
      }
    }
    console.log(
      "  map ----->        " + JSON.stringify(map.get("ContextPrompt"))
    );

    return map;
  }
}
