<div class="input-wrapper">
  <input
    type="text"
    [(ngModel)]="value"
    placeholder="Type the link or click +Browse"
    class="text-input"
  />
  <label for="fileInput" class="browse-label">+Browse</label>
  <input
    type="file"
    id="fileInput"
    class="file-input"
    (change)="onFileSelected($event)"
  />
</div>
